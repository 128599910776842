import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  SimpleGrid,
} from '@chakra-ui/react';

const AddLeadForm = ({ isOpen, onClose, onSubmit, currentList, editingLead }) => {
  const [lead, setLead] = useState({
    email: '',
    firstName: '',
    lastName: '',
    position: '',
    company: '',
    website: '',
    linkedinUrl: '',
    companyIndustry: '',
    companySize: '',
    twitter: ''
  });

  useEffect(() => {
    if (editingLead) {
      setLead(editingLead);
    } else {
      setLead({
        email: '',
        firstName: '',
        lastName: '',
        position: '',
        company: '',
        website: '',
        linkedinUrl: '',
        companyIndustry: '',
        companySize: '',
        twitter: ''
      });
    }
  }, [editingLead]);

  const handleChange = (e) => {
    setLead({ ...lead, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(lead);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editingLead ? 'Edit Lead' : 'Add New Lead'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <SimpleGrid columns={2} spacing={4} width="100%">
                <FormControl>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    value={lead.firstName}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    value={lead.lastName}
                    onChange={handleChange}
                  />
                </FormControl>
              </SimpleGrid>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={lead.email}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="position">Position</FormLabel>
                <Input
                  id="position"
                  name="position"
                  value={lead.position}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="company">Company</FormLabel>
                <Input
                  id="company"
                  name="company"
                  value={lead.company}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="website">Website</FormLabel>
                <Input
                  id="website"
                  name="website"
                  type="url"
                  value={lead.website}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="linkedinUrl">LinkedIn URL</FormLabel>
                <Input
                  id="linkedinUrl"
                  name="linkedinUrl"
                  type="url"
                  value={lead.linkedinUrl}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="companyIndustry">Company Industry</FormLabel>
                <Input
                  id="companyIndustry"
                  name="companyIndustry"
                  value={lead.companyIndustry}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="companySize">Company Size</FormLabel>
                <Input
                  id="companySize"
                  name="companySize"
                  value={lead.companySize}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="twitter">Twitter</FormLabel>
                <Input
                  id="twitter"
                  name="twitter"
                  value={lead.twitter}
                  onChange={handleChange}
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
            <Button variant="ghost" colorScheme="blue" onClick={handleSubmit}>
              {editingLead ? 'Update Lead' : 'Add Lead'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddLeadForm;