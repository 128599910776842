import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initClient, signIn } from '../googleApi';
import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  useToast,
  Container,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import api from '../api'; 

const Login = ({ onSignIn, error: propError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [localError, setLocalError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadGoogleSignIn = async () => {
      try {
        await initClient();
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading Google Sign-In:', error);
        setLocalError('Failed to load Google Sign-In. Please try again later.');
        setIsLoading(false);
      }
    };

    loadGoogleSignIn();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    if (code) {
      console.log('Microsoft auth code detected in URL');
      handleMicrosoftCallback(code);
    }
  }, [location]);

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      setLocalError(null);
      const response = await signIn();
      console.log('Google Sign-In response:', response);

      const userInfo = {
        token: response.token,
        idToken: response.id_token,
        email: response.email,
        name: response.name,
        googleId: response.id,
        photoUrl: response.photoUrl || response.imageUrl,
      };

      console.log('Extracted user info:', {
        ...userInfo,
        token: userInfo.token ? 'present' : 'missing',
        idToken: userInfo.idToken ? 'present' : 'missing',
        photoUrl: userInfo.photoUrl ? 'present' : 'missing',
      });

      const serverResponse = await api.post('/api/auth/login', { userInfo });

      console.log('Server login response:', serverResponse.data);

      if (serverResponse.data.user && serverResponse.data.token) {
        if (typeof onSignIn === 'function') {
          onSignIn(serverResponse.data.user, serverResponse.data.token, serverResponse.data.expiresAt);
        }

        toast({
          title: "Signed in successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Invalid server response: missing user data or token');
      }
    } catch (error) {
      console.error('Google Sign In failed:', error);
      setLocalError(`Sign-in failed: ${error.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      console.log('Initiating Microsoft login');
      const response = await api.getMicrosoftLoginUrl();
      if (response.data && response.data.url) {
        console.log('Redirecting to Microsoft login URL');
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error initiating Microsoft login:', error);
      setLocalError('Failed to initiate Microsoft login. Please try again.');
    }
  };

  const handleMicrosoftCallback = async (code) => {
    try {
      console.log('Processing Microsoft callback');
      setIsLoading(true);
      const user = await api.microsoftLogin(code);
      if (typeof onSignIn === 'function') {
        onSignIn(user);
      }
      toast({
        title: "Signed in successfully with Microsoft",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/');  // Change this line to navigate to the home page
    } catch (error) {
      console.error('Error during Microsoft callback:', error);
      setLocalError('Microsoft sign-in failed: ' + (error.response?.data?.message || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const error = propError || localError;

  return (
    <Box minHeight="100vh" bg="gray.100" display="flex" alignItems="center" justifyContent="center">
      <Container maxW="md">
        <Box bg="white" p={8} borderRadius="lg" boxShadow="md">
          <VStack spacing={6}>
            <Heading as="h2" size="xl">Login</Heading>
            {error && (
              <Alert status="error" borderRadius="md">
                <AlertIcon />
                <Text>{error}</Text>
              </Alert>
            )}
            <Button
              onClick={handleSignIn}
              isLoading={isLoading}
              loadingText="Signing in..."
              colorScheme="blue"
              size="lg"
              width="full"
            >
              Sign in with Google
            </Button>
            <Button
              onClick={handleMicrosoftLogin}
              isLoading={isLoading}
              loadingText="Signing in with Microsoft..."
              colorScheme="blue"
              variant="outline"
              size="lg"
              width="full"
            >
              Sign in with Microsoft
            </Button>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;