import { useState, useEffect, useCallback } from 'react';
import api from '../api';

const useWrapUpData = (campaignId) => {
  const [wrapUpData, setWrapUpData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [
        campaignDetails,
        campaignCountsResponse,
        availableEmails,
        profiles,
        randomLead
      ] = await Promise.all([
        api.getCampaign(campaignId).catch(() => ({})),
        api.getCampaignCounts(campaignId).catch(() => ({})),
        api.get('/api/email-campaigns/connected-emails').catch(() => ({ data: [] })),
        api.getCampaignProfiles().catch(() => ({ data: [] })),
        api.getRandomLead(campaignId).catch(() => ({ data: null }))
      ]);

      const campaignCounts = {
        totalRecipients: campaignCountsResponse?.totalRecipients || 0,
        totalEmails: campaignCountsResponse?.totalEmails || 0
      };

      // Fetch full campaign profile data
      let fullCampaignProfile = null;
      if (campaignDetails.campaignProfile) {
        try {
          const profileId = typeof campaignDetails.campaignProfile === 'string' 
            ? campaignDetails.campaignProfile 
            : campaignDetails.campaignProfile._id;
          
          const profileResponse = await api.getCampaignProfile(profileId);
          if (profileResponse.data && typeof profileResponse.data === 'object') {
            fullCampaignProfile = profileResponse.data;
          } else {
            console.error('Unexpected profile data format:', profileResponse);
          }
        } catch (profileError) {
          console.error('Error fetching campaign profile:', profileError);
        }
      }

      // Process signature information
      const signatureInfo = (campaignDetails.signatureSettings || []).reduce((acc, setting) => {
        acc[setting.email] = {
          signature: setting.signature || '',
          signatureType: setting.signatureType || 'default',
          customSignature: setting.customSignature || '',
          showSignature: setting.showSignature ?? true
        };
        return acc;
      }, {});

      const unsubscribeInfo = {
        method: campaignDetails.unsubscribeMethod || 'link',
        customText: campaignDetails.unsubscribeCustomText || '',
        anchorText: campaignDetails.unsubscribeAnchorText || 'Unsubscribe',
        keyword: campaignDetails.unsubscribeKeyword || '',
        formattedText: campaignDetails.unsubscribeMethod === 'link'
          ? (campaignDetails.unsubscribeCustomText || '').replace('[UNSUBSCRIBE_LINK]', `<span class="unsubscribe-link">${campaignDetails.unsubscribeAnchorText || 'Unsubscribe'}</span>`)
          : (campaignDetails.unsubscribeCustomText || '').replace('[KEYWORD]', campaignDetails.unsubscribeKeyword || '')
      };

      setWrapUpData({
        campaign: {
          ...campaignDetails,
          startDate: campaignDetails.startDate || null
        },
        fullCampaignProfile,
        campaignCounts,
        availableEmails: availableEmails.data || [],
        profiles: profiles.data || [],
        previewLead: randomLead.data || null,
        signatureInfo,
        unsubscribeInfo
      });
    } catch (err) {
      console.error('Error fetching wrap-up data:', err);
      setError(err);
      // Set default values for all data in case of an error
      setWrapUpData({
        campaign: {},
        fullCampaignProfile: null,
        campaignCounts: { totalRecipients: 0, totalEmails: 0 },
        availableEmails: [],
        profiles: [],
        previewLead: null,
        signatureInfo: {},
        unsubscribeInfo: {
          method: 'link',
          customText: '',
          anchorText: 'Unsubscribe',
          keyword: '',
          formattedText: ''
        }
      });
    } finally {
      setIsLoading(false);
    }
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      fetchData();
    }
  }, [fetchData, refreshTrigger]);

  const refreshWrapUpData = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  return { wrapUpData, isLoading, error, refreshWrapUpData };
};

export default useWrapUpData;