import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Checkbox,
  Text,
  Box,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import api from '../api';

const MailboxForm = ({ isOpen, onClose, onSubmit, initialData = null }) => {
  const [formData, setFormData] = useState({
    email: '',
    provider: '',
    senderName: '',
    dailyLimit: 500,
    hourlyLimit: 50,
    isDefault: false,
    smtpHost: '',
    smtpPort: '',
    imapHost: '',
    imapPort: '',
    username: '',
    password: '',
    signature: '',
    signatureType: 'none',
  });
  const [originalSignature, setOriginalSignature] = useState('');
  const toast = useToast();

  const fetchGmailSignature = async (email) => {
    try {
      const response = await api.get(`/api/mailboxes/signature/${email}`);
      return response.data.signature;
    } catch (error) {
      console.error('Error fetching Gmail signature:', error);
      return 'No Signature';
    }
  };

  useEffect(() => {
    const setupFormData = async () => {
      if (initialData) {
        let signature = initialData.signature;
        let signatureType = initialData.signatureType || 'none';

        if (initialData.provider === 'gmail' || initialData.provider === 'google365') {
          if (signatureType === 'email') {
            signature = await fetchGmailSignature(initialData.email);
          }
        }

        setFormData({
          ...initialData,
          signature,
          signatureType,
        });
        setOriginalSignature(signature);
      } else {
        // Reset form for new mailbox
        setFormData({
          email: '',
          provider: '',
          senderName: '',
          dailyLimit: 500,
          hourlyLimit: 50,
          isDefault: false,
          signature: '',
          signatureType: 'none',
        });
        setOriginalSignature('');
      }
    };

    setupFormData();
  }, [initialData, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNumberChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSignatureTypeChange = (e) => {
    const newSignatureType = e.target.value;
    setFormData(prev => ({
      ...prev,
      signatureType: newSignatureType,
      signature: newSignatureType === 'email' ? originalSignature : 
                 newSignatureType === 'none' ? '' : 
                 (prev.provider === 'gmail' || prev.provider === 'google365') ? '' : prev.signature,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    let submittedData = { ...formData };
  
    if (submittedData.provider === 'gmail' || submittedData.provider === 'google365') {
      if (submittedData.signatureType === 'email') {
        const fetchedSignature = await fetchGmailSignature(submittedData.email);
        submittedData.signature = fetchedSignature;
      } else if (submittedData.signatureType === 'none') {
        submittedData.signature = '';
      }
      // For 'custom', keep the signature as is
    } else {
      // For non-Gmail/Google365 accounts
      if (submittedData.signatureType === 'none') {
        submittedData.signature = '';
      }
    }
    
    console.log('Submitting data:', submittedData);
  
    try {
      let response;
      if (submittedData._id) {
        // Updating an existing mailbox
        response = await api.put(`/api/mailboxes/${submittedData._id}`, submittedData);
      } else if (submittedData.provider === 'smtp-imap') {
        // Creating a new SMTP/IMAP mailbox
        response = await api.post('/api/smtp-imap/connect/smtp-imap', submittedData);
      } else {
        // Creating a new mailbox (non-SMTP/IMAP)
        response = await api.post('/api/mailboxes', submittedData);
      }
      
      if (response.data) {
        // For SMTP/IMAP, the mailbox data is nested in the response
        const mailboxData = submittedData.provider === 'smtp-imap' ? response.data.mailbox : response.data;
        onSubmit(mailboxData);
        onClose();
        toast({
          title: submittedData._id ? "Mailbox updated" : "Mailbox created",
          description: submittedData.provider === 'smtp-imap' ? "SMTP/IMAP connection successful" : undefined,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating/creating mailbox:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderSignature = (signature) => {
    return { __html: signature };
  };

  const isEmailSignatureAvailable = formData.provider === 'gmail' || formData.provider === 'google365';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{initialData ? 'Edit Mailbox' : 'Add New Mailbox'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Non-editable fields */}
            <Box borderWidth={1} borderRadius="md" p={4} bg="gray.50">
              <Text fontWeight="bold" mb={2}>Mailbox Details</Text>
              <Text><strong>Email:</strong> {formData.email}</Text>
              <Text><strong>Provider:</strong> {formData.provider}</Text>
              <Text><strong>Sender Name:</strong> {formData.senderName}</Text>
            </Box>

            {/* Editable fields */}
            <FormControl>
              <FormLabel>Daily Limit</FormLabel>
              <NumberInput 
                min={1} 
                max={2000} 
                value={formData.dailyLimit} 
                onChange={(value) => handleNumberChange('dailyLimit', value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Hourly Limit</FormLabel>
              <NumberInput 
                min={1} 
                max={200} 
                value={formData.hourlyLimit} 
                onChange={(value) => handleNumberChange('hourlyLimit', value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            {formData.provider === 'smtp-imap' && (
              <>
                <FormControl isRequired>
                <FormLabel>Email</FormLabel>
      <Input 
        name="email" 
        value={formData.email} 
        onChange={handleChange}
      />
    </FormControl>
    <FormControl isRequired>
      <FormLabel>Sender Name</FormLabel>
      <Input 
        name="senderName" 
        value={formData.senderName} 
        onChange={handleChange}
      />
                  <FormLabel>SMTP Host</FormLabel>
                  <Input 
                    name="smtpHost" 
                    value={formData.smtpHost} 
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>SMTP Port</FormLabel>
                  <Input 
                    name="smtpPort" 
                    type="number"
                    value={formData.smtpPort} 
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>IMAP Host</FormLabel>
                  <Input 
                    name="imapHost" 
                    value={formData.imapHost} 
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>IMAP Port</FormLabel>
                  <Input 
                    name="imapPort" 
                    type="number"
                    value={formData.imapPort} 
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Username</FormLabel>
                  <Input 
                    name="username" 
                    value={formData.username} 
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input 
                    name="password" 
                    type="password"
                    value={formData.password} 
                    onChange={handleChange}
                  />
                </FormControl>
              </>
            )}
            <Checkbox 
              name="isDefault" 
              isChecked={formData.isDefault} 
              onChange={(e) => setFormData(prev => ({ ...prev, isDefault: e.target.checked }))}
            >
              Set as default mailbox
            </Checkbox>
            
            {/* Signature fields */}
            <FormControl>
              <FormLabel fontWeight="bold">Signature Type</FormLabel>
              <Select 
                value={formData.signatureType} 
                onChange={handleSignatureTypeChange}
                bg="gray.100"
                size="lg"
              >
                {isEmailSignatureAvailable && <option value="email">Use Email Signature</option>}
                <option value="custom">Use Custom Signature</option>
                <option value="none">No Signature</option>
              </Select>
            </FormControl>

            {formData.signatureType !== 'none' && (
              <FormControl>
                <FormLabel>Signature</FormLabel>
                {formData.signatureType === 'email' ? (
                  <Box 
                    border="1px solid" 
                    borderColor="gray.200" 
                    borderRadius="md" 
                    p={2}
                    dangerouslySetInnerHTML={renderSignature(formData.signature || 'No email signature found.')}
                  />
                ) : (
                  <Textarea
                    name="signature"
                    value={formData.signature}
                    onChange={handleChange}
                    placeholder="Enter your custom signature here"
                    rows={5}
                  />
                )}
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {initialData ? 'Update' : 'Add'} Mailbox
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MailboxForm;