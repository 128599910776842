import React, { useState, useEffect, useRef } from 'react';
import { ChakraProvider, Box, Table, Thead, Tbody, Tr, Th, Td, VStack, HStack, Text, Avatar, Switch, Container } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const SmoothPulsingNumber = ({ value, isAnimating, isPercentage = false }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [emoji, setEmoji] = useState('');
  const previousValue = useRef(value);
  const animationRef = useRef(null);

  useEffect(() => {
    if (value === undefined || value === null) return;

    const startValue = previousValue.current;
    const endValue = value;

    if (startValue === endValue) return;

    setEmoji(endValue > startValue ? '🚀' : endValue < startValue ? '📉' : '🔄');

    const startTime = performance.now();
    const duration = 500;
    const easeOutQuad = t => t * (2 - t);

    const animateValue = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeOutQuad(progress);
      const currentValue = startValue + (endValue - startValue) * easedProgress;
      
      const roundedValue = isPercentage ? Math.round(currentValue * 10) / 10 : Math.round(currentValue);
      setDisplayValue(roundedValue);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateValue);
      } else {
        setDisplayValue(endValue);
        previousValue.current = endValue;
      }
    };

    animationRef.current = requestAnimationFrame(animateValue);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [value, isPercentage]);

  return (
    <Box position="relative" width="100%" height="24px">
      <Box 
        position="absolute" 
        right="0" 
        width="60px" 
        textAlign="right"
      >
        <motion.span
          animate={isAnimating ? { 
            scale: [1, 1.1, 1],
            color: ["#000", "#8dc6ec", "#000"]
          } : {}}
          transition={{ duration: 0.5 }}
        >
          {isPercentage ? displayValue.toFixed(1) : Math.round(displayValue)}
          {isPercentage && '%'}
        </motion.span>
      </Box>
      <AnimatePresence>
        {isAnimating && (
          <motion.span
            style={{
              position: 'absolute',
              right: '-24px',
              top: '0',
              fontSize: '0.9em',
              lineHeight: '24px',
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
          >
            {emoji}
          </motion.span>
        )}
      </AnimatePresence>
    </Box>
  );
};

const AnimatedCampaignTables = () => {
  const [variantData, setVariantData] = useState([
    { name: 'Version A', subject: 'Check out our new product!', sent: 1000, pending: 500, openRate: 25.5, replyRate: 5.2, removed: false },
    { name: 'Version B', subject: 'Limited time offer inside', sent: 950, pending: 550, openRate: 28.3, replyRate: 6.1, removed: false },
    { name: 'Version C', subject: 'You won\'t believe this deal', sent: 1020, pending: 480, openRate: 22.7, replyRate: 4.8, removed: false },
  ]);

  const [mailboxData, setMailboxData] = useState([
    { email: 'john@example.com', sent: 800, replies: 40, opens: 200, scheduled: 100, removed: false },
    { email: 'jane@example.com', sent: 750, replies: 35, opens: 180, scheduled: 120, removed: false },
    { email: 'bob@example.com', sent: 820, replies: 45, opens: 210, scheduled: 90, removed: false },
  ]);

  const [pulsingCell, setPulsingCell] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const tables = ['variant', 'mailbox'];
      const selectedTable = tables[Math.floor(Math.random() * tables.length)];
      
      if (selectedTable === 'variant') {
        const rowIndex = Math.floor(Math.random() * variantData.length);
        const columns = ['sent', 'pending', 'openRate', 'replyRate'];
        const columnName = columns[Math.floor(Math.random() * columns.length)];
        
        setVariantData(prevData => {
          const newData = [...prevData];
          if (columnName === 'openRate' || columnName === 'replyRate') {
            newData[rowIndex][columnName] = +(newData[rowIndex][columnName] + (Math.random() * 0.5 - 0.25)).toFixed(1);
          } else {
            newData[rowIndex][columnName] += Math.floor(Math.random() * 10);
          }
          return newData;
        });
        
        setPulsingCell({ table: 'variant', row: rowIndex, column: columnName });
      } else {
        const rowIndex = Math.floor(Math.random() * mailboxData.length);
        const columns = ['sent', 'replies', 'opens', 'scheduled'];
        const columnName = columns[Math.floor(Math.random() * columns.length)];
        
        setMailboxData(prevData => {
          const newData = [...prevData];
          newData[rowIndex][columnName] += Math.floor(Math.random() * 5);
          return newData;
        });
        
        setPulsingCell({ table: 'mailbox', row: rowIndex, column: columnName });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth="1250px" p={5}>
      <VStack spacing={8} align="stretch">
        <Text fontSize="2xl" fontWeight="bold">Campaign Performance</Text>
        
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Version</Th>
                <Th>Subject Line</Th>
                <Th>Sent</Th>
                <Th>Pending Emails</Th>
                <Th>Open Rate</Th>
                <Th>Reply Rate</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {variantData.map((variant, rowIndex) => (
                <Tr key={variant.name}>
                  <Td>{variant.name}</Td>
                  <Td>{variant.subject}</Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={variant.sent} 
                      isAnimating={pulsingCell?.table === 'variant' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'sent'}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={variant.pending} 
                      isAnimating={pulsingCell?.table === 'variant' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'pending'}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={variant.openRate} 
                      isAnimating={pulsingCell?.table === 'variant' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'openRate'}
                      isPercentage={true}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={variant.replyRate} 
                      isAnimating={pulsingCell?.table === 'variant' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'replyRate'}
                      isPercentage={true}
                    />
                  </Td>
                  <Td>
                    <Switch isChecked={!variant.removed} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Text fontSize="2xl" fontWeight="bold" mt={8}>Email Account Monitoring</Text>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Mailbox</Th>
                <Th>Sent</Th>
                <Th>Replies</Th>
                <Th>Opens</Th>
                <Th>Scheduled</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {mailboxData.map((mailbox, rowIndex) => (
                <Tr key={mailbox.email}>
                  <Td>
                    <HStack>
                      <Avatar name={mailbox.email} src="" size="sm" />
                      <Text>{mailbox.email}</Text>
                    </HStack>
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={mailbox.sent} 
                      isAnimating={pulsingCell?.table === 'mailbox' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'sent'}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={mailbox.replies} 
                      isAnimating={pulsingCell?.table === 'mailbox' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'replies'}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={mailbox.opens} 
                      isAnimating={pulsingCell?.table === 'mailbox' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'opens'}
                    />
                  </Td>
                  <Td width="84px" pr="24px">
                    <SmoothPulsingNumber 
                      value={mailbox.scheduled} 
                      isAnimating={pulsingCell?.table === 'mailbox' && pulsingCell?.row === rowIndex && pulsingCell?.column === 'scheduled'}
                    />
                  </Td>
                  <Td>
                    <Switch isChecked={!mailbox.removed} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Container>
  );
};

const App = () => (
  <ChakraProvider>
    <AnimatedCampaignTables />
  </ChakraProvider>
);

export default App;