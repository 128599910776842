import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Input,
  Text,
  VStack,
  HStack,
  useToast,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

const FieldMapping = ({ csvData, onMappingComplete, onCancel, selectedList, newListName, lists }) => {
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [customAttributes, setCustomAttributes] = useState([]);
  const [newCustomAttribute, setNewCustomAttribute] = useState('');
  const [showCustomAttributeInput, setShowCustomAttributeInput] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [emailColumnSelected, setEmailColumnSelected] = useState(false);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const toast = useToast();

  const defaultAttributes = [
    "Email", "First Name", "Last Name", "Company", "Position", "Website", "URL",
    "LinkedIn URL", "Company Industry", "Company Size", "Twitter",
    "Phone", "Location", "Country", "Domain Name"
  ];
  
  const attributeMapping = {
    'Email': 'email',
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'Company': 'company',
    'Position': 'position',
    'Website': 'website',
    'URL': 'url',
    'LinkedIn URL': 'linkedinUrl',
    'Company Industry': 'companyIndustry',
    'Company Size': 'companySize',
    'Twitter': 'twitter',
    'Phone': 'phone',
    'Location': 'location',
    'Country': 'country',
    'Domain Name': 'domainName'
  };

  useEffect(() => {
    if (csvData && csvData.headers && csvData.headers.length > 0) {
      updateHeadersAndPreview();
    }
  }, [csvData]);
  
  const normalizeString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');

  const isLikelyEmail = (value) => {
    return typeof value === 'string' && value.includes('@') && value.includes('.');
  };

  const updateHeadersAndPreview = () => {
    if (!csvData || !csvData.previewData || csvData.previewData.length === 0) {
      console.error('Invalid or empty csvData');
      return;
    }
  
    const actualHeaders = csvData.headers;
    const dataForPreview = csvData.previewData;
  
    setHeaders(actualHeaders);
    setPreviewData(dataForPreview);
  
    const autoMapping = {};
    let emailColumnFound = false;
  
    actualHeaders.forEach((header, index) => {
      const columnValues = dataForPreview.map(row => row[index]);
      const columnValuesLower = columnValues.map(value => value.toLowerCase());
  
      for (const attribute of defaultAttributes) {
        const attributeLower = attribute.toLowerCase();
        if (!autoMapping[index]) {
          if (attributeLower === 'email' && !emailColumnFound && columnValues.some(isLikelyEmail)) {
            autoMapping[index] = 'Email';
            emailColumnFound = true;
            break;
          } else if (
            columnValuesLower.some(value => 
              value.includes(attributeLower) || 
              attributeLower.split(' ').every(word => value.includes(word))
            )
          ) {
            autoMapping[index] = attribute;
            break;
          }
        }
      }
    });
  
    console.log('Automapping result:', autoMapping);
    console.log('Valid rows count:', csvData.validLeadsCount);
  
    setMapping(autoMapping);
    setEmailColumnSelected(emailColumnFound);
    setAvailableAttributes(defaultAttributes.filter(attr => !Object.values(autoMapping).includes(attr)));
    setTotalRows(csvData.totalRows);
  };

  const handleDragStart = (e, attribute) => {
    e.dataTransfer.setData('text/plain', attribute);
  };

  const confirmMapping = () => {
    const emailColumnIndex = Object.entries(mapping).find(([_, value]) => value === 'Email')?.[0];
    console.log('Mapping before confirmation:', mapping);
    
    // Convert mapping to the format expected by the backend
    const formattedMapping = Object.entries(mapping).reduce((acc, [index, value]) => {
      acc[index] = attributeMapping[value] || value;
      return acc;
    }, {});
    
    console.log('Formatted mapping:', formattedMapping);
    console.log('Email column index:', emailColumnIndex);
    console.log('Headers:', headers);
    console.log('Preview data:', previewData);
    console.log('Total rows to import:', totalRows);
    
    onMappingComplete(formattedMapping, csvData.fullData || previewData, parseInt(emailColumnIndex), headers);
  };

  const handleDrop = (e, columnIndex) => {
    e.preventDefault();
    const attribute = e.dataTransfer.getData('text');
    setMapping(prev => {
      const newMapping = { ...prev, [columnIndex]: attribute };
      setEmailColumnSelected(Object.values(newMapping).includes('Email'));
      setAvailableAttributes(availableAttributes.filter(attr => attr !== attribute));
      return newMapping;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeMapping = (columnIndex) => {
    setMapping(prev => {
      const newMapping = { ...prev };
      const removedAttribute = newMapping[columnIndex];
      delete newMapping[columnIndex];
      setAvailableAttributes([...availableAttributes, removedAttribute]);
      setEmailColumnSelected(Object.values(newMapping).includes('Email'));
      return newMapping;
    });
  };

  const addCustomAttribute = () => {
    if (newCustomAttribute && !customAttributes.includes(newCustomAttribute)) {
      setCustomAttributes([...customAttributes, newCustomAttribute]);
      setAvailableAttributes([...availableAttributes, newCustomAttribute]);
      setNewCustomAttribute('');
      setShowCustomAttributeInput(false);
    }
  };

  const getListName = (listId) => {
    if (listId === 'all') return 'All Leads';
    const list = lists.find(l => l._id === listId);
    return list ? list.name : 'Unknown List';
  };

  return (
    <Box p={4} width="100%" maxWidth="1200px" margin="0 auto">
      <Heading as="h2" size="lg" mb={4}>Map CSV Columns</Heading>
      <Text mb={4}>
        Destination list: {selectedList ? getListName(selectedList) : `New list "${newListName}"`}
      </Text>
      <VStack spacing={4} align="stretch">
        <Box>
          <Heading as="h3" size="md" mb={2}>Drag and drop fields to map:</Heading>
          <Wrap spacing={2} mb={4}>
            {[...availableAttributes, ...customAttributes].map(attribute => (
              <WrapItem key={attribute}>
                <Tag
                  size="md"
                  draggable
                  onDragStart={(e) => handleDragStart(e, attribute)}
                  cursor="move"
                  colorScheme="blue"
                >
                  <TagLabel>{attribute}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
        <Box mb={4}>
          {showCustomAttributeInput ? (
            <HStack>
              <Input
                value={newCustomAttribute}
                onChange={(e) => setNewCustomAttribute(e.target.value)}
                placeholder="New custom attribute"
              />
              <Button onClick={addCustomAttribute} colorScheme="green" size="sm">
                Add
              </Button>
            </HStack>
          ) : (
            <Button onClick={() => setShowCustomAttributeInput(true)} colorScheme="green" size="sm">
              Add Custom Attribute
            </Button>
          )}
        </Box>
        <Box overflowX="auto" borderWidth={1} borderRadius="md" maxHeight="500px">
        {headers.length > 0 ? (
          <Grid templateColumns={`repeat(${headers.length}, 200px)`} gap={1} bg="gray.100" p={1}>
            {headers.map((header, index) => (
              <Box key={index} bg="white" p={2} width="200px">
                  <Box
                    h="50px"
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                    bg={mapping[index] ? "blue.50" : "gray.50"}
                    onDrop={(e) => handleDrop(e, index)}
                    onDragOver={handleDragOver}
                  >
                    {mapping[index] ? (
                      <Flex alignItems="center" justifyContent="space-between" w="100%" px={2}>
                        <Text fontSize="sm" color="blue.800" isTruncated>
                          {mapping[index]}
                        </Text>
                        <Button
                          size="xs"
                          onClick={() => removeMapping(index)}
                          colorScheme="red"
                          variant="ghost"
                        >
                          ×
                        </Button>
                      </Flex>
                    ) : (
                      <Text color="gray.400">Drag Here</Text>
                    )}
                  </Box>
                  <Box overflowY="auto" maxHeight="30vh">
                  {previewData.map((row, rowIndex) => (
                    <Text key={rowIndex} fontSize="sm" borderTopWidth={1} borderColor="gray.200" py={1} isTruncated>
                      {row[index] || ''}
                      </Text>
                    ))}
                  </Box>
                </Box>
              ))}
            </Grid>
          ) : (
            <Text>No headers found in the CSV data.</Text>
          )}
        </Box>
        <Flex justify="space-between" align="center">
        <Text>Total leads to import: {totalRows}</Text>
        <HStack spacing={2}>
          <Button onClick={onCancel} variant="outline">
            Back
          </Button>
          <Button
            onClick={confirmMapping}
            isDisabled={!emailColumnSelected}
            colorScheme="blue"
          >
            Confirm Mapping and Import
          </Button>
          </HStack>
        </Flex>
      </VStack>
    </Box>
  );
};

export default FieldMapping;