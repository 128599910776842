import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  VStack,
  HStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  IconButton,
  Flex,
  Heading,
  ButtonGroup
} from "@chakra-ui/react";
import { ChevronDownIcon, ViewIcon } from '@chakra-ui/icons';
import DOMPurify from 'dompurify';
import { defaultAttributes } from './AttributeModal';

const EmailPreview = ({ campaign, showSignature, signature, buttonProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeVersion, setActiveVersion] = useState(campaign?.versions?.[0] || {});
  const [activeView, setActiveView] = useState('desktop');

  const sanitizeHtml = (html) => ({
    __html: DOMPurify.sanitize(html, { 
      ADD_ATTR: ['style'],
      ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'u', 'ol', 'ul', 'li', 'a', 'span', 'div'],
      ALLOWED_ATTR: ['style', 'href']
    })
  });

  const replaceAttributesWithExamples = (content) => {
    if (typeof content !== 'string') {
      console.warn('replaceAttributesWithExamples received non-string content:', content);
      return ''; // or return a default value that makes sense for your use case
    }
  
    let replacedContent = content;
    const regex = /\{([^:}]+)(?::([^}]+))?\}/g;
    
    replacedContent = replacedContent.replace(regex, (match, attributeName, fallback) => {
      const attribute = defaultAttributes.find(attr => attr.name.toLowerCase() === attributeName.toLowerCase().trim());
      if (attribute) {
        return attribute.example;
      } else if (fallback) {
        return fallback;
      }
      return match; // If no match and no fallback, leave the original text
    });
  
    return replacedContent;
  };

  const renderEmailContent = (email) => {
    let content = email?.body || '';
    content = replaceAttributesWithExamples(content);
    content = content.replace(/<p><\/p>/g, '<br>');
    content = content.replace(/^(<br>)+|(<br>)+$/g, '');
    content = `<div>${content}</div>`;

    if (showSignature && signature) {
      content += `<div style="color: #8f8f8f; margin-top: 20px;">--<br>${signature}</div>`;
    }

    return (
      <div 
        style={{ 
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          fontFamily: 'Arial, sans-serif',
          lineHeight: '1.5'
        }} 
        dangerouslySetInnerHTML={sanitizeHtml(content)} 
      />
    );
  };

  const renderTimelinePreview = (emails, isMobile = false) => {
    const fromEmail = campaign?.fromEmails?.[0]?.email || 'No email address';
    
    return (
      <VStack align="stretch" spacing={8} position="relative" maxWidth={isMobile ? "375px" : "100%"} margin={isMobile ? "0 auto" : "0"}>
        <Box position="absolute" left="15px" top="0" bottom="0" width="2px" bg="gray.200" />
        {emails.map((email, index) => (
          <VStack key={index} spacing={2} align="stretch">
            {isMobile && (
              <Text fontSize="sm" color="gray.500" ml="38px">
                {index === 0 ? "Initial Email" : `Follow-up after ${email.sendAfterDays} day${email.sendAfterDays > 1 ? 's' : ''}`}
              </Text>
            )}
            <HStack spacing={4} align="flex-start">
              <Box 
                width="30px" 
                height="30px" 
                borderRadius="full" 
                bg={index === 0 ? "green.500" : "blue.500"} 
                color="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
              >
                {index + 1}
              </Box>
              <Box flex={1} borderWidth={1} borderRadius="md" p={4}>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <Heading as="h2" size="md" fontWeight="400" mb={2}>
                      {replaceAttributesWithExamples(email.subject)}
                    </Heading>
                    <Text fontWeight="bold">{fromEmail}</Text>
                    <HStack spacing={1} color="gray.500" fontSize="sm">
                      <Text>to me</Text>
                      <ChevronDownIcon />
                    </HStack>
                  </Box>
                  {!isMobile && (
                    <Text fontSize="sm" color="gray.500" textAlign="right">
                      {index === 0 ? "Initial Email" : `Follow-up after ${email.sendAfterDays} day${email.sendAfterDays > 1 ? 's' : ''}`}
                    </Text>
                  )}
                </Flex>
                <Box mt={4}>
                  {renderEmailContent(email)}
                </Box>
              </Box>
            </HStack>
          </VStack>
        ))}
      </VStack>
    );
  };

  if (!campaign || !campaign.versions || campaign.versions.length === 0) {
    return <Box>No campaign data available for preview.</Box>;
  }

  return (
    <>
       <Tooltip label="Preview Emails" placement="top">
        <IconButton
          icon={<ViewIcon />}
          aria-label="Preview Emails"
          onClick={onOpen}
          variant="outline"
          colorScheme="cyan"
          size="lg"
          {...buttonProps}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Text mr={4}>Email Campaign Preview</Text>
              <ButtonGroup size="sm" isAttached variant="outline">
                <Button 
                  onClick={() => setActiveView('desktop')}
                  colorScheme={activeView === 'desktop' ? "blue" : "gray"}
                >
                  Desktop
                </Button>
                <Button 
                  onClick={() => setActiveView('mobile')}
                  colorScheme={activeView === 'mobile' ? "blue" : "gray"}
                >
                  Mobile
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                {campaign.versions.map((version, index) => (
                  <Tab key={index} onClick={() => setActiveVersion(version)}>
                    Variant {version.name}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {campaign.versions.map((version, index) => (
                  <TabPanel key={index}>
                    {renderTimelinePreview(version.emails, activeView === 'mobile')}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailPreview;