import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Switch, Box, VStack, HStack, Text, Button, Table, Thead, Tbody, Tr, Th, Td, 
  Select, Flex, Stat, StatLabel, StatNumber, Icon, Badge, Menu, MenuButton, 
  MenuList, MenuItem, IconButton, Container, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Tooltip, Avatar, AvatarBadge,
  useDisclosure, Spinner,  RadioGroup, Stack, Radio, useToast
} from "@chakra-ui/react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import { EmailIcon, ViewIcon, RepeatIcon, WarningIcon, SettingsIcon, ChevronDownIcon, EditIcon } from '@chakra-ui/icons';
import { FaUsers, FaChartLine, FaPaperPlane, FaCrown, FaCommentDots, FaRegEnvelope } from 'react-icons/fa';
import { CiPause1, CiPlay1 } from "react-icons/ci";
import { motion, AnimatePresence } from 'framer-motion';
import api from '../api';
import CampaignSettings from './CampaignSettings';
import CampaignContent from './CampaignContent';
import RecipientSelection from './RecipientSelection';

// New LeftAlignedSmoothNumber component
const LeftAlignedSmoothNumber = ({ value, isAnimating, isPercentage = false }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const previousValue = useRef(value);
  const animationRef = useRef(null);

  useEffect(() => {
    if (value === undefined || value === null) return;

    const startValue = previousValue.current;
    const endValue = value;

    if (startValue === endValue) return;

    const startTime = performance.now();
    const duration = 500;
    const easeOutQuad = t => t * (2 - t);

    const animateValue = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeOutQuad(progress);
      const currentValue = startValue + (endValue - startValue) * easedProgress;
      
      const roundedValue = isPercentage ? Math.round(currentValue * 10) / 10 : Math.round(currentValue);
      setDisplayValue(roundedValue);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateValue);
      } else {
        setDisplayValue(endValue);
        previousValue.current = endValue;
      }
    };

    animationRef.current = requestAnimationFrame(animateValue);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [value, isPercentage]);

  return (
    <Box position="relative" width="100%" height="24px" textAlign="left">
      <motion.span
        animate={isAnimating ? { 
          scale: [1, 1.1, 1],
        } : {}}
        transition={{ duration: 0.5 }}
      >
        {isPercentage ? Math.round(displayValue) : Math.round(displayValue)}
        {isPercentage && '%'}
      </motion.span>
    </Box>
  );
};

const SmoothNumber = ({ value, isAnimating, isPercentage = false, icon, color, emojiVerticalOffset = 0 }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const previousValue = useRef(value);
  const animationRef = useRef(null);

  useEffect(() => {
    if (value === undefined || value === null) return;

    const startValue = previousValue.current;
    const endValue = value;

    if (startValue === endValue) return;

    const startTime = performance.now();
    const duration = 500;
    const easeOutQuad = t => t * (2 - t);

    const animateValue = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeOutQuad(progress);
      const currentValue = startValue + (endValue - startValue) * easedProgress;
      
      const roundedValue = isPercentage ? Math.round(currentValue * 10) / 10 : Math.round(currentValue);
      setDisplayValue(roundedValue);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateValue);
      } else {
        setDisplayValue(endValue);
        previousValue.current = endValue;
      }
    };

    animationRef.current = requestAnimationFrame(animateValue);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [value, isPercentage]);

  return (
    <Box position="relative" width="100%" height="24px" textAlign="center">
      <Box display="inline-block">
        <motion.span
          animate={isAnimating ? { 
            scale: [1, 1.1, 1],
            color: ["#000", "#8dc6ec", "#000"]
          } : {}}
          transition={{ duration: 1 }}
        >
          {isPercentage ? Math.round(displayValue) : Math.round(displayValue)}
          {isPercentage && '%'}
        </motion.span>
      </Box>
      <AnimatePresence>
        {isAnimating && icon && (
          <motion.span
            style={{
              position: 'absolute',
              right: '-5px',
              top: `${emojiVerticalOffset}px`,
              fontSize: '0.9em',
              lineHeight: '24px',
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 1 }}
          >
            <Icon as={icon} color={color} />
          </motion.span>
        )}
      </AnimatePresence>
    </Box>
  );
};

const EnhancedStatusIndicator = ({ status, onPause, onResume }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'active': return "primary.500";
      case 'completed': return "green.500";
      case 'paused': return "yellow.500";
      case 'scheduled': return "blue.500";
      default: return "gray.500";
    }
  };

  return (
    <HStack spacing={2} alignItems="center" height="40px">
      <Text color={getStatusColor(status)} fontWeight="medium" fontSize="md">
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Text>
      {(status === 'active' || status === 'paused') && (
        <Tooltip label={status === 'active' ? 'Pause campaign' : 'Resume campaign'}>
          <IconButton
            icon={status === 'active' ? <CiPause1 size={20} /> : <CiPlay1 size={20} />}
            size="sm"
            variant="outline"
            borderColor={getStatusColor(status)}
            color={getStatusColor(status)}
            onClick={status === 'active' ? onPause : onResume}
            aria-label={status === 'active' ? 'Pause campaign' : 'Resume campaign'}
          />
        </Tooltip>
      )}
    </HStack>
  );
};


const StatCard = ({ title, value, icon }) => (
  <Box borderWidth={1} borderRadius="lg" overflow="hidden" p={4} bg="white" boxShadow="sm" flex="1">
    <Stat>
      <Flex justify="space-between" align="center" mb={2}>
        <StatLabel color="gray.500" fontSize="sm">{title}</StatLabel>
        <Icon as={icon} color="blue.500" boxSize={6} />
      </Flex>
      <StatNumber fontSize="2xl" fontWeight="bold" color="gray.800">{value}</StatNumber>
    </Stat>
  </Box>
);

const CurrentCampaigns = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null);
  const [stats, setStats] = useState({});
  const [chartData, setChartData] = useState([]);
  const [selectedKPI, setSelectedKPI] = useState('openRate');
  const [selectedTimeRange, setSelectedTimeRange] = useState('7days');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [campaignProfile, setCampaignProfile] = useState(null);
  const [mailboxes, setMailboxes] = useState([]);
  const [currentPage, setCurrentPage] = useState('analytics');
  const currentPageRef = useRef('analytics');
  const [initialRecipients, setInitialRecipients] = useState([]);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [lists, setLists] = useState([]);
  const [imports, setImports] = useState([]);
  const recipientsDataFetched = useRef(false);
  const [preloadedRecipients, setPreloadedRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggledVariant, setToggledVariant] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingEmailCount, setPendingEmailCount] = useState(0);
const [isPendingEmailCountLoading, setIsPendingEmailCountLoading] = useState(true);
const [variantStats, setVariantStats] = useState({});
const [mailboxScheduledCounts, setMailboxScheduledCounts] = useState({});
const [isMailboxDataLoading, setIsMailboxDataLoading] = useState(true);
const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);
const [isReactivationModalOpen, setIsReactivationModalOpen] = useState(false);
const [deactivationOption, setDeactivationOption] = useState('cancelFollowUps');
const toast = useToast();
const [animatingCells, setAnimatingCells] = useState({
  campaign: {},
  variants: {},
  mailboxes: {}
});

const initialDataFetchedRef = useRef(false);


useEffect(() => {
  console.log('pendingEmailCount updated:', pendingEmailCount);
}, [pendingEmailCount]);


const handleSSEUpdate = useCallback((event) => {
  console.log('Received SSE event:', event.data);
  const { type, data } = JSON.parse(event.data);
  
  console.log('Parsed SSE data:', { type, data });
  console.log('Current campaign ID:', id);

  if (type === 'pendingEmailCount') {
    console.log('Updating pending email count:', data.count);
    setPendingEmailCount(prevCount => {
      const newCount = Number(data.count);
      console.log('New pending email count:', newCount);
      setAnimatingCells(prev => ({
        ...prev,
        campaign: { ...prev.campaign, pendingEmailCount: true }
      }));
      setTimeout(() => setAnimatingCells(prev => ({
        ...prev,
        campaign: { ...prev.campaign, pendingEmailCount: false }
      })), 500);
      return isNaN(newCount) ? prevCount : newCount;
    });
    setIsPendingEmailCountLoading(false);
    return;
  }
  
  if (data.campaignId !== id) {
    console.log('Ignoring event for different campaign');
    return;
  }

  switch (type) {
    case 'queueProgress':
    case 'queueComplete':
      console.log('Updating pending email count:', data.queuedEmailCount || data.pendingEmailCount);
      setPendingEmailCount(prevCount => {
        const newCount = Number(data.queuedEmailCount || data.pendingEmailCount);
        console.log('New pending email count:', newCount);
        setAnimatingCells(prev => ({
          ...prev,
          campaign: { ...prev.campaign, pendingEmailCount: true }
        }));
        setTimeout(() => setAnimatingCells(prev => ({
          ...prev,
          campaign: { ...prev.campaign, pendingEmailCount: false }
        })), 500);
        return isNaN(newCount) ? prevCount : newCount;
      });
      setIsPendingEmailCountLoading(false);
      break;
    case 'campaignUpdate':
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        ...data.campaign
      }));
      setStats(prevStats => ({
        ...prevStats,
        ...data.stats
      }));
      if (data.chartData) {
        setChartData(prevChartData => ([
          ...prevChartData,
          ...data.chartData
        ]));
      }
      break;
      case 'mailboxStatsUpdate':
        console.log('Received mailbox update:', data);
        console.log('Current animatingCells state:', JSON.stringify(animatingCells, null, 2));
      
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          fromEmails: prevCampaign.fromEmails.map(mailbox =>
            mailbox.email === data.email
              ? { ...mailbox, ...data.stats }
              : mailbox
          )
        }));
        setMailboxes(prevMailboxes => 
          prevMailboxes.map(mailbox =>
            mailbox.email === data.email
              ? { ...mailbox, ...data.stats }
              : mailbox
          )
        );
        setMailboxScheduledCounts(prevCounts => ({
          ...prevCounts,
          [data.email]: data.stats.scheduledEmails || 0
        }));
        
        // Update animatingCells for specific stats we want to animate
        const statsToAnimate = ['sentCount', 'scheduledEmails', 'openCount', 'replyCount'];
        statsToAnimate.forEach(stat => {
          if (stat in data.stats) {
            console.log(`Setting ${stat} to animate for ${data.email}`);
            setAnimatingCells(prevAnimatingCells => {
              const newState = {
                ...prevAnimatingCells,
                mailboxes: {
                  ...prevAnimatingCells.mailboxes,
                  [data.email]: {
                    ...prevAnimatingCells.mailboxes[data.email],
                    [stat]: true
                  }
                }
              };
              console.log('New animatingCells state:', JSON.stringify(newState, null, 2));
              return newState;
            });
      
            // Reset animation after 1000ms (1 second) for each stat individually
            setTimeout(() => {
              console.log(`Resetting animation for ${stat} of ${data.email}`);
              setAnimatingCells(prevAnimatingCells => {
                const newState = {
                  ...prevAnimatingCells,
                  mailboxes: {
                    ...prevAnimatingCells.mailboxes,
                    [data.email]: {
                      ...prevAnimatingCells.mailboxes[data.email],
                      [stat]: false
                    }
                  }
                };
                console.log('Reset animatingCells state:', JSON.stringify(newState, null, 2));
                return newState;
              });
            }, 1000);
          }
        });
        break;
    case 'campaignError':
      console.error('Campaign error:', data.error);
      break;
      case 'emailSent':
        console.log('Email sent event received:', data);
        setCampaign(prevCampaign => {
          if (!prevCampaign) {
            console.warn('Previous campaign state is null or undefined');
            return prevCampaign;
          }
          console.log('Updating campaign with new data:', data);
          
          // Use the sentCount from the server, or increment the previous count
          const newSentCount = data.sentCount ?? (prevCampaign.sentCount + 1);
          
          setAnimatingCells(prev => ({
            ...prev,
            campaign: { ...prev.campaign, sentCount: true }
          }));
          setTimeout(() => setAnimatingCells(prev => ({
            ...prev,
            campaign: { ...prev.campaign, sentCount: false }
          })), 500);
      
          return {
            ...prevCampaign,
            sentCount: newSentCount,
            stats: {
              ...prevCampaign.stats,
              ...(data.stats || {}),
              sent: data.stats?.sent ?? newSentCount
            }
          };
        });
        break;
      case 'variantUpdate':
        console.log('Received variant update:', data);
        setCampaign(prevCampaign => {
          const updatedVersions = prevCampaign.versions.map(version => 
            version.name === data.versionName 
              ? { ...version, stats: { ...version.stats, ...data.stats } }
              : version
          );
          
          // Update animatingCells only for the stats that changed
          const statsToAnimate = ['sent', 'opened', 'clicked', 'replied', 'bounced', 'pendingEmailCount'];
          statsToAnimate.forEach(stat => {
            if (data.stats.hasOwnProperty(stat) && data.stats[stat] !== prevCampaign.versions.find(v => v.name === data.versionName).stats[stat]) {
              console.log(`Setting ${stat} to animate for variant ${data.versionName}`);
              setAnimatingCells(prevAnimatingCells => ({
                ...prevAnimatingCells,
                variants: {
                  ...prevAnimatingCells.variants,
                  [data.versionName]: {
                    ...(prevAnimatingCells.variants[data.versionName] || {}),
                    [stat]: true
                  }
                }
              }));
      
              setTimeout(() => {
                console.log(`Resetting animation for ${stat} of variant ${data.versionName}`);
                setAnimatingCells(prevAnimatingCells => ({
                  ...prevAnimatingCells,
                  variants: {
                    ...prevAnimatingCells.variants,
                    [data.versionName]: {
                      ...(prevAnimatingCells.variants[data.versionName] || {}),
                      [stat]: false
                    }
                  }
                }));
              }, 1000);
            }
          });
          
          return { ...prevCampaign, versions: updatedVersions };
        });
        break;
    default:
      console.log('Unhandled SSE event type:', data.type);
  }
}, [id]);

useEffect(() => {
  if (campaign && campaign.fromEmails) {
    const scheduledCounts = {};
    campaign.fromEmails.forEach(mailbox => {
      scheduledCounts[mailbox.email] = mailbox.scheduledEmails || 0;
    });
    setMailboxScheduledCounts(scheduledCounts);
    setIsMailboxDataLoading(false);
  }
}, [campaign]);

  useEffect(() => {
    const handlePopState = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const pageParam = searchParams.get('page');
      if (pageParam && ['analytics', 'content', 'recipients'].includes(pageParam)) {
        setCurrentPage(pageParam);
        currentPageRef.current = pageParam;
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleMailboxToggle = async (email, isActive) => {
    try {
      // Here you would typically update the backend
      // For now, we'll just update the local state
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        fromEmails: prevCampaign.fromEmails.map(mailbox => 
          mailbox.email === email ? { ...mailbox, isActive } : mailbox
        )
      }));
    } catch (error) {
      console.error('Error toggling mailbox:', error);
    }
  };


  
// Updated and finalized function
const fetchCampaignData = useCallback(async (forceRefresh = true) => {
  if (!forceRefresh && initialDataFetchedRef.current) return;

  setIsLoading(true);
  setIsPendingEmailCountLoading(true);
  setIsMailboxDataLoading(true);

  try {
    const [rawCampaignData, mailboxesResponse] = await Promise.all([
      api.getRawCampaign(id),
      api.get('/api/mailboxes')
    ]);

    console.log('Raw campaign data received:', rawCampaignData);

    if (!rawCampaignData || !rawCampaignData.versions) {
      throw new Error('Invalid campaign data received');
    }

    const formattedVersions = rawCampaignData.versions.map(version => ({
      ...version,
      stats: {
        sent: version.stats?.sent || 0,
        opened: version.stats?.opened || 0,
        clicked: version.stats?.clicked || 0,
        replied: version.stats?.replied || 0,
        bounced: version.stats?.bounced || 0,
        pendingEmailCount: version.stats?.pendingEmailCount || 0
      }
    }));

    const updatedCampaignData = {
      ...rawCampaignData,
      versions: formattedVersions
    };

    console.log('Formatted campaign data:', updatedCampaignData);

    const initialPendingCount = rawCampaignData.pendingEmailCount || 0;
    console.log('Setting initial pending email count:', initialPendingCount);

    const scheduledCounts = {};
    updatedCampaignData.fromEmails.forEach(mailbox => {
      console.log(`Mailbox ${mailbox.email} scheduled emails:`, mailbox.scheduledEmails);
      scheduledCounts[mailbox.email] = mailbox.scheduledEmails || 0;
    });
    console.log('Initial mailbox scheduled counts:', scheduledCounts);

    // Update all states at once
    setCampaign(updatedCampaignData);
    setStats(rawCampaignData.stats || {});
    setPendingEmailCount(initialPendingCount);
    setMailboxes(mailboxesResponse.data);
    setMailboxScheduledCounts(scheduledCounts);

    initialDataFetchedRef.current = true;
    console.log('Campaign data and mailboxes fetched and set in state');
  } catch (error) {
    console.error('Error fetching campaign data:', error);
    // We're not setting states to null here to maintain consistency with the original function
  } finally {
    setIsLoading(false);
    setIsPendingEmailCountLoading(false);
    setIsMailboxDataLoading(false);
  }
}, [id]);
  
useEffect(() => {
  console.log('mailboxScheduledCounts updated:', mailboxScheduledCounts);
}, [mailboxScheduledCounts]);
  
  useEffect(() => {
    const isDataFetched = sessionStorage.getItem('campaignDataFetched');

    if (!isDataFetched) {
      // This is a fresh page load or a refresh
      fetchCampaignData(true);
    } else {
      // This is navigation between components
      fetchCampaignData(false);
    }

    // Clean up function
    return () => {
      // We don't remove the sessionStorage item here
    };
  }, [fetchCampaignData]);
  

  const fetchRecipientsData = useCallback(async () => {
    if (recipientsDataFetched.current) return;
  
    try {
      const [recipientsResponse, listsResponse, importsResponse] = await Promise.all([
        api.get(`/api/email-campaigns/${id}/recipients`, { params: { page: 1, pageSize: 25 } }),
        api.get('/api/lists'),
        api.getImports(id)
      ]);
  
      setInitialRecipients(recipientsResponse.data.recipients);
      setPreloadedRecipients(recipientsResponse.data.recipients); // Set preloadedRecipients here
      setTotalRecipients(recipientsResponse.data.total);
      setLists(listsResponse.data.lists);
      setImports(importsResponse.data);
  
      recipientsDataFetched.current = true;
    } catch (error) {
      console.error('Error fetching recipients data:', error);
    }
  }, [id]);

  useEffect(() => {
  
    fetchRecipientsData();
  }, [id, fetchRecipientsData]);
  
  useEffect(() => {
    if (currentPage === 'recipients' && !recipientsDataFetched.current) {
      fetchRecipientsData();
    }
  }, [currentPage, fetchRecipientsData]);

  const handleUpdateRecipients = useCallback((count) => {
    setTotalRecipients(count);
    // No need to call fetchCampaignData here
  }, []);

  const handleImportComplete = useCallback(() => {
    // Update total count
    api.get(`/api/email-campaigns/${id}/recipients/count`)
      .then(response => {
        setTotalRecipients(response.data.count);
      })
      .catch(error => {
        console.error('Error fetching updated recipient count:', error);
      });
  
    // Fetch updated recipients
    api.get(`/api/email-campaigns/${id}/recipients`, { params: { page: 1, pageSize: 25 } })
      .then(response => {
        setPreloadedRecipients(response.data.recipients);
      })
      .catch(error => {
        console.error('Error fetching updated recipients:', error);
      });
  
    // Refresh the imports list
    api.getImports(id)
      .then(response => {
        setImports(response.data);
      })
      .catch(error => {
        console.error('Error fetching updated imports:', error);
      });
  }, [id]);



  const updateURLForPage = useCallback((page) => {
    const url = new URL(window.location);
    url.searchParams.set('page', page);
    window.history.pushState({}, '', url);
    currentPageRef.current = page;
    setCurrentPage(page);
  }, []);

  const handlePageChange = useCallback((newPage) => {
    if (currentPageRef.current !== newPage) {
      updateURLForPage(newPage);
    }
  }, [updateURLForPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    if (pageParam && ['analytics', 'content', 'recipients'].includes(pageParam)) {
      setCurrentPage(pageParam);
      currentPageRef.current = pageParam;
      
      // Only fetch recipients data if we're switching to the recipients page
      // and the data hasn't been fetched yet
      if (pageParam === 'recipients' && !recipientsDataFetched.current) {
        fetchRecipientsData();
      }
    }
  }, [location, fetchRecipientsData]);

  const handleVariantToggle = (variantName, isCurrentlyActive) => {
    // isCurrentlyActive is true when the switch is ON (variant is active)
    // We're setting isActive to the new state we want to change to
    const isActive = !isCurrentlyActive;
    
    setToggledVariant({ name: variantName, isActive });
    
    if (isActive) {
      // If isActive is true, we're activating a previously inactive variant
      setIsReactivationModalOpen(true);
      setIsDeactivationModalOpen(false);
    } else {
      // If isActive is false, we're deactivating a currently active variant
      setIsDeactivationModalOpen(true);
      setIsReactivationModalOpen(false);
    }
  };
  
  const confirmVariantToggle = async () => {
    if (!toggledVariant) return;
  
    try {
      setIsLoading(true);
      const { name: variantName, isActive } = toggledVariant;
      console.log('Toggled variant:', toggledVariant);
      console.log('Is active:', isActive);
      console.log('Deactivation option:', deactivationOption);
  
      const response = await api.toggleVersion(id, variantName, isActive, deactivationOption);
  
      console.log('Response from backend:', response);
  
      if (response.campaign) {
        setCampaign(response.campaign);
      } else {
        console.warn('Campaign data not found in response');
      }
  
      toast({
        title: "Campaign updated",
        description: `Variant ${variantName} has been ${isActive ? 'activated' : 'deactivated'}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
  
    } catch (error) {
      console.error('Error toggling variant:', error);
      toast({
        title: "Error updating campaign",
        description: "There was an error updating the campaign. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsDeactivationModalOpen(false);
      setIsReactivationModalOpen(false);
      setToggledVariant(null);
    }
  };
  const handleCampaignStatusChange = async (newStatus) => {
    try {
      await api.put(`/api/email-campaigns/${id}/status`, { status: newStatus });
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        status: newStatus
      }));
    } catch (error) {
      console.error('Error changing campaign status:', error);
    }
  };

 // Update the SSE effect
 useEffect(() => {
  let eventSource;
  let retryCount = 0;
  let heartbeatInterval;
  let reconnectionTimeout;
  const maxRetries = 10;
  const baseDelay = 5000;
  const maxDelay = 60000;

  const setupSSE = () => {
    eventSource = new EventSource('/api/sse');
    
    eventSource.onopen = () => {
      console.log('SSE connection established');
      retryCount = 0;
      startHeartbeat();
    };

    eventSource.onmessage = (event) => {
      console.log('SSE message received:', event.data);
      handleSSEUpdate(event);
      resetHeartbeat();
    };
    
    eventSource.onerror = (error) => {
      console.error('SSE error:', error);
      eventSource.close();
      clearInterval(heartbeatInterval);
      reconnect();
    };
  };

  const startHeartbeat = () => {
    heartbeatInterval = setInterval(() => {
      if (eventSource.readyState === EventSource.CLOSED) {
        console.log('Connection closed, attempting to reconnect');
        reconnect();
      }
    }, 30000); // Check every 30 seconds
  };

  const resetHeartbeat = () => {
    clearTimeout(reconnectionTimeout);
    reconnectionTimeout = setTimeout(() => {
      console.log('No message received for 60 seconds, reconnecting');
      reconnect();
    }, 60000);
  };

  const reconnect = () => {
    if (retryCount < maxRetries) {
      const delay = Math.min(maxDelay, baseDelay * Math.pow(2, retryCount));
      console.log(`Attempting to reconnect in ${delay}ms (attempt ${retryCount + 1}/${maxRetries})`);
      setTimeout(setupSSE, delay);
      retryCount++;
    } else {
      console.error('Max retries reached. SSE connection failed.');
    }
  };

  setupSSE();
  fetchCampaignData();

  return () => {
    if (eventSource) {
      eventSource.close();
    }
    clearInterval(heartbeatInterval);
    clearTimeout(reconnectionTimeout);
  };
}, [id, handleSSEUpdate, fetchCampaignData]);

  const openSettingsModal = () => setIsSettingsModalOpen(true);
  const closeSettingsModal = () => setIsSettingsModalOpen(false);

  const handleSettingsSave = async (newSettings) => {
    try {
      await api.updateCampaign(id, { campaignProfile: newSettings._id });
      const updatedCampaign = await api.getCampaign(id);
      setCampaign(updatedCampaign);
      setCampaignProfile(newSettings);
      closeSettingsModal();
    } catch (error) {
      console.error('Error updating campaign settings:', error);
    }
  };

  useEffect(() => {
    console.log('Campaign data in state:', campaign);
    if (campaign && campaign.versions) {
      console.log('Versions data:');
      campaign.versions.forEach(version => {
        console.log(`Version ${version.name}:`, version);
        console.log(`Version ${version.name} stats:`, version.stats);
      });
    }
  }, [campaign]);
  
  if (!campaign) return <Box>Loading...</Box>;

  return (
    <Container maxWidth="1250px" p={5}>
      {/* Campaign header */}
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <HStack>
          <Text fontSize="2xl" fontWeight="bold">{campaign.name}</Text>
         <EnhancedStatusIndicator
            status={campaign.status}
            onPause={() => handleCampaignStatusChange('paused')}
            onResume={() => handleCampaignStatusChange('active')}
          />
        </HStack>
        <HStack>
          <Button 
            variant="outline" 
            isActive={currentPage === 'analytics'}
            onClick={() => handlePageChange('analytics')}
          >
            Analytics
          </Button>
          <Button 
            variant="outline" 
            isActive={currentPage === 'content'}
            onClick={() => handlePageChange('content')}
          >
            Content
          </Button>
          <Button 
            variant="outline" 
            isActive={currentPage === 'recipients'}
            onClick={() => handlePageChange('recipients')}
          >
            Recipients
          </Button>
          <Button variant="outline" onClick={openSettingsModal}>Campaign Settings</Button>
        </HStack>
      </Flex>
  
      {currentPage === 'analytics' && (
        <>
          {/* Statistics cards */}
          <Flex justifyContent="space-between" flexWrap="wrap" gap={4} mb={8}>
            <StatCard title="Total Recipients" value={isLoading ? <Spinner /> : campaign.recipientCount} icon={FaUsers} />
            <StatCard 
  title="Emails Sent" 
  value={
    isLoading ? (
      <Spinner /> 
    ) : (
      <LeftAlignedSmoothNumber 
        value={campaign.sentCount} 
        isAnimating={animatingCells.campaign?.sentCount}
      />
    )
  } 
  icon={FaPaperPlane} 
/>   
 <StatCard 
  title="Avg. Open Rate" 
  value={
    isLoading 
      ? <Spinner /> 
      : `${Math.round(campaign.openRate || 0)}%`
  } 
  icon={FaChartLine} 
/>            
<StatCard 
  title="Emails in Queue" 
  value={
    isPendingEmailCountLoading ? (
      <Spinner size="sm" />
    ) : (
      <LeftAlignedSmoothNumber 
        value={pendingEmailCount} 
        isAnimating={animatingCells.campaign?.pendingEmailCount}
      />
    )
  } 
  icon={FaRegEnvelope} 
/>
          </Flex>
  
          {/* Performance chart */}
          <Box borderWidth={1} borderRadius="lg" p={4} mb={8}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Text fontSize="xl" fontWeight="bold">Performance Chart</Text>
              <Select value={selectedTimeRange} onChange={(e) => setSelectedTimeRange(e.target.value)} width="200px">
                <option value="7days">Last 7 days</option>
                <option value="30days">Last 30 days</option>
                <option value="custom">Custom Range</option>
              </Select>
            </Flex>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <RechartsTooltip />
                  <Line type="monotone" dataKey={selectedKPI} stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Box>
  
          {/* Variant performance */}
      {/* Variant performance */}
      <Box borderWidth={1} borderRadius="lg" p={4} mb={8}>
            <Text fontSize="xl" fontWeight="bold" mb={4}>Variant Performance</Text>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="80px" textAlign="center">Version</Th>
                    <Th width="300px">Subject Line</Th>
                    <Th width="100px" textAlign="center">SENT</Th>
                    <Th width="100px" textAlign="center">SCHEDULED</Th>
                    <Th width="100px" textAlign="center">OPENS</Th>
                    <Th width="100px" textAlign="center">REPLIES</Th>
                    <Th width="100px">Status</Th>
                    <Th width="50px"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {campaign.versions && campaign.versions.map((version, index) => {
                    const stats = version.stats || {};
                    const openRate = stats.sent > 0 ? Math.round((stats.opened / stats.sent) * 100) : 0;
                    const replyRate = stats.sent > 0 ? Math.round((stats.replied / stats.sent) * 100) : 0;
                    const subjectLine = version.emails && version.emails[0] ? version.emails[0].subject : 'N/A';

                    return (
                      <Tr key={version.name}>
                        {/* Version name and crown icon */}
                        <Td width="80px">
                          <Flex justifyContent="center" alignItems="center">
                            <Text>{version.name}</Text>
                            {index === 0 && <Icon as={FaCrown} color="gold" ml={2} />}
                          </Flex>
                        </Td>

                        {/* Subject line */}
                        <Td width="300px">
                          <Tooltip label={subjectLine} placement="top-start">
                            <Flex height="48px" alignItems="center" justifyContent="flex-start">
                              <Text
                                maxWidth="280px"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "1.2em",
                                  maxHeight: "2.4em"
                                }}
                              >
                                {subjectLine}
                              </Text>
                            </Flex>
                          </Tooltip>
                        </Td>

                        {/* Sent count */}
                        <Td width="100px">
                          <Flex justifyContent="center">
                            <SmoothNumber
                              value={stats.sent || 0}
                              isAnimating={animatingCells.variants[version.name]?.sent}
                              icon={FaPaperPlane}
                              color="green.500"
                              emojiVerticalOffset={0}
                            />
                          </Flex>
                        </Td>

                        {/* Scheduled count */}
                        <Td width="100px">
                          <Flex justifyContent="center">
                            <SmoothNumber
                              value={stats.pendingEmailCount || 0}
                              isAnimating={animatingCells.variants[version.name]?.pendingEmailCount}
                              icon={FaRegEnvelope}
                              color="purple.500"
                              emojiVerticalOffset={0}
                            />
                          </Flex>
                        </Td>

                        {/* Open rate */}
                        <Td width="100px">
                          <Flex justifyContent="center">
                            <SmoothNumber
                              value={openRate}
                              isAnimating={animatingCells.variants[version.name]?.opened}
                              icon={FaChartLine}
                              color="orange.500"
                              emojiVerticalOffset={0}
                              isPercentage={true}
                            />
                          </Flex>
                        </Td>

                        {/* Reply rate */}
                        <Td width="100px">
                          <Flex justifyContent="center">
                            <SmoothNumber
                              value={replyRate}
                              isAnimating={animatingCells.variants[version.name]?.replied}
                              icon={FaCommentDots}
                              color="blue.500"
                              emojiVerticalOffset={0}
                              isPercentage={true}
                            />
                          </Flex>
                        </Td>

                        {/* Status switch */}
                        <Td width="100px">
                        <Switch 
  isChecked={!version.removed}  // ON when not removed
  onChange={() => handleVariantToggle(version.name, !version.removed)}
  isDisabled={isLoading}
/>
                        </Td>

                        {/* Edit button */}
                        <Td width="50px">
                          <IconButton
                            aria-label="Edit version"
                            icon={<EditIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => {/* Placeholder for edit functionality */}}
                            isDisabled={isLoading}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>

          {/* Email account monitoring */}
          <Box borderWidth={1} borderRadius="lg" p={4}>
  <Text fontSize="xl" fontWeight="bold" mb={4}>Email Account Monitoring</Text>
  <Box overflowX="auto">
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th width="370px">Mailbox</Th>
          <Th width="15px"></Th>
          <Th width="107px" textAlign="center">SENT</Th>
          <Th width="107px" textAlign="center">SCHEDULED</Th>
          <Th width="107px" textAlign="center">OPENS</Th>
          <Th width="107px" textAlign="center">REPLIES</Th>
          <Th width="102px">Status</Th>
          <Th width="60px"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {campaign.fromEmails.map((fromEmail) => {
          const mailbox = mailboxes.find(m => m.email === fromEmail.email) || {};
          const openRate = fromEmail.sentCount > 0 ? Math.round((fromEmail.openCount / fromEmail.sentCount) * 100) : 0;
          const replyRate = fromEmail.sentCount > 0 ? Math.round((fromEmail.replyCount / fromEmail.sentCount) * 100) : 0;

          return (
            <Tr key={fromEmail.email}>
              <Td width="370px">
                <HStack spacing={3}>
                  <Avatar name={fromEmail.email} src={mailbox.profilePicture} size="sm" />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="medium">{fromEmail.email}</Text>
                    <Text fontSize="xs" color="gray.500">{mailbox.provider || fromEmail.provider}</Text>
                  </VStack>
                </HStack>
              </Td>
              <Td width="15px"></Td>
              <Td width="107px">
  <Flex justifyContent="center">
    <SmoothNumber
      value={fromEmail.sentCount || 0}
      isAnimating={animatingCells.mailboxes[fromEmail.email]?.sentCount}
      icon={FaPaperPlane}
      color="green.500"
      emojiVerticalOffset={0}
    />
  </Flex>
</Td>
<Td width="107px">
  <Flex justifyContent="center">
    {isMailboxDataLoading ? (
      <Spinner size="sm" />
    ) : (
      <SmoothNumber
        value={mailboxScheduledCounts[fromEmail.email] || 0}
        isAnimating={animatingCells.mailboxes[fromEmail.email]?.scheduledEmails}
        icon={FaRegEnvelope}
        color="purple.500"
        emojiVerticalOffset={0}
      />
    )}
  </Flex>
</Td>
<Td width="107px">
  <Flex justifyContent="center">
    <SmoothNumber
      value={openRate}
      isAnimating={animatingCells.mailboxes[fromEmail.email]?.openCount}
      icon={FaChartLine}
      color="orange.500"
      emojiVerticalOffset={0}
      isPercentage={true}
    />
  </Flex>
</Td>
<Td width="107px">
  <Flex justifyContent="center">
    <SmoothNumber
      value={replyRate}
      isAnimating={animatingCells.mailboxes[fromEmail.email]?.replyCount}
      icon={FaCommentDots}
      color="blue.500"
      emojiVerticalOffset={0}
      isPercentage={true}
    />
  </Flex>
</Td>
              <Td width="102px">
                <Switch 
                  isChecked={!fromEmail.removed}
                  onChange={() => handleMailboxToggle(fromEmail.email, !fromEmail.removed)}
                />
              </Td>
              <Td width="60px">
                <IconButton
                  aria-label="Mailbox settings"
                  icon={<SettingsIcon />}
                  size="sm"
                  variant="ghost"
                  onClick={() => {/* Placeholder for mailbox settings functionality */}}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  </Box>
</Box>
        </>
      )}

  
  {currentPage === 'content' && (
      <CampaignContent
        campaign={campaign}
        setCampaign={setCampaign}
        id={id}
      />
    )}

    {/* Campaign Settings Modal */}
    <Modal isOpen={isSettingsModalOpen} onClose={closeSettingsModal} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Campaign Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CampaignSettings 
            currentProfileId={campaign.campaignProfile?._id}
            initialSettings={campaignProfile}
            onSave={handleSettingsSave}
            isInModal={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
    {currentPage === 'recipients' && (
         <RecipientSelection
         campaignId={id}
         campaignName={campaign.name}
         initialRecipients={initialRecipients}
         totalRecipients={totalRecipients}
         onUpdateRecipients={handleUpdateRecipients}
         initialLists={lists}
         initialImports={imports}
         onImportComplete={handleImportComplete}
         preloadedRecipients={preloadedRecipients}
         
        />
      )}
       {/* Deactivation Modal */}
    {/* Deactivation Modal */}
{/* Deactivation Modal */}
<Modal isOpen={isDeactivationModalOpen} onClose={() => setIsDeactivationModalOpen(false)} isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Deactivate Variant</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Text mb={4}>Are you sure you want to deactivate variant {toggledVariant?.name}?</Text>
      <RadioGroup onChange={setDeactivationOption} value={deactivationOption}>
        <Stack>
          <Radio value="cancelFollowUps">
            Cancel remaining follow-ups: Recipients who have started receiving emails will not receive any more follow-ups from this variant.
          </Radio>
          <Radio value="finishSequence">
            Finish sequence: Recipients who have started receiving emails will continue to receive the remaining follow-ups from this variant.
          </Radio>
        </Stack>
      </RadioGroup>
    </ModalBody>
    <ModalFooter>
      <Button onClick={() => setIsDeactivationModalOpen(false)} mr={3}>Cancel</Button>
      <Button colorScheme="blue" onClick={confirmVariantToggle} isLoading={isLoading}>
        Confirm
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

{/* Reactivation Modal */}
<Modal isOpen={isReactivationModalOpen} onClose={() => setIsReactivationModalOpen(false)} isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Reactivate Variant</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Text>
        Are you sure you want to reactivate variant {toggledVariant?.name}?
        This will redistribute some recipients to this variant.
      </Text>
    </ModalBody>
    <ModalFooter>
      <Button onClick={() => setIsReactivationModalOpen(false)} mr={3}>Cancel</Button>
      <Button colorScheme="blue" onClick={confirmVariantToggle} isLoading={isLoading}>
        Confirm
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>
  </Container>
);
}
export default CurrentCampaigns;
