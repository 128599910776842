import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box, Container, Flex, Heading, Text, Button, Input, VStack, HStack,
  useToast, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel,
  Select, Switch, Checkbox, Stack, Menu, MenuButton, MenuList, MenuItem,
  Tooltip, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
} from "@chakra-ui/react";
import { 
  AddIcon, 
  ChevronDownIcon, 
  SettingsIcon, 
  LinkIcon,
  AttachmentIcon,
  Icon,
  EditIcon,
  CheckIcon,
  CloseIcon,
  ViewIcon
} from '@chakra-ui/icons';
import { createIcon } from "@chakra-ui/icon";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import api from '../api';
import TiptapEditor from './TiptapEditor';
import { defaultAttributes } from './AttributeModal';
import UnsubscribeModal from './UnsubscribeModal';
import SignatureManager from './SignatureManager';
import { debounce } from 'lodash'; 
import EmailPreview from './EmailPreview';
import RecipientSelection from './RecipientSelection';
import WrapUp from './WrapUp';
import CampaignSettings from './CampaignSettings';
import { SkeletonLoader1, SkeletonLoader2, SkeletonLoader3 } from './SkeletonLoader';
import useWrapUpData from './useWrapUpData';
import FollowUpModal from './FollowUpModal';

const TiptapSubject = React.forwardRef(({ value, onChange, placeholder, onFocus, className }, ref) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getText());
    },
    onFocus: () => {
      console.log('TiptapSubject focused');
      onFocus();
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
      },
    },
  });

  useEffect(() => {
    if (editor && value !== editor.getText()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <Box 
      position="relative" 
      ref={ref} 
      onFocus={() => {
        console.log('TiptapSubject box focused');
        editor?.commands.focus();
      }}
      sx={{
        '& .ProseMirror': {
          outline: 'none',
          padding: '8px',
          minHeight: '40px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'gray.200',
          borderRadius: 'md',
          transition: 'all 0.2s',
        },
        '& .ProseMirror:focus': {
          borderColor: 'blue.500',
          boxShadow: 'none',
        }
      }}
    >
      <EditorContent editor={editor} className={className} />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        pointerEvents="none"
        color="gray.500"
        padding="8px"
      >
        {!value && placeholder}
      </Box>
    </Box>
  );
});



const CreateCampaign = () => {
  const [campaign, setCampaign] = useState({
    name: '',
    fromEmails: [],
    versions: [{ name: 'A', emails: [{ day: 1, subject: '', body: '' }] }],
    tracking: { opens: true, clicks: true }
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const [activeVersion, setActiveVersion] = useState('A');
  const [activeEmailIndex, setActiveEmailIndex] = useState(0);
  const [subject, setSubject] = useState('');
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState('');
  const [isInboxRotation, setIsInboxRotation] = useState(false);
  const [currentStep, setCurrentStep] = useState('content');
  const [mailboxes, setMailboxes] = useState([]);

  const [availableEmails, setAvailableEmails] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [followUpDays, setFollowUpDays] = useState(2);
  const [editingFollowUpIndex, setEditingFollowUpIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState('content');
  const [editorContent, setEditorContent] = useState('');
  const [currentFocusedEditor, setCurrentFocusedEditor] = useState('main');
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
  const [campaignStartDate, setCampaignStartDate] = useState('Immediately');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [localSubject, setLocalSubject] = useState('');
  const [localContent, setLocalContent] = useState('');
  const activeVersionRef = useRef(activeVersion);
const activeEmailIndexRef = useRef(activeEmailIndex);
const [isEditing, setIsEditing] = useState(false);
const [hasEdited, setHasEdited] = useState(false);
const [isLoading, setIsLoading] = useState(true);
  const [isContentReady, setIsContentReady] = useState(false);
  const [initialRecipients, setInitialRecipients] = useState([]);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [lists, setLists] = useState([]);
  const [imports, setImports] = useState([]);
  const [preloadedRecipients, setPreloadedRecipients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const { wrapUpData, error, refreshWrapUpData } = useWrapUpData(id);

const updateURLAndRefsWithoutRender = useCallback((version, emailIndex) => {
  const url = new URL(window.location);
  url.searchParams.set('version', version);
  url.searchParams.set('email', emailIndex.toString());
  window.history.pushState({}, '', url);
  activeVersionRef.current = version;
  activeEmailIndexRef.current = emailIndex;
  
  // Update state values as well
  setActiveVersion(version);
  setActiveEmailIndex(emailIndex);
}, [setActiveVersion, setActiveEmailIndex]);

const updateURLForPage = useCallback((page) => {
  const url = new URL(window.location);
  
  // Always set the new page parameter
  url.searchParams.set('page', page);
  
  if (page !== 'content') {
    // Remove version and email parameters for non-content pages
    url.searchParams.delete('version');
    url.searchParams.delete('email');
  } else {
    // For the content page, ensure version and email are set
    // Use the current active version and email index
    url.searchParams.set('version', activeVersionRef.current);
    url.searchParams.set('email', activeEmailIndexRef.current.toString());
  }
  
  // Update the URL
  window.history.pushState({}, '', url);
  
  // Update the current page state
  setCurrentPage(page);
}, []);

const startCampaign = async () => {
  console.log('startCampaign function called');
  try {
    console.log('Setting isSaving to true');
    setIsSaving(true);
    console.log('Calling api.startCampaign');
    
    // Start the campaign but don't await the full response
    api.startCampaign(id, campaignStartDate).then(response => {
      console.log('Campaign started successfully:', response);
    }).catch(error => {
      console.error('Error in background campaign start:', error);
    });

    // Show success toast immediately
    toast({
      title: "Campaign is starting",
      description: "Your campaign has been queued to start. You'll be notified when it's fully processed.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    
    console.log('Navigating to campaign page');
    navigate(`/current-campaigns/${id}`);
  } catch (error) {
    console.error('Error initiating campaign start:', error);
    toast({
      title: "Error starting campaign",
      description: "An unexpected error occurred while trying to start the campaign.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  } finally {
    console.log('Setting isSaving to false');
    setIsSaving(false);
  }
};

useEffect(() => {
  console.log('isSaving state changed:', isSaving);
}, [isSaving]);

const forceSave = useCallback(() => {
  if (debouncedSaveRef.current) {
    debouncedSaveRef.current.flush();
  }
}, []);

const handleContinue = useCallback(async () => {
  setIsSaving(true);
  try {
    if (currentPage === 'content') {
      await forceSave();
      await refreshWrapUpData();
      updateURLForPage('recipients');
    } else if (currentPage === 'recipients') {
      await refreshWrapUpData();
      updateURLForPage('wrapUp');
    }
  } catch (error) {
    console.error('Error updating data:', error);
    toast({
      title: "Error updating data",
      description: "Failed to save or refresh the latest data. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  } finally {
    setIsSaving(false);
  }
}, [currentPage, forceSave, refreshWrapUpData, toast, updateURLForPage]);

const handleBack = useCallback(() => {
  if (currentPage === 'wrapUp') {
    updateURLForPage('recipients');
  } else if (currentPage === 'recipients') {
    updateURLForPage('content');
  }
}, [currentPage, updateURLForPage]);

const handlePageChange = useCallback(async (newPage) => {
  if (currentPage === 'content' && newPage !== 'content') {
    setIsSaving(true);
    try {
      await forceSave();
      await refreshWrapUpData();
    } catch (error) {
      console.error('Error updating data:', error);
      toast({
        title: "Error updating data",
        description: "Failed to save or refresh the latest data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  }
  updateURLForPage(newPage);
}, [currentPage, forceSave, refreshWrapUpData, toast, updateURLForPage]);


  const handleStartDateChange = (value) => {
    setCampaignStartDate(value);
    // Update the campaign in the backend
    updateCampaign({ startDate: value });
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const handleSettingsSave = (newSettings) => {
    // Update the campaign with the new settings
    updateCampaign({ campaignProfile: newSettings._id });
    closeSettingsModal();
  };

  useEffect(() => {
    console.log('currentPage changed to:', currentPage);
  }, [currentPage]);
  
  const handleUpdateRecipients = (count) => {
    setCampaign(prev => ({ ...prev, recipientCount: count }));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'content':
        return <Box>Content Step (Your existing content here)</Box>;
      case 'recipients':
        return <RecipientSelection campaignId={id} onUpdateRecipients={handleUpdateRecipients} />;
      case 'preview':
        return <Box>Preview Step (To be implemented)</Box>;
      default:
        return null;
    }
  };

  const [unsubscribeOptions, setUnsubscribeOptions] = useState({
    method: campaign?.unsubscribeMethod || 'link',
    keyword: campaign?.unsubscribeKeyword || '',
    sentence: campaign?.unsubscribeCustomText || 'If you wish to unsubscribe, [UNSUBSCRIBE_LINK].',
    anchorText: campaign?.unsubscribeAnchorText || 'click here'
  });

  const debouncedSaveRef = useRef(null);


  const debouncedSave = useCallback(
    debounce(async (campaignData) => {
      setIsSaving(true);
      try {
        await Promise.all([
          api.put(`/api/email-campaigns/${id}`, campaignData),
          api.cacheCampaign(id, campaignData)
        ]);
        console.log('Campaign saved successfully to database and cache');
        setIsSaving(false);
      } catch (error) {
        console.error('Error saving campaign:', error);
        setIsSaving(false);
      }
    }, 60000),
    [id]
  );

  useEffect(() => {
    debouncedSaveRef.current = debouncedSave;
  }, [debouncedSave]);

  const updateCampaign = useCallback((data) => {
    setCampaign(prev => {
      const updatedCampaign = { ...prev, ...data };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
  }, [debouncedSave]);

  const saveOnExit = useCallback(() => {
    if (campaign) {
      api.put(`/api/email-campaigns/${id}`, campaign)
        .then(() => {
          console.log('Campaign saved on exit');
        })
        .catch((error) => {
          console.error('Error saving campaign on exit:', error);
        });
    }
  }, [campaign, id]);

  useEffect(() => {
    window.addEventListener('beforeunload', saveOnExit);
    return () => {
      window.removeEventListener('beforeunload', saveOnExit);
    };
  }, [saveOnExit]);

  useEffect(() => {
    if (campaign && campaign.fromEmails) {
      fetchMailboxes(campaign.fromEmails);
    }
  }, [campaign]);

  const fetchMailboxes = async (emails) => {
    try {
      const mailboxData = await Promise.all(
        emails.map(email => api.get(`/api/mailboxes/${email}`))
      );
      setMailboxes(mailboxData.map(response => response.data));
    } catch (error) {
      console.error('Error fetching mailboxes:', error);
      // Handle error (e.g., show toast notification)
    }
  };

  const handleSignatureSave = async (updatedSignatureSettings) => {
    try {
      const newSignatureSettings = Object.entries(updatedSignatureSettings).map(([email, settings]) => ({
        email,
        ...settings
      }));
  
      // Immediate API call to update signature settings
      await api.updateCampaign(campaign._id, { signatureSettings: newSignatureSettings });
  
      setCampaign(prevCampaign => {
        const updatedCampaign = {
          ...prevCampaign,
          signatureSettings: newSignatureSettings
        };
  
        // Use the debouncedSave function for any other changes
        debouncedSave(updatedCampaign);
  
        return updatedCampaign;
      });
  
      setIsSignatureModalOpen(false);
      refreshWrapUpData();
  
      toast({
        title: "Signature updated",
        description: "Your signature settings have been saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating signature:', error);
      toast({
        title: "Error updating signature",
        description: "Failed to update signature. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

const getCurrentEmailSignatureSettings = () => {
  if (!campaign || !campaign.fromEmails || !campaign.signatureSettings) {
    return {};
  }
  const currentEmail = campaign.fromEmails[0]?.email || campaign.fromEmails[0];
  
  if (Array.isArray(campaign.signatureSettings)) {
    return campaign.signatureSettings.find(s => s.email === currentEmail) || {};
  } else if (typeof campaign.signatureSettings === 'object') {
    return campaign.signatureSettings[currentEmail] || {};
  }
  
  return {};
};

  
  const toggleSignature = () => {
    setCampaign(prevCampaign => {
      const updatedSignatureSettings = prevCampaign.signatureSettings.map(setting => {
        if (prevCampaign.fromEmails.some(email => email.email === setting.email || email === setting.email)) {
          return {
            ...setting,
            showSignature: !setting.showSignature
          };
        }
        return setting;
      });
  
      const updatedCampaign = {
        ...prevCampaign,
        signatureSettings: updatedSignatureSettings
      };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
  };

  const stripHtml = (html) => {
    // Replace <br>, <p>, and other block-level elements with newlines
    const textWithLineBreaks = html.replace(/<br\s*\/?>/gi, '\n')
                                   .replace(/<\/?(p|div|h[1-6]|ul|ol|li|blockquote)[^>]*>/gi, '\n')
                                   .replace(/\n{3,}/g, '\n\n'); // Replace multiple newlines with max two
  
    // Remove remaining HTML tags
    const tmp = document.createElement("DIV");
    tmp.innerHTML = textWithLineBreaks;
    
    // Preserve newlines in the text content
    return tmp.textContent || tmp.innerText || "";
  };

  const updateCurrentEmail = useCallback((newSubject, newContent) => {
    // Get the current URL parameters
    const searchParams = new URLSearchParams(window.location.search);
    const versionParam = searchParams.get('version');
    const emailIndexParam = searchParams.get('email');
  
    // Use URL parameters if available, otherwise use ref values
    const currentVersion = versionParam || activeVersionRef.current;
    const currentEmailIndex = emailIndexParam ? parseInt(emailIndexParam, 10) : activeEmailIndexRef.current;
  
    console.log('Updating current email:', { 
      newSubject, 
      newContent, 
      activeVersion: currentVersion, 
      activeEmailIndex: currentEmailIndex 
    });
    
    setCampaign(prev => {
      const versionToUpdate = prev.versions.find(v => v.name === currentVersion);
      if (!versionToUpdate) {
        console.error(`Version ${currentVersion} not found`);
        return prev;
      }
  
      const emailToUpdate = versionToUpdate.emails[currentEmailIndex];
      if (!emailToUpdate) {
        console.error(`Email at index ${currentEmailIndex} not found in version ${currentVersion}`);
        return prev;
      }
  
      if (emailToUpdate.subject === newSubject && emailToUpdate.body === newContent) {
        console.log('No changes detected, skipping update');
        return prev;
      }
  
      console.log(`Updating email for version ${currentVersion}, index ${currentEmailIndex}`);
  
      const updatedVersions = prev.versions.map(v => 
        v.name === currentVersion
          ? {
              ...v,
              emails: v.emails.map((email, index) => 
                index === currentEmailIndex
                  ? { ...email, subject: newSubject, body: newContent }
                  : email
              )
            }
          : v
      );
      
      const updatedCampaign = { ...prev, versions: updatedVersions };
      
      // Immediately cache the updated campaign
      api.cacheCampaign(id, updatedCampaign).catch(error => {
        console.error('Error caching campaign:', error);
      });
  
      // Still use debouncedSave for updating the database
      debouncedSave(updatedCampaign);
  
      return updatedCampaign;
    });
  
    // Update the URL to reflect any changes
    updateURLAndRefsWithoutRender(currentVersion, currentEmailIndex);
  }, [id, debouncedSave, updateURLAndRefsWithoutRender]);

  const debouncedCache = useCallback(
    debounce((campaignData) => {
      api.cacheCampaign(id, campaignData).catch(error => {
        console.error('Error caching campaign:', error);
      });
    }, 2000), // Cache every 2 seconds of inactivity
    [id]
  );




  const editor = useEditor({
    extensions: [StarterKit],
    content: editorContent,
    onUpdate: ({ editor }) => {
      setEditorContent(editor.getHTML());
      updateCurrentEmail(subject, editor.getText());
    },
  });

  useEffect(() => {
    // Update local state when active email changes
    const currentVersion = campaign.versions.find(v => v.name === activeVersion);
    if (currentVersion && currentVersion.emails[activeEmailIndex]) {
      const currentEmail = currentVersion.emails[activeEmailIndex];
      setLocalSubject(currentEmail.subject || '');
      setLocalContent(currentEmail.body || '');
      setHasEdited(false); 
    }
  }, [activeVersion, activeEmailIndex, campaign.versions]);

  const handleInsertIntoSubject = useCallback((attribute) => {
    const newSubject = subject + attribute;
    setSubject(newSubject);
    updateCurrentEmail(newSubject, editorContent);
  }, [subject, editorContent, updateCurrentEmail]);

  const handleFocusChange = useCallback((editor) => {
    console.log(`Focus changing to: ${editor}`);
    setFocusedEditor(editor);
  }, []);

  const replaceAttributesWithDefaults = (text) => {
    return text.replace(/\{([^:}]+)(?::([^}]+))?\}/g, (match, name, fallback) => {
      const attribute = defaultAttributes.find(attr => attr.name === name);
      return attribute ? attribute.example : (fallback || match);
    });
  };

  const handleEditorChange = useCallback(() => {
    setIsEditing(true);
    if (editor) {
      const newContent = editor.getHTML();
      setEditorContent(newContent);
      updateCurrentEmail(subject, newContent);
    }
    // Set isEditing back to false after a short delay
    setTimeout(() => {
      setIsEditing(false);
    }, 500);
  }, [editor, subject, updateCurrentEmail]);


  const handleInsertAttribute = useCallback((attributeName, fallback) => {
    console.log('handleInsertAttribute called in CreateCampaign');
    console.log('Current focused editor:', currentFocusedEditor);
    const attribute = `{${attributeName}:${fallback}} `;
    
    if (currentFocusedEditor === 'subject') {
      console.log('Inserting attribute in subject line');
      const newSubject = subject + attribute;
      setSubject(newSubject);
      updateCurrentEmail(newSubject, editorContent);
      
      // Force focus on the subject input after a short delay
      setTimeout(() => {
        const subjectInput = document.querySelector('.subject-input .ProseMirror');
        if (subjectInput) {
          subjectInput.focus();
          const range = document.createRange();
          range.selectNodeContents(subjectInput);
          range.collapse(false);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          console.log('Subject input focused and cursor moved to end');
        } else {
          console.log('Subject input not found');
        }
      }, 10);
    } else {
      console.log('Not inserting in subject line, current focused editor:', currentFocusedEditor);
    }
  }, [currentFocusedEditor, subject, editorContent, updateCurrentEmail]);
 
  
  


  
  const getPlaceholderText = useCallback(() => {
    return activeEmailIndex === 0 ? "Compose your email..." : "Compose your follow-up...";
  }, [activeEmailIndex]);
  

  const isEditingRef = useRef(false);


  useEffect(() => {
    if (id && !isInitialized) {
      const searchParams = new URLSearchParams(location.search);
      const versionParam = searchParams.get('version');
      const emailIndexParam = searchParams.get('email');
  
      Promise.all([fetchCampaign(), fetchAvailableEmails()])
        .then(([campaignData, availableEmails]) => {
          setCampaign(campaignData);
          setNewCampaignName(campaignData.name);
          setIsInboxRotation(campaignData.fromEmails.length > 1);
          setIsInitialized(true);
  
          // Set initial version and email based on URL params or defaults
          const initialVersion = versionParam && campaignData.versions.some(v => v.name === versionParam)
            ? versionParam
            : campaignData.versions[0].name;
          const initialEmailIndex = emailIndexParam 
            ? parseInt(emailIndexParam, 10)
            : 0;
  
          setActiveVersion(initialVersion);
          setActiveEmailIndex(initialEmailIndex);
          updateURLAndRefsWithoutRender(initialVersion, initialEmailIndex);
  
          // Set content based on initial version and email index
          const selectedVersion = campaignData.versions.find(v => v.name === initialVersion);
          if (selectedVersion && selectedVersion.emails[initialEmailIndex]) {
            const selectedEmail = selectedVersion.emails[initialEmailIndex];
            setSubject(selectedEmail.subject || '');
            setEditorContent(selectedEmail.body || '');
            editor?.commands.setContent(selectedEmail.body || '');
          }
          setIsLoading(false);
          // Set a small delay to ensure content is ready after loading state changes
          setTimeout(() => setIsContentReady(true), 100);
        })
        .catch(error => {
          console.error('Error fetching campaign data:', error);
          setIsInitialized(true);
          setIsLoading(false);
          setIsContentReady(true);
        });
    }
  }, [id, isInitialized, location.search, editor]);

  useEffect(() => {
    const handlePopState = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const versionParam = searchParams.get('version');
      const emailIndexParam = searchParams.get('email');
  
      if (versionParam && campaign.versions.some(v => v.name === versionParam)) {
        setActiveVersion(versionParam);
      }
  
      if (emailIndexParam) {
        const index = parseInt(emailIndexParam, 10);
        if (!isNaN(index) && index >= 0) {
          setActiveEmailIndex(index);
        }
      }
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [campaign]);
  
  useEffect(() => {
    console.log('State changed:', { activeVersion, activeEmailIndex });
  }, [activeVersion, activeEmailIndex]);

  const updateURL = useCallback((version, emailIndex) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('version', version);
    searchParams.set('email', emailIndex.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }, [location, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    if (pageParam && ['content', 'recipients', 'wrapUp'].includes(pageParam)) {
      setCurrentPage(pageParam);
    }
  }, [location]);

  const fetchVersion = async (versionName) => {
    try {
      const response = await api.getCampaignVersion(id, versionName);
      const versionData = response.data;
  
      setCampaign(prev => ({
        ...prev,
        versions: prev.versions.map(v => 
          v.name === versionName ? versionData : v
        )
      }));
  
      if (versionData.emails && versionData.emails.length > 0) {
        const firstEmail = versionData.emails[0];
        setSubject(firstEmail.subject || '');
        setEditorContent(firstEmail.body || '');
        editor?.commands.setContent(firstEmail.body || '');
      }
    } catch (error) {
      console.error('Error fetching version:', error);
      toast({
        title: "Error fetching version",
        description: "Unable to load version details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleVersionChange = useCallback(async (versionName) => {
    setActiveVersion(versionName);
    setActiveEmailIndex(0);
    updateURLAndRefsWithoutRender(versionName, 0);
  
    try {
      // First, try to get the version from the local state
      let versionData = campaign.versions.find(v => v.name === versionName);
  
      if (!versionData) {
        // If not in local state, try to get from Redis cache
        const cachedCampaign = await api.getCachedCampaign(id);
        if (cachedCampaign) {
          versionData = cachedCampaign.versions.find(v => v.name === versionName);
        }
      }
  
      if (!versionData) {
        // If not in local state or Redis cache, fetch from database
        const response = await api.getCampaignVersion(id, versionName);
        versionData = response.data;
      }
  
      if (versionData.emails && versionData.emails.length > 0) {
        const firstEmail = versionData.emails[0];
        setSubject(firstEmail.subject || '');
        setEditorContent(firstEmail.body || '');
        editor?.commands.setContent(firstEmail.body || '');
      }
    } catch (error) {
      console.error('Error fetching version:', error);
      toast({
        title: "Error fetching version",
        description: "Unable to load version details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [campaign, id, updateURL, editor]);

  const handleEmailSelect = useCallback(async (index) => {
    setActiveEmailIndex(index);
    updateURLAndRefsWithoutRender(activeVersionRef.current, index);
    
    try {
      // First, try to get the email from the local state
      let emailData = campaign.versions.find(v => v.name === activeVersion)?.emails[index];
  
      if (!emailData) {
        // If not in local state, try to get from Redis cache
        const cachedCampaign = await api.getCachedCampaign(id);
        if (cachedCampaign) {
          emailData = cachedCampaign.versions.find(v => v.name === activeVersion)?.emails[index];
        }
      }
  
      if (!emailData) {
        // If not in local state or Redis cache, fetch from database
        const response = await api.getCampaignVersion(id, activeVersion);
        emailData = response.data.emails[index];
      }
  
      if (emailData) {
        setSubject(emailData.subject || '');
        setEditorContent(emailData.body || '');
        editor?.commands.setContent(emailData.body || '');
      } else {
        console.error('Email data not found');
        toast({
          title: "Error loading email",
          description: "Unable to load email details. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error fetching email:', error);
      toast({
        title: "Error fetching email",
        description: "Unable to load email details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [activeVersion, campaign.versions, id, updateURL, editor]);
  
  const handleRename = () => {
    setCampaign(prev => {
      const updatedCampaign = { ...prev, name: newCampaignName };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
    setIsRenameModalOpen(false);
    toast({
      title: "Campaign renamed",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };




  const handleEmailSelection = useCallback(async (email) => {
    let updatedEmails;
    
    // Find the existing email object if it exists in the campaign
    const existingEmailObj = campaign.fromEmails.find(e => e.email === email);
  
    if (isInboxRotation) {
      if (existingEmailObj) {
        // If the email is already in the list, remove it
        updatedEmails = campaign.fromEmails.filter(e => e.email !== email);
      } else {
        // If it's not in the list, add it with all its existing properties
        updatedEmails = [...campaign.fromEmails, existingEmailObj || { email }];
      }
    } else {
      // In single email mode, just use the selected email (with existing properties if available)
      updatedEmails = [existingEmailObj || { email }];
    }
  
    setCampaign(prev => {
      const updatedCampaign = {
        ...prev,
        fromEmails: updatedEmails,
      };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
  
    // Initialize signature settings for new emails if needed
    if (!campaign.signatureSettings || !campaign.signatureSettings[email]) {
      try {
        const fetchedSignature = await api.getSignature(email);
        setCampaign(prev => ({
          ...prev,
          signatureSettings: {
            ...prev.signatureSettings,
            [email]: {
              signature: fetchedSignature || '',
              showSignature: !!fetchedSignature,
              signatureType: 'email',
              customSignature: ''
            }
          }
        }));
      } catch (error) {
        console.error('Error fetching signature for new email:', error);
        // Handle error (maybe set default values or show a toast)
      }
    }
  }, [campaign.fromEmails, campaign.signatureSettings, isInboxRotation, debouncedSave]);
  
  useEffect(() => {
    if (hasEdited) {
      const timer = setTimeout(() => {
        updateCurrentEmail(localSubject, localContent);
      }, 100);
  
      return () => clearTimeout(timer);
    }
  }, [localSubject, localContent, hasEdited, updateCurrentEmail]);


  useEffect(() => {
    return () => {
      setIsInitialized(false);
    };
  }, [id]);
  

  const handleDeleteVariant = (variantName) => {
    if (variantName === 'A') {
      toast({
        title: "Cannot delete base variant",
        description: "The A variant cannot be deleted as it's the base email.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    const confirmDelete = window.confirm(`Are you sure you want to delete variant ${variantName}?`);
    if (confirmDelete) {
      setCampaign(prev => {
        const updatedVersions = prev.versions.filter(v => v.name !== variantName);
        
        // Rename subsequent variants
        const renamedVersions = updatedVersions.map((version, index) => ({
          ...version,
          name: String.fromCharCode(65 + index) // 'A' is 65 in ASCII
        }));
  
        const updatedCampaign = {
          ...prev,
          versions: renamedVersions
        };
        
        // Use debouncedSave instead of direct API call
        debouncedSave(updatedCampaign);
  
        // Update activeVersion if necessary
        if (variantName === activeVersion) {
          setActiveVersion('A');
          setActiveEmailIndex(0);
          const variantA = renamedVersions.find(v => v.name === 'A');
          if (variantA && variantA.emails.length > 0) {
            setSubject(variantA.emails[0].subject || '');
            setEditorContent(variantA.emails[0].body || '');
            editor?.commands.setContent(variantA.emails[0].body || '');
          }
        } else if (activeVersion > variantName) {
          // If active version was after the deleted one, update it
          const newActiveVersion = String.fromCharCode(activeVersion.charCodeAt(0) - 1);
          setActiveVersion(newActiveVersion);
        }
  
        toast({
          title: "Variant deleted",
          description: `Variant ${variantName} has been deleted and remaining variants have been renamed.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        return updatedCampaign;
      });
    }
  };

  const fetchCampaign = async () => {
    try {
      console.log('Fetching campaign data...', { id });
  
      // First, try to get the cached campaign
      let campaignData = await api.getCachedCampaign(id);
      
      if (!campaignData) {
        console.log('No cached data found, fetching from database');
        const response = await api.getCampaign(id);
        campaignData = response;
  
        // Cache the fetched campaign data
        await api.cacheCampaign(id, campaignData);
      } else {
        console.log('Using cached campaign data');
      }
  
      console.log('Received campaign data:', campaignData);
  
      if (!campaignData.campaignProfile || typeof campaignData.campaignProfile === 'string') {
        console.log('Campaign profile not found or is just an ID, fetching profiles...');
        const profilesResponse = await api.getCampaignProfiles();
        const profiles = profilesResponse.data;
        
        if (typeof campaignData.campaignProfile === 'string') {
          // If it's an ID, find the matching profile
          const fullProfile = profiles.find(profile => profile._id === campaignData.campaignProfile);
          if (fullProfile) {
            campaignData.campaignProfile = fullProfile;
          }
        } else {
          // If it's not set, use the default profile
          const defaultProfile = profiles.find(profile => profile.isDefault) || profiles[0];
          
          if (defaultProfile) {
            console.log('Using default profile:', defaultProfile);
            campaignData.campaignProfile = defaultProfile;
            
            // Update the campaign with the default profile
            await api.updateCampaign(id, { campaignProfile: defaultProfile._id });
          } else {
            console.error('No default profile found');
            throw new Error('No default profile found');
          }
        }
        
        // Update the cache with the new campaign data
        await api.cacheCampaign(id, campaignData);
      }
  
      // Ensure versions exist
      if (!campaignData.versions || campaignData.versions.length === 0) {
        campaignData.versions = [{ name: 'A', emails: [{ day: 1, subject: '', body: '' }] }];
      }
      
      // Format fromEmails to ensure they're in the correct structure
      const formattedFromEmails = campaignData.fromEmails.map(email => ({
        email: email.email || email,
        provider: email.provider || '',
        signature: email.signature || '',
        signatureType: email.signatureType || 'email',
        customSignature: email.customSignature || '',
        showSignature: email.showSignature !== undefined ? email.showSignature : true
      }));
      
      // Initialize signatureSettings if it doesn't exist
      if (!campaignData.signatureSettings || !Array.isArray(campaignData.signatureSettings)) {
        campaignData.signatureSettings = [];
      }
    
      formattedFromEmails.forEach(emailObj => {
        const email = emailObj.email;
        const existingSettings = campaignData.signatureSettings.find(s => s.email === email);
        if (!existingSettings) {
          campaignData.signatureSettings.push({
            email,
            signature: emailObj.signature || '',
            signatureType: emailObj.signatureType || 'email',
            customSignature: emailObj.customSignature || '',
            showSignature: emailObj.showSignature !== undefined ? emailObj.showSignature : true
          });
        }
      });
      
      const formattedCampaignData = {
        ...campaignData,
        fromEmails: formattedFromEmails
      };
      
      console.log('Formatted campaign data:', formattedCampaignData);
      
      setCampaign(formattedCampaignData);
      setNewCampaignName(formattedCampaignData.name);
      setIsInboxRotation(formattedFromEmails.length > 1);
      
      // Set active version to the first version
      setActiveVersion(formattedCampaignData.versions[0].name);
  
      // Check if versions exist and have emails
      if (formattedCampaignData.versions[0].emails && formattedCampaignData.versions[0].emails.length > 0) {
        const firstEmail = formattedCampaignData.versions[0].emails[0];
        console.log('Setting initial email content:', firstEmail);
        setSubject(firstEmail.subject || '');
        setActiveEmailIndex(0);
        setEditorContent(firstEmail.body || '');
        editor?.commands.setContent(firstEmail.body || '');
      } else {
        // If no emails exist, set default values
        console.log('No emails found, setting default values');
        setSubject('');
        setActiveEmailIndex(0);
        setEditorContent('');
        editor?.commands.setContent('');
      }
  
      return formattedCampaignData;
    } catch (error) {
      console.error('Error fetching campaign:', error);
      toast({
        title: "Error fetching campaign",
        description: "Unable to load campaign details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    
      // Set default state in case of error
      const defaultCampaign = {
        name: 'New Campaign',
        fromEmails: [],
        versions: [{ name: 'A', emails: [{ day: 1, subject: '', body: '' }] }],
        campaignProfile: defaultProfile,
        signatureSettings: {
          signatureType: 'email',
          signature: '',
          customSignature: '',
          showSignature: true
        }
      };
      setCampaign(defaultCampaign);
      setNewCampaignName('New Campaign');
      setIsInboxRotation(false);
      setActiveVersion('A');
      setSubject('');
      setActiveEmailIndex(0);
      setEditorContent('');
      editor?.commands.setContent('');
  
      console.log('Default state set for new campaign:', defaultCampaign);
  
      return defaultCampaign;  // Return the default campaign data in case of error
    }
  };


  const fetchAvailableEmails = async () => {
    try {
      const response = await api.get('/api/email-campaigns/connected-emails');
      setAvailableEmails(response.data);
    } catch (error) {
      console.error('Error fetching available emails:', error);
    }
  };

  const handleInboxRotationChange = async (isChecked) => {
    setIsInboxRotation(isChecked);
    
    let updatedEmails;
    if (isChecked) {
      updatedEmails = campaign.fromEmails;
    } else {
      updatedEmails = campaign.fromEmails.length > 0 ? [campaign.fromEmails[0]] : [];
    }
  
    setCampaign(prev => {
      const updatedCampaign = {
        ...prev,
        fromEmails: updatedEmails,
      };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
  };

  const addVersion = async () => {
    if (campaign.versions && campaign.versions.length < 5) {
      const newVersionName = String.fromCharCode(65 + campaign.versions.length);
      const newVersion = { name: newVersionName, emails: [{ day: 1, subject: '', body: '' }] };
      
      // Optimistically update the local state
      const updatedCampaign = {
        ...campaign,
        versions: [...campaign.versions, newVersion]
      };
      
      setCampaign(updatedCampaign);
      setActiveVersion(newVersionName);
      setActiveEmailIndex(0);
      updateURLAndRefsWithoutRender(newVersionName, 0);
      setSubject('');
      setEditorContent('');
      editor?.commands.setContent('');
  
      try {
        // Update cache (preserved from previous implementation)
        await api.cacheCampaign(id, updatedCampaign);
        
        // Save to backend (uses debouncedSave as before)
        debouncedSave(updatedCampaign);
  
        toast({
          title: "New version added",
          description: `Version ${newVersionName} has been added successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error adding new version:', error);
        
        // Revert the optimistic update if there's an error
        setCampaign(campaign);
        setActiveVersion(campaign.versions[0].name);
        setActiveEmailIndex(0);
        updateURL(campaign.versions[0].name, 0);
  
        toast({
          title: "Error adding new version",
          description: "Failed to add new version. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      // Maximum versions reached logic (preserved from previous implementation)
      toast({
        title: "Maximum versions reached",
        description: "You can't add more than 5 versions.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
 

  const addFollowUp = () => {
    setIsFollowUpModalOpen(true);
  };
  
  const openFollowUpModal = () => {
    const currentVersion = campaign.versions.find(v => v.name === activeVersion);
    const currentEmailCount = currentVersion.emails.length;
    
    if (currentEmailCount >= 6) { // 1 initial email + 5 follow-ups
      toast({
        title: "Maximum follow-ups reached",
        description: "You can't add more than 5 follow-ups to your campaign.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    setIsFollowUpModalOpen(true);
  };
  
  // Modify the existing handleAddFollowUp function
  const handleAddFollowUp = (newFollowUps) => {
    const currentVersion = campaign.versions.find(v => v.name === activeVersion);
    const currentEmailCount = currentVersion.emails.length;
    
    if (currentEmailCount + newFollowUps.length > 6) {
      toast({
        title: "Maximum follow-ups reached",
        description: "You can't add more than 5 follow-ups to your campaign.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    const lastEmail = currentVersion.emails[currentEmailCount - 1];
    
    const updatedEmails = [...currentVersion.emails];
    let currentDay = lastEmail.day;
  
    newFollowUps.forEach(({ days }) => {
      currentDay += days;
      updatedEmails.push({
        day: currentDay,
        subject: '',
        body: '',
        sendAfterDays: days
      });
    });
  
    const updatedCampaign = {
      ...campaign,
      versions: campaign.versions.map(v =>
        v.name === activeVersion
          ? {
              ...v,
              emails: updatedEmails
            }
          : v
      )
    };
  
    // Update local state
    setCampaign(updatedCampaign);
    setActiveEmailIndex(updatedEmails.length - 1);
    updateURLAndRefsWithoutRender(activeVersionRef.current, updatedEmails.length - 1);
    setSubject('');
    setEditorContent('');
    editor?.commands.setContent('');
  
    // Update cache and backend
    (async () => {
      try {
        await api.cacheCampaign(id, updatedCampaign);
        debouncedSave(updatedCampaign);
      } catch (error) {
        console.error('Error adding follow-ups:', error);
        toast({
          title: "Error adding follow-ups",
          description: "There was an error adding the follow-ups. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })();
  
    // Close the modal after successfully adding follow-ups
    setIsFollowUpModalOpen(false);
    setEditingFollowUpIndex(null);
  };


  const renderVersions = useCallback(() => {
    if (!campaign || !campaign.versions || campaign.versions.length === 0) return null;
    
    return (
      <HStack spacing={1} mb={4} mt={3.5}>
        {campaign.versions.map((version) => (
          <Button
            key={version.name}
            size="md"
            variant="outline"
            width="100%"
            height="42px"
            onClick={() => handleVersionChange(version.name)}  // This line changes
            {...(activeVersion === version.name && {
              borderColor: 'blue.500',
              color: 'blue.500',
            })}
          >
            {version.name}
          </Button>
        ))}
      </HStack>
    );
  }, [campaign, activeVersion, handleVersionChange]);  // Add handleVersionChange to dependencies

  const updateEmailContent = useCallback(() => {
    let content = editorContent;
  
    if (campaign.signatureSettings?.showSignature && campaign.signatureSettings?.signature) {
      content += '\n--\n' + campaign.signatureSettings.signature;
    }
  
    setCampaign(prev => {
      const updatedCampaign = {
        ...prev,
        versions: prev.versions.map(v => 
          v.name === activeVersion
            ? {
                ...v,
                emails: v.emails.map((email, index) => 
                  index === activeEmailIndex
                    ? { 
                        ...email, 
                        subject, 
                        body: content
                      }
                    : email
                )
              }
            : v
        )
      };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
  }, [activeVersion, activeEmailIndex, editorContent, campaign.signatureSettings, subject, debouncedSave]);

  const fetchInitialData = useCallback(async () => {
    try {
      const [recipientsResponse, listsResponse, importsResponse] = await Promise.all([
        api.get(`/api/email-campaigns/${id}/recipients`, { params: { page: 1, pageSize: 25 } }),
        api.get('/api/lists'),
        api.getImports(id)
      ]);

      setRecipients(recipientsResponse.data.recipients);
      setInitialRecipients(recipientsResponse.data.recipients);
      setTotalRecipients(recipientsResponse.data.total);
      setPreloadedRecipients(recipientsResponse.data.recipients);
      setLists(listsResponse.data.lists);
      setImports(importsResponse.data);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast({
        title: "Error fetching data",
        description: "An unexpected error occurred while loading initial data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [id]);

  const updateRecipientsAfterImport = useCallback(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const renderEmails = () => {
    if (!campaign || !campaign.versions) return null;
    
    const currentVersion = campaign.versions.find(v => v.name === activeVersion);
    if (!currentVersion || !currentVersion.emails) return null;
  
    const stripSignature = (body) => {
      const delimiters = ['\n--\n', '<p>--</p>', '---', '– ', '-- '];
      
      for (const delimiter of delimiters) {
        const parts = body.split(delimiter);
        if (parts.length > 1) {
          return parts[0].trim();
        }
      }
      
      return body;
    };
    
    return (
      <VStack align="stretch" spacing={4}>
        {currentVersion.emails.map((email, index) => (
          <Box 
            key={index} 
            borderWidth={1} 
            borderRadius="md" 
            p={4} 
            position="relative"
            cursor="pointer"
            onClick={() => handleEmailSelect(index)}
            bg={index === activeEmailIndex ? "blue.50" : "white"}
            borderColor={index === activeEmailIndex ? "blue.500" : "gray.200"}
            _hover={{
              bg: index === activeEmailIndex ? "blue.100" : "gray.50",
              borderColor: "blue.300",
            }}
            transition="all 0.2s"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text>{activeVersion}</Text>
              {index > 0 && <Text>Follow up {index}</Text>}
              <Text fontWeight="bold">
                {index === 0 ? `Day ${email.day}` : `After ${email.sendAfterDays} days`}
              </Text>
            </Flex>
            <Text fontWeight="bold" mb={2} noOfLines={1}>
              {replaceAttributesWithDefaults(email.subject)}
            </Text>
            <Text fontSize="sm" noOfLines={2}>
              {stripHtml(replaceAttributesWithDefaults(stripSignature(email.body || '')))}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon />}
                variant="ghost"
                size="xs"
                position="absolute"
                bottom={1}
                right={1}
                aria-label="Email options"
                minWidth="20px"
                height="20px"
                padding={0}
              />
              <MenuList>
                {index === 0 ? (
                  activeVersion !== 'A' && (
                    <MenuItem onClick={() => handleDeleteVariant(currentVersion.name)}>
                      Delete variant
                    </MenuItem>
                  )
                ) : (
                  <>
                    <MenuItem onClick={() => handleChangeFollowUpDay(index)}>
                      Change follow-up day
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteFollowUp(index)}>
                      Delete follow-up
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Box>
        ))}
      </VStack>
    );
  };

  const handleUnsubscribeOptionsSave = async (options) => {
    try {
      // Prepare the updated unsubscribe settings
      const updatedUnsubscribeSettings = {
        unsubscribeMethod: options.method,
        unsubscribeKeyword: options.keyword,
        unsubscribeCustomText: options.sentence,
        unsubscribeAnchorText: options.anchorText
      };
  
      // Immediate API call to update unsubscribe settings
      await api.updateCampaign(campaign._id, updatedUnsubscribeSettings);
  
      // Update local state
      setCampaign(prevCampaign => {
        const updatedCampaign = {
          ...prevCampaign,
          ...updatedUnsubscribeSettings
        };
  
        // Use the debouncedSave function for any other changes
        debouncedSave(updatedCampaign);
  
        return updatedCampaign;
      });
  
      // Close the unsubscribe modal
      setIsUnsubscribeModalOpen(false);
  
      // Refresh the wrap-up data
      refreshWrapUpData();
  
      toast({
        title: "Unsubscribe settings updated",
        description: "Your unsubscribe settings have been saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating unsubscribe settings:', error);
      toast({
        title: "Error updating unsubscribe settings",
        description: "Failed to update unsubscribe settings. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (campaign) {
      setUnsubscribeOptions({
        method: campaign.unsubscribeMethod || 'link',
        keyword: campaign.unsubscribeKeyword || '',
        sentence: campaign.unsubscribeCustomText || 'If you wish to unsubscribe, [UNSUBSCRIBE_LINK].',
        anchorText: campaign.unsubscribeAnchorText || 'click here'
      });
    }
  }, [campaign]);


  const generateEmailContent = (recipient, content) => {
    const unsubscribeLink = `${process.env.REACT_APP_TRACKING_SERVER_URL}/unsubscribe/${recipient._id}`;
    
    let unsubscribeText = unsubscribeOptions.sentence || 'If you wish to unsubscribe, [UNSUBSCRIBE_LINK].';
    const anchorText = unsubscribeOptions.anchorText || 'click here';
    
    const unsubscribeHtml = unsubscribeText.replace('[UNSUBSCRIBE_LINK]', `<a href="${unsubscribeLink}">${anchorText}</a>`);
  
    const updatedContent = `
      ${content}
      
      <p>${unsubscribeHtml}</p>
    `;
    
    return updatedContent;
  };

const handleChangeFollowUpDay = (index) => {
  setEditingFollowUpIndex(index);
  setFollowUpDays(campaign.versions.find(v => v.name === activeVersion).emails[index].sendAfterDays);
  setIsFollowUpModalOpen(true);
};

const handleDeleteFollowUp = (index) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this follow-up?");
  if (confirmDelete) {
    setCampaign(prev => {
      const updatedCampaign = {
        ...prev,
        versions: prev.versions.map(v =>
          v.name === activeVersion
            ? { ...v, emails: v.emails.filter((_, i) => i !== index) }
            : v
        )
      };
      debouncedSave(updatedCampaign);
      return updatedCampaign;
    });
    if (index === activeEmailIndex) {
      setActiveEmailIndex(Math.max(0, index - 1));
    } else if (index < activeEmailIndex) {
      setActiveEmailIndex(activeEmailIndex - 1);
    }
  }
};

const handleUpdateFollowUp = () => {
  setCampaign(prev => ({
    ...prev,
    versions: prev.versions.map(v =>
      v.name === activeVersion
        ? {
            ...v,
            emails: v.emails.map((email, index) =>
              index === editingFollowUpIndex
                ? { ...email, sendAfterDays: followUpDays }
                : email
            )
          }
        : v
    )
  }));
  setIsFollowUpModalOpen(false);
  setEditingFollowUpIndex(null);
};



  const handleEmailSettings = (index) => {
    // Implement email settings logic here
    console.log(`Open settings for email ${index}`);
  };

  if (isLoading || !isContentReady) {
    return <SkeletonLoader2 />;
  }
  
  return (
    <Container maxW="container.xl" py={8}>
      {/* Adjustable top section */}
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
  <Flex ml="38px" maxWidth="600px" alignItems="center" overflow="hidden">
    <Text
      as="h2"
      fontSize="2xl"
      fontWeight="bold"
      noOfLines={1}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      flex="1"
    >
      {campaign?.name}
    </Text>
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<ChevronDownIcon />}
        variant="ghost"
        size="sm"
        aria-label="Campaign options"
        ml={2}
        flexShrink={0}
      />
      <MenuList>
        <MenuItem onClick={() => setIsRenameModalOpen(true)}>
          Rename campaign
        </MenuItem>
        {/* You can add more menu items here later */}
      </MenuList>
    </Menu>
  </Flex>
        {/* Adjustable navigation buttons */}
        <Box ml="auto" pr={4}> {/* Adjust 'pr' value to move buttons more to the left */}
     
        <HStack spacing={4} mr="20px">
            <Button
              variant={currentPage === 'content' ? "primary" : "outline"}
              onClick={() => handlePageChange('content')}
            >
              Content
            </Button>
            <Button
              variant={currentPage === 'recipients' ? "primary" : "outline"}
              onClick={() => handlePageChange('recipients')}
            >
              Recipients
            </Button>
            <Button
              variant={currentPage === 'wrapUp' ? "primary" : "outline"}
              onClick={() => handlePageChange('wrapUp')}
            >
              Wrap Up
            </Button>
      </HStack>
      </Box>
      </Flex>
    {/* Adjustable From box with horizontal positioning */}
    {currentPage === 'content' && (
    <Box 
  mb={4} 
  ml="auto"
  mr={0}
  width="855px"
  maxWidth="855px"
  position="relative"
  left="-35px"
>
  
<Flex 
    alignItems="center" 
    borderWidth={1} 
    borderColor="gray.200" 
    p={1} // Reduced padding from p={2} to p={1}
    borderRadius="md"  
    bg="gray.100"
    minHeight="32px" // Added minHeight to control the overall height
  >  <Text fontWeight="bold" mr={2}>From:</Text>
  <Text>
    {campaign?.fromEmails
      .map(emailObj => emailObj.email || emailObj)
      .join(', ')}
  </Text>
  <IconButton
    icon={<ChevronDownIcon />}
    aria-label="Change email"
    size="sm"
    ml="auto"
    variant="ghost"
    onClick={() => setIsEmailModalOpen(true)}
  />
</Flex>
</Box>
  )}
 {/* Content based on current page */}
 {currentPage === 'content' ? (
    <Flex justifyContent="center">
    {campaign && Array.isArray(campaign.versions) && (
      <VStack align="stretch" width="300px" mr={6} mt="-58px">
        {/* Wrap the buttons in a new Box */}
        <Box marginBottom="-7px">
          <Flex width="100%">
            <Button
              variant="outline"
              height="43px"
              width="50%"
              borderColor="blue.500"
              color="blue.500"
              mr={1}
              onClick={addVersion}
              className="add-variant-button"
            >
              Add Variant
            </Button>
            <Button
              variant="outline"
              height="43px"
              width="50%"
              borderColor="blue.500"
              color="blue.500"
              ml={1}
              onClick={openFollowUpModal}
              className="add-follow-up-button"
            >
              Add Follow Up
            </Button>
          </Flex>
        </Box>
        {renderVersions()}
        <Box mt={-3.5}>
          {renderEmails()}
        </Box>
      </VStack>
    )}
    <VStack align="stretch" width="850px">
    <TiptapSubject
  value={localSubject}
  onChange={(newSubject) => {
    setHasEdited(true);
    setIsEditing(true);
    setLocalSubject(newSubject);
    // Set isEditing back to false after a short delay
    setTimeout(() => {
      setIsEditing(false);
    }, 500);
  }}
      placeholder={activeEmailIndex === 0 ? "Subject Line" : "Leave empty to reply to the previous email"}
      onFocus={() => {
        console.log('Setting current focused editor to subject');
        setCurrentFocusedEditor('subject');
      }}
      className="subject-input"
    />
<Box 
  mb={4} 
  borderWidth="1px"
  mt="2px"
  borderRadius="md" 
  borderColor="gray.200"
  overflow="hidden"
  position="relative"
  transition="all 0.2s"
  _focusWithin={{
    borderColor: "blue.500",
    boxShadow: "none",
  }}
>
<TiptapEditor
  content={localContent}
  onEditorUpdate={(newContent) => {
    setLocalContent(newContent);
    setHasEdited(true);

  }}
  currentFocusedEditor={currentFocusedEditor}
  setCurrentFocusedEditor={setCurrentFocusedEditor}
  onInsertAttribute={handleInsertAttribute}
  placeholder={getPlaceholderText()}
  signature={getCurrentEmailSignatureSettings().signature || getCurrentEmailSignatureSettings().customSignature || ''}
  showSignature={!!getCurrentEmailSignatureSettings().showSignature && getCurrentEmailSignatureSettings().signatureType !== 'none'}
  setIsEditing={setIsEditing}
/>
</Box>


            
          
<Flex justifyContent="space-between" alignItems="center" width="100%">
  <Flex alignItems="center">
    <Button 
      leftIcon={<EditIcon />}
      onClick={() => setIsUnsubscribeModalOpen(true)}
      variant="outline"
      borderColor="blue.500"
      color="blue.500"
      size="sm"
      height="35px"
      minWidth="120px"
      mr={2}
    >
      Unsubscribe
    </Button>
    <Button 
      leftIcon={<EditIcon />}
      onClick={() => setIsSignatureModalOpen(true)}
      variant="outline"
      borderColor="blue.500"
      color="blue.500"
      size="sm"
      height="35px"
      minWidth="120px"
      mr={2}
    >
      Signature
    </Button>
    <Tooltip 
      label="This button only visually removes the signature. To adjust signature settings, please use the 'Signature' button on the left."
      placement="top"
      hasArrow
    >
    <Button
  onClick={toggleSignature}
  colorScheme={campaign.signatureSettings?.showSignature ? "blue" : "gray"}
  variant="outline"
  size="sm"
  height="35px"
  minWidth="120px"
  leftIcon={campaign.signatureSettings?.showSignature ? <CheckIcon /> : <CloseIcon />}
  isDisabled={campaign.signatureSettings?.signatureType === 'none'}
>
  {campaign.signatureSettings?.showSignature ? "Signature On" : "Signature Off"}
</Button>
    </Tooltip>
  </Flex>
 
 

  <EmailPreview 
  campaign={campaign} 
  showSignature={campaign.signatureSettings?.showSignature} 
  signature={campaign.signatureSettings?.signature || campaign.signatureSettings?.customSignature}
  buttonProps={{
    size: "md",
    height: "40px",
    minWidth: "40px"
  }}
/>
</Flex>
        </VStack>
      </Flex>
  ) : currentPage === 'recipients' ? (
    <RecipientSelection 
    campaignId={id} 
    campaignName={campaign.name}
    initialRecipients={initialRecipients}
    totalRecipients={totalRecipients}
    onUpdateRecipients={(count) => setTotalRecipients(count)}
    initialLists={lists}
    initialImports={imports}
    preloadedRecipients={recipients}
    onImportComplete={updateRecipientsAfterImport} 
    refreshWrapUpData={refreshWrapUpData}
  />
  ) : currentPage === 'wrapUp' ? (
    <WrapUp
    campaign={wrapUpData?.campaign || campaign}
    setCampaign={setCampaign} 
    onSettingsClick={openSettingsModal}
    onSignatureClick={() => setIsSignatureModalOpen(true)}
    onUnsubscribeClick={() => setIsUnsubscribeModalOpen(true)}
    onStartDateChange={handleStartDateChange}
    startDate={campaignStartDate}
    showSignature={wrapUpData?.campaign?.signatureSettings?.showSignature || campaign.signatureSettings?.showSignature}
    signature={wrapUpData?.campaign?.signatureSettings?.signature || wrapUpData?.campaign?.signatureSettings?.customSignature || campaign.signatureSettings?.signature || campaign.signatureSettings?.customSignature}
    isSaving={isSaving}
    startCampaign={startCampaign}
    wrapUpData={wrapUpData}
    refreshWrapUpData={refreshWrapUpData}
    handleBack={handleBack}
    handleContinue={handleContinue}
    currentPage={currentPage}
    />
  ) : null}
    {currentPage !== 'wrapUp' && (
  <Flex justifyContent="space-between" mt={6}>
    <Button onClick={handleBack} isDisabled={currentPage === 'content'}>
      Back
    </Button>
    <Button 
      variant="primary"
      onClick={handleContinue}
    >
      Continue
    </Button>
  </Flex>
)}
      {/* Email selection modal */}
      <Modal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Select Sending Emails</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <FormControl mb={4}>
        <FormLabel>Inbox rotation</FormLabel>
        <Switch 
          isChecked={isInboxRotation}
          onChange={(e) => handleInboxRotationChange(e.target.checked)}
        />
      </FormControl>
      {isInboxRotation ? (
        <Stack>
          {availableEmails.map(email => (
            <Checkbox 
              key={email}
              isChecked={campaign?.fromEmails.some(e => e.email === email || e === email)}
              onChange={() => handleEmailSelection(email)}
            >
              {email}
            </Checkbox>
          ))}
        </Stack>
      ) : (
        <Select
          value={campaign?.fromEmails[0]?.email || campaign?.fromEmails[0] || ''}
          onChange={(e) => handleEmailSelection(e.target.value)}
        >
          <option value="">Select an email</option>
          {availableEmails.map(email => (
            <option key={email} value={email}>{email}</option>
          ))}
        </Select>
      )}
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={() => setIsEmailModalOpen(false)}>
        Save
      </Button>
      <Button variant="ghost" onClick={() => setIsEmailModalOpen(false)}>Cancel</Button>
    </ModalFooter>
  </ModalContent>
</Modal>
  
      {/* Rename modal */}
      <Modal isOpen={isRenameModalOpen} onClose={() => setIsRenameModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>New campaign name</FormLabel>
              <Input 
                value={newCampaignName} 
                onChange={(e) => setNewCampaignName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleRename}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setIsRenameModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
{/* Follow-up Modal */}
<Modal isOpen={isFollowUpModalOpen} onClose={() => {
  setIsFollowUpModalOpen(false);
  setEditingFollowUpIndex(null);
}}>
  <ModalOverlay />
  {isFollowUpModalOpen && (
  <FollowUpModal
    isOpen={isFollowUpModalOpen}
    onClose={() => setIsFollowUpModalOpen(false)}
    onAddFollowUps={handleAddFollowUp}
    currentEmailCount={campaign.versions.find(v => v.name === activeVersion).emails.length}
  />
)}
</Modal>
<UnsubscribeModal 
  isOpen={isUnsubscribeModalOpen}
  onClose={() => setIsUnsubscribeModalOpen(false)}
  onSave={handleUnsubscribeOptionsSave}
  initialValues={{
    method: campaign.unsubscribeMethod || 'link',
    keyword: campaign.unsubscribeKeyword || '',
    sentence: campaign.unsubscribeCustomText || 'If you wish to unsubscribe, [UNSUBSCRIBE_LINK].',
    anchorText: campaign.unsubscribeAnchorText || 'click here'
  }}
/>
      
        <SignatureManager 
      isOpen={isSignatureModalOpen}
      onClose={() => setIsSignatureModalOpen(false)}
      onSave={handleSignatureSave}
      campaign={campaign}
      activeVersion={activeVersion}
      activeEmailIndex={activeEmailIndex}
    />
<Modal isOpen={isSettingsModalOpen} onClose={closeSettingsModal} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Campaign Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CampaignSettings onSave={handleSettingsSave} />
        </ModalBody>
      </ModalContent>
    </Modal>
    </Container>
  );
};


export default CreateCampaign;