import React, { useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

const TiptapSubject = React.forwardRef(({ value, onChange, placeholder, onFocus, className }, ref) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getText());
    },
    onFocus: () => {
      console.log('TiptapSubject focused');
      onFocus?.();
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
      },
    },
  });

  useEffect(() => {
    if (editor && value !== editor.getText()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <Box 
      position="relative" 
      ref={ref} 
      onFocus={() => {
        console.log('TiptapSubject box focused');
        editor?.commands.focus();
      }}
      sx={{
        '& .ProseMirror': {
          outline: 'none',
          padding: '8px',
          minHeight: '40px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'gray.200',
          borderRadius: 'md',
          transition: 'all 0.2s',
        },
        '& .ProseMirror:focus': {
          borderColor: 'blue.500',
          boxShadow: 'none',
        }
      }}
    >
      <EditorContent editor={editor} className={className} />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        pointerEvents="none"
        color="gray.500"
        padding="8px"
      >
        {!value && placeholder}
      </Box>
    </Box>
  );
});

export default TiptapSubject;