import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Badge,
  Button,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  AvatarBadge,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  VStack,
  Text,
  useToast,
  useDisclosure,
  Spinner,
  Alert,
  AlertIcon,
  useColorModeValue
} from '@chakra-ui/react';
import { SettingsIcon, AddIcon, RepeatIcon } from '@chakra-ui/icons';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import api from '../api';
import MailboxForm from './MailboxForm';
import CustomMailboxButtons from './CustomMailboxButtons'; // Adjust path if necessary
import CampaignSettings from './CampaignSettings';


const Settings = () => {
  const [mailboxes, setMailboxes] = useState([]);
  const [editingMailbox, setEditingMailbox] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const toastShownRef = useRef(false);
  const initialRenderRef = useRef(true);
  const [activeTab, setActiveTab] = useState('mailboxes');
  const activeColor = useColorModeValue('blue.500', 'blue.200');
  const [mailboxAddedSuccess, setMailboxAddedSuccess] = useState(null);

  useEffect(() => {
    fetchMailboxes();
  }, []);

  useEffect(() => {
    if (mailboxAddedSuccess) {
      toast({
        title: "Success",
        description: mailboxAddedSuccess,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setMailboxAddedSuccess(null);
    }
  }, [mailboxAddedSuccess, toast]);
  
  const fetchMailboxes = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get('/api/mailboxes');
      setMailboxes(response.data);
    } catch (error) {
      console.error('Error fetching mailboxes:', error);
      setError('Failed to fetch mailboxes. Please try again.');
      toast({
        title: 'Error fetching mailboxes',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderCampaignSettingsContent = () => (
    <CampaignSettings />
  );

  const handleAddMailbox = async (newMailbox) => {
    try {
      let response;
      if (newMailbox.provider === 'smtp-imap') {
        response = await api.post('/api/smtp-imap/connect/smtp-imap', newMailbox);
      } else {
        response = await api.post('/api/mailboxes', newMailbox);
      }
      setMailboxes(prevMailboxes => [...prevMailboxes, response.data]);
      setMailboxAddedSuccess('Mailbox added');
      onClose(); // Close the form after successful addition
      fetchMailboxes(); // Fetch updated list of mailboxes
    } catch (error) {
      console.error('Error adding mailbox:', error);
      toast({
        title: 'Error adding mailbox',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateMailbox = async (updatedMailbox) => {
    try {
      console.log('Attempting to update/add mailbox:', updatedMailbox);
      let response;
      if (!updatedMailbox._id) {
        // This is a new mailbox
        if (updatedMailbox.provider === 'smtp-imap') {
          response = await api.connectSmtpImap(updatedMailbox);
        } else {
          response = await api.post('/api/mailboxes', updatedMailbox);
        }
        console.log('Server response for new mailbox:', response.data);
        setMailboxes(prevMailboxes => [...prevMailboxes, response.data]);
      } else {
        // This is an existing mailbox being updated
        response = await api.put(`/api/mailboxes/${updatedMailbox._id}`, updatedMailbox);
        console.log('Server response for updated mailbox:', response.data);
        setMailboxes(prevMailboxes => 
          prevMailboxes.map(mailbox => mailbox._id === updatedMailbox._id ? response.data : mailbox)
        );
      }
      setMailboxAddedSuccess(updatedMailbox._id ? 'Mailbox updated' : 'Mailbox added');
      onClose(); // Close the form after successful update/addition
      fetchMailboxes(); // Fetch updated list of mailboxes
    } catch (error) {
      console.error('Error updating/adding mailbox:', error);
      console.error('Error response:', error.response?.data);
      toast({
        title: 'Error updating/adding mailbox',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMailbox = async (id) => {
    try {
      await api.delete(`/api/mailboxes/${id}`);
      setMailboxes(mailboxes.filter(mailbox => mailbox._id !== id));
      toast({
        title: 'Mailbox deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting mailbox:', error);
      const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
      toast({
        title: 'Error deleting mailbox',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleReconnect = async (mailboxId) => {
    try {
      const response = await api.getReauthUrl(mailboxId);
      
      if (response && response.url) {
        // Store the current URL so we can redirect back after re-auth
        sessionStorage.setItem('returnToUrl', window.location.href);
        
        // Redirect to the re-auth URL
        window.location.href = response.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error getting re-auth URL:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message || "Failed to initiate re-authentication.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    
    if (code && state) {
      const [userId, mailboxId] = state.split(':');
      handleReauthReturn(mailboxId, code);
    }
  }, []);
  
  const handleReauthReturn = async (mailboxId, code) => {
    try {
      await api.completeReauth(mailboxId, code);
      toast({
        title: "Success",
        description: "Mailbox re-authenticated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMailboxes(); // Refresh the mailbox list
    } catch (error) {
      console.error('Error completing re-auth:', error);
      toast({
        title: "Error",
        description: "Failed to complete re-authentication.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditMailbox = (mailbox) => {
    setEditingMailbox(mailbox);
    onOpen();
  };

  const handleGoogleAuth = async () => {
    try {
      const response = await api.get(`/api/auth/google/url`);
      
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error getting Google Auth URL:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to initiate Google authentication.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


useLayoutEffect(() => {
  if (initialRenderRef.current) {
    initialRenderRef.current = false;
    return;
  }

  const params = new URLSearchParams(location.search);
  const mailboxAdded = params.get('mailboxAdded');
  const mailboxUpdated = params.get('mailboxUpdated');
  const error = params.get('error');
  const code = params.get('code');
  const state = params.get('state');

  const handleMailboxOperation = async () => {
    if (toastShownRef.current) return;

    try {
      if (mailboxAdded === 'true' || mailboxUpdated === 'true' || code) {
        await fetchMailboxes();  // Refresh the mailbox list immediately
        
        if (mailboxAdded === 'true') {
          setMailboxAddedSuccess("Mailbox added successfully.");
        } else if (mailboxUpdated === 'true') {
          setMailboxAddedSuccess("Mailbox updated successfully.");
        } else if (code) {
          if (state === 'microsoft365') {
            await api.addMicrosoft365Mailbox(code);
            setMailboxAddedSuccess("Microsoft 365 account added successfully.");
          } else if (state === 'google') {
            setMailboxAddedSuccess("Google account added successfully.");
          }
        }
      } else if (error) {
        throw new Error(decodeURIComponent(error));
      }
    } catch (error) {
      console.error('Error in mailbox operation:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to perform mailbox operation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      toastShownRef.current = true;
      // Remove the query parameters from the URL
      navigate('/settings', { replace: true });
    }
  };

  if (mailboxAdded === 'true' || mailboxUpdated === 'true' || code || error) {
    handleMailboxOperation();
  }

  return () => {
    toastShownRef.current = false;
  };
}, [location, navigate, toast, fetchMailboxes]);

  const handleMicrosoftAuth = async () => {
    try {
      const response = await api.getMicrosoftMailboxUrl();
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error getting Microsoft Auth URL:', error);
      toast({
        title: "Error",
        description: "Failed to initiate Microsoft mailbox authentication.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const renderMailboxContent = () => (
    <>
      <HStack justify="space-between" mb={4}>
        <Heading as="h2" size="md">Connected Mailboxes</Heading>
        <CustomMailboxButtons 
          onGmailClick={handleGoogleAuth}
          onMicrosoftClick={handleMicrosoftAuth}
          onSMTPClick={() => {
            setEditingMailbox({
              email: '',
              provider: 'smtp-imap',
              senderName: '',
              dailyLimit: 500,
              hourlyLimit: 50,
              isDefault: false,
              smtpHost: '',
              smtpPort: '',
              imapHost: '',
              imapPort: '',
              username: '',
              password: '',
            });
            onOpen();
          }}
        />
      </HStack>
      {mailboxes.length === 0 ? (
        <Alert status="info">
          <AlertIcon />
          No mailboxes connected yet. Add a mailbox to get started.
        </Alert>
      ) : (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Mailbox</Th>
                <Th>Daily Limit</Th>
                <Th>Hourly Limit</Th>
                <Th>Scheduled</Th>
                <Th>Sent Today</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {mailboxes.map((mailbox) => (
                <Tr key={mailbox._id}>
                  <Td>
                    <HStack>
                      <Avatar name={mailbox.senderName} src={mailbox.profilePicture} size="sm">
                        <AvatarBadge boxSize="1em" bg={mailbox.isConnected ? "green.500" : "red.500"} />
                      </Avatar>
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="medium">{mailbox.email}</Text>
                        <Text fontSize="xs" color="gray.500">{mailbox.provider}</Text>
                      </VStack>
                    </HStack>
                  </Td>
                  <Td>{mailbox.dailyLimit}</Td>
                  <Td>{mailbox.hourlyLimit}</Td>
                  <Td>{mailbox.scheduledEmails || 0}</Td>
                  <Td>
                    <Badge colorScheme={mailbox.emailsSentToday < mailbox.dailyLimit * 0.8 ? "green" : "red"}>
                      {mailbox.emailsSentToday || 0}
                    </Badge>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<SettingsIcon />}
                          variant="ghost"
                        />
                        <MenuList>
                          <MenuItem onClick={() => handleUpdateMailbox({ ...mailbox, isDefault: true })}>
                            Set as Default
                          </MenuItem>
                          <MenuItem onClick={() => handleEditMailbox(mailbox)}>Edit</MenuItem>
                          {mailbox.isRemovable && (
                            <MenuItem onClick={() => handleDeleteMailbox(mailbox._id)}>Remove</MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                      <IconButton
                        aria-label="Reconnect"
                        icon={<RepeatIcon />}
                        variant="ghost"
                        onClick={() => handleReconnect(mailbox._id)}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 'account':
        return <Box p={4}>Account settings placeholder</Box>;
      case 'mailboxes':
        return renderMailboxContent();
      case 'campaignsettings':  // Changed from 'campaign' to 'campaignsettings'
        return <CampaignSettings />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl">Settings</Heading>
          <Box textAlign="center">
            <Spinner size="xl" />
            <Text mt={4}>Loading...</Text>
          </Box>
        </VStack>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl">Settings</Heading>
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
          <Button onClick={fetchMailboxes}>Retry</Button>
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="xl" mb={4}>Settings</Heading>
       <HStack spacing={8} mb={4}>
  {['Account', 'Mailboxes', 'Campaign Settings'].map((tab) => (
    <Box
      key={tab}
      borderBottom={activeTab === tab.toLowerCase().replace(' ', '') ? '2px solid' : 'none'}
      borderColor={activeColor}
      pb={2}
      onClick={() => setActiveTab(tab.toLowerCase().replace(' ', ''))}
      cursor="pointer"
      color={activeTab === tab.toLowerCase().replace(' ', '') ? activeColor : 'inherit'}
    >
      {tab}
    </Box>
  ))}
</HStack>
        {renderTabContent()}
      </VStack>
      <MailboxForm
  isOpen={isOpen}
  onClose={onClose}
  onSubmit={(formData) => {
    if (editingMailbox) {
      handleUpdateMailbox({...editingMailbox, ...formData});
    } else {
      handleAddMailbox(formData);
    }
  }}
  initialData={editingMailbox}
/>
    </Container>
  );
};

export default Settings;