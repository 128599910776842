import React from 'react';
import { Box, Flex, HStack, Text, Button, Tag, Progress } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const ImportBox = ({ imp, index, onDelete, progress }) => {
  const importStatus = progress ? progress.status : 'waiting';
  const importProgress = progress ? progress.progress : 0;
  const count = progress && progress.count !== undefined ? progress.count : imp.count;

  console.log('ImportBox render:', { impId: imp.id, impCount: imp.count, progress, finalCount: count }); // Add this log

  return (
    <Box key={imp.id} borderWidth={1} borderRadius="md" p={2} minWidth="200px">
      <Flex direction="column">
        <Flex justify="space-between" align="center" mb={2}>
          <Text fontWeight="bold" isTruncated>Import {index + 1}</Text>
          <Tag colorScheme={imp.method === 'csv' ? 'blue' : imp.method === 'list' ? 'green' : 'orange'}>
            {imp.method.toUpperCase()}
          </Tag>
        </Flex>
        {importStatus === 'importing' && importProgress < 100 ? (
          <Box mb={2}>
            <Progress value={importProgress} size="sm" colorScheme="blue" />
            <Text fontSize="xs" mt={1}>{importProgress}% Complete</Text>
          </Box>
        ) : (
          <Text fontSize="xs" mb={2}>
            {importStatus === 'completed' || (importStatus === 'importing' && importProgress === 100) 
              ? `${count} recipients imported` 
              : 'Waiting to start...'}
          </Text>
        )}
        <Button 
          size="sm" 
          leftIcon={<DeleteIcon />} 
          onClick={() => onDelete(imp.id)}
          isDisabled={importStatus === 'importing' && importProgress < 100}
        >
          Remove
        </Button>
      </Flex>
    </Box>
  );
};

const ImportBoxes = ({ imports, handleDeleteImport, importProgress }) => {
  console.log('ImportBoxes render:', { imports, importProgress }); // Add this log

  return (
    <Flex flex={1} overflowX="auto" mx={4}>
      <HStack spacing={2}>
        {imports.map((imp, index) => (
          <ImportBox 
            key={imp.id}
            imp={imp}
            index={index}
            onDelete={handleDeleteImport}
            progress={importProgress[imp.id]}
          />
        ))}
      </HStack>
    </Flex>
  );
};

export default ImportBoxes;