import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Box,
  Tooltip,
  Textarea,
  Flex
} from "@chakra-ui/react";
import { InfoIcon, WarningIcon } from '@chakra-ui/icons';

const UnsubscribeModal = ({ isOpen, onClose, onSave, initialValues }) => {
  const [unsubscribeMethod, setUnsubscribeMethod] = useState(initialValues.method || 'link');
  const [unsubscribeKeyword, setUnsubscribeKeyword] = useState(initialValues.keyword || '');
  const [unsubscribeSentence, setUnsubscribeSentence] = useState(
    initialValues.sentence || 'If you wish to unsubscribe, [UNSUBSCRIBE_LINK].'
  );
  const [anchorText, setAnchorText] = useState(initialValues.anchorText || 'click here');
  const [isValidSentence, setIsValidSentence] = useState(true);

  useEffect(() => {
    if (unsubscribeMethod === 'keyword') {
      setUnsubscribeSentence('Please say [KEYWORD] to be automatically unsubscribed from further emails.');
    } else {
      setUnsubscribeSentence('If you wish to unsubscribe, [UNSUBSCRIBE_LINK].');
    }
    setIsValidSentence(true);
  }, [unsubscribeMethod]);

  const handleSentenceChange = (e) => {
    const newSentence = e.target.value;
    setUnsubscribeSentence(newSentence);
    setIsValidSentence(
      (unsubscribeMethod === 'link' && newSentence.includes('[UNSUBSCRIBE_LINK]')) ||
      (unsubscribeMethod === 'keyword' && newSentence.includes('[KEYWORD]'))
    );
  };

  const handleSave = () => {
    if (isValidSentence) {
      onSave({
        method: unsubscribeMethod,
        keyword: unsubscribeKeyword,
        sentence: unsubscribeSentence,
        anchorText: anchorText
      });
      onClose();
    }
  };

  const getPreviewText = () => {
    if (unsubscribeMethod === 'keyword') {
      return unsubscribeSentence.replace('[KEYWORD]', unsubscribeKeyword);
    } else {
      return unsubscribeSentence.replace('[UNSUBSCRIBE_LINK]', anchorText);
    }
  };

  const getTooltipText = () => {
    if (unsubscribeMethod === 'link') {
      return "Feel free to change your link to incorporate your preferred opt out sentence, however you must include [UNSUBSCRIBE_LINK] so the opt out link is included. The 'Link Text' button will change the function to words.";
    } else {
      return "Write out any sentence you'd like with [KEYWORD] in it, where [KEYWORD] will be replaced with your designated unsubscribe keyword. Sentences must have [KEYWORD] in it to function properly.";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            Unsubscribe Options
            <Tooltip label="Cold emails don't require unsubscribe links by law, however you DO need to allow recipients to opt out in a clear manner." placement="top">
              <WarningIcon color="red.500" ml={2} />
            </Tooltip>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel fontWeight="bold">Unsubscribe Method</FormLabel>
              <Select 
                value={unsubscribeMethod} 
                onChange={(e) => setUnsubscribeMethod(e.target.value)}
                bg="gray.100"
                size="lg"
              >
                <option value="link">Unsubscribe Link</option>
                <option value="keyword">Keyword Unsubscribe</option>
              </Select>
            </FormControl>
            
            {unsubscribeMethod === 'keyword' && (
              <FormControl>
                <FormLabel>Unsubscribe Keyword</FormLabel>
                <Input 
                  value={unsubscribeKeyword} 
                  onChange={(e) => setUnsubscribeKeyword(e.target.value)}
                  placeholder="e.g., STOP"
                />
              </FormControl>
            )}
            
            <FormControl isInvalid={!isValidSentence}>
              <FormLabel>
                Unsubscribe Sentence
                <Tooltip 
                  label={getTooltipText()} 
                  placement="top"
                >
                  <InfoIcon ml={2} />
                </Tooltip>
              </FormLabel>
              <Textarea
                value={unsubscribeSentence} 
                onChange={handleSentenceChange}
                placeholder={unsubscribeMethod === 'link' ? 
                  "Use [UNSUBSCRIBE_LINK] where you want the link" : 
                  "Use [KEYWORD] where you want the unsubscribe keyword"}
                rows={2}
                resize="vertical"
              />
              {!isValidSentence && (
                <Text color="red.500" fontSize="sm">
                  The sentence must include {unsubscribeMethod === 'link' ? '[UNSUBSCRIBE_LINK]' : '[KEYWORD]'} for the {unsubscribeMethod} unsubscribe method.
                </Text>
              )}
            </FormControl>
            
            {unsubscribeMethod === 'link' && (
              <FormControl>
                <FormLabel>Link Text</FormLabel>
                <Input 
                  value={anchorText} 
                  onChange={(e) => setAnchorText(e.target.value)}
                  placeholder="e.g., click here"
                />
              </FormControl>
            )}
            
            <Box borderWidth={1} p={3} borderRadius="md">
              <Text fontWeight="bold">Preview:</Text>
              <Text>{getPreviewText()}</Text>
            </Box>

            <Box borderWidth={1} borderStyle="dashed" borderColor="gray.300" p={3} borderRadius="md">
              <Text fontSize="sm">
                Your unsubscribe sentence will be automatically placed underneath your signature. If no signature it's placed at the end of the email.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave} isDisabled={!isValidSentence}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnsubscribeModal;