import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../api';
import { useDropzone } from 'react-dropzone';
import {
  Box, Button, Flex, Heading, Input, Select, Table, Thead, Tbody, Tr, Th, Td,
  Tag, IconButton, Tooltip, Progress, Spinner, useToast, VStack, HStack, Text,
  InputGroup, InputLeftElement
} from '@chakra-ui/react';
import { 
  DownloadIcon, DeleteIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon,
  CheckIcon, CloseIcon, EmailIcon
} from '@chakra-ui/icons';
import BulkVerificationModal from './BulkVerificationModal';
import io from 'socket.io-client';


const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

const EmailVerifier = () => {
  const [email, setEmail] = useState('');
  const [singleVerificationResult, setSingleVerificationResult] = useState(null);
  const [error, setError] = useState(null);
  const [verificationHistory, setVerificationHistory] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [verificationJobId, setVerificationJobId] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedList, setSelectedList] = useState('');
  const [lists, setLists] = useState([]);
  const [activeJobs, setActiveJobs] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  
  const verificationHistoryRef = useRef(null);
  const bulkVerificationRef = useRef(null);
  const socketRef = useRef(null);

  const toast = useToast();

  const cleanupActiveJobs = useCallback((jobs) => {
    const currentTime = new Date().getTime();
    const updatedJobs = { ...jobs };
    for (const [id, job] of Object.entries(updatedJobs)) {
      if (job.status === 'completed' || job.status === 'failed' || (currentTime - job.lastUpdated > 60000)) {
        delete updatedJobs[id];
      }
    }
    return updatedJobs;
  }, []);

  const MultiJobProgress = ({ jobs }) => {
    return (
      <Box position="fixed" bottom={4} right={4} bg="white" p={4} borderRadius="md" boxShadow="lg" maxWidth="300px">
        {Object.entries(jobs).map(([jobId, job]) => (
          <Box key={jobId} mb={4}>
            <Text fontWeight="bold" isTruncated>{job.name}</Text>
            <Progress value={job.progress} size="sm" colorScheme="blue" />
            <Text mt={2} fontSize="sm">Verifying emails... {job.progress.toFixed(2)}% complete</Text>
            {job.estimatedTime && (
              <Text fontSize="sm">
                Est. time remaining: {Math.max(0, job.estimatedTime).toFixed(1)} minutes
              </Text>
            )}
          </Box>
        ))}
      </Box>
    );
  };


  const fetchActiveJobsStatus = useCallback(async () => {
    try {
      const response = await api.get(`/api/email-verifier/active-jobs`);
      const activeJobsData = response.data.reduce((acc, job) => {
        if (job.status !== 'completed' && job.status !== 'failed') {
          acc[job.verificationHistoryId] = {
            ...job,
            lastUpdated: new Date().getTime()
          };
        }
        return acc;
      }, {});
      setActiveJobs(prevJobs => cleanupActiveJobs({ ...prevJobs, ...activeJobsData }));
    } catch (error) {
      console.error('Error fetching active jobs:', error);
    }
  }, [cleanupActiveJobs]);

const fetchVerificationHistory = useCallback(async () => {
  try {
    const response = await api.get(`/api/email-verifier/history`);
    setVerificationHistory(response.data.sort((a, b) => new Date(b.date) - new Date(a.date)));
  } catch (error) {
    console.error('Failed to fetch verification history:', error);
    setError('Failed to fetch verification history');
  }
}, []);

const fetchLists = async () => {
  setIsLoading(true);
  try {
    const response = await api.get(`/api/lists`);
    console.log('Fetched lists data:', response.data);
    if (response.data && Array.isArray(response.data.lists)) {
      setLists(response.data.lists);
    } else {
      setLists([]);
    }
    console.log('Lists state after setting:', lists);
  } catch (error) {
    console.error('Error fetching lists:', error);
    setLists([]);
  } finally {
    setIsLoading(false);
  }
};

  const handleSingleVerification = async () => {
    setError(null);
    setSingleVerificationResult(null);
    try {
      const response = await api.post(`/api/email-verifier/single`, { email });
      setSingleVerificationResult(response.data);
    } catch (error) {
      setError('Failed to verify email');
    }
  };

  const handleBulkVerificationComplete = (result) => {
    console.log('Bulk verification completed:', result);
    fetchVerificationHistory();
  };

  const handleBulkVerification = async (formData) => {
    try {
      setError(null);
      
      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      
      console.log('Sending request to server');
      const response = await api.post(`/api/email-verifier/verify-csv`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });
      console.log('Server response:', response.data);
      
      const { 
        jobId, 
        estimatedTime, 
        verificationHistoryId, 
        verificationName, 
        totalEmails, 
        importType 
      } = response.data;
      
      setVerificationJobId(jobId);
      setEstimatedTime(estimatedTime);
  
      const newEntry = {
        _id: verificationHistoryId,
        date: new Date(),
        name: verificationName,
        status: 'pending',
        progress: 0,
        results: null,
        type: importType === 'import' ? 'Bulk' : 'Download',
        totalEmails: totalEmails,
        importType: importType
      };
  
      setVerificationHistory(prevHistory => [newEntry, ...prevHistory]);
  
      setActiveJobs(prevJobs => ({
        ...prevJobs,
        [verificationHistoryId]: {
          name: verificationName,
          progress: 0,
          status: 'pending',
          estimatedTime,
          importType: importType,
          totalEmails: totalEmails
        }
      }));
  
      setShowBulkModal(false);
      
      toast({
        title: "Verification Started",
        description: `${importType === 'import' ? 'Bulk' : 'Download'} verification for "${verificationName}" has been initiated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      return response.data;
    } catch (error) {
      console.error('Error in handleBulkVerification:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
      setError(error.response?.data?.error || 'An error occurred during verification');
      throw error;
    }
  };

  const handleVerifyExistingLeads = async () => {
    if (!selectedList) {
      setError('Please select a list');
      return;
    }
    setError(null);
    try {
      const response = await api.post(`/api/email-verifier/verify-existing`, { 
        listId: selectedList 
      }, {
        withCredentials: true
      });
      console.log('Verification job started:', response.data);
      const { jobId, estimatedTime, verificationHistoryId, totalEmails, verificationName } = response.data;
      setVerificationJobId(jobId);
      setEstimatedTime(estimatedTime);
  
      const newEntry = {
        _id: verificationHistoryId,
        date: new Date(),
        name: verificationName,
        status: 'pending',
        progress: 0,
        results: null,
        type: 'List'
      };
  
      setVerificationHistory(prevHistory => [newEntry, ...prevHistory]);
      
      setActiveJobs(prevJobs => ({
        ...prevJobs,
        [verificationHistoryId]: {
          name: verificationName,
          progress: 0,
          status: 'pending',
          estimatedTime: estimatedTime,
          totalEmails: totalEmails
        }
      }));
  
    } catch (error) {
      console.error('Error starting verification job:', error);
      setError('Failed to start verification job');
    }
  };

  useEffect(() => {
    const socket = io(API_BASE_URL, {
      withCredentials: true,
      transports: ['websocket', 'polling']
    });
  
    socket.on('connect', () => {
      console.log('Connected to socket');
      console.log('Socket ID:', socket.id);
    });
  
    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      toast({
        title: "Connection Error",
        description: "Failed to connect to the server. Please check your internet connection.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
  
    socket.on('disconnect', (reason) => {
      console.log('Disconnected from socket:', reason);
      if (reason === 'io server disconnect') {
        socket.connect();
      }
    });
  
    socket.on('jobUpdate', (update) => {
      console.log('Received job update:', update);
      const verificationName = update.verificationName || 'Verification job';
      
      setActiveJobs(prevJobs => {
        const updatedJobs = { ...prevJobs };
        if (update.status === 'completed' || update.status === 'failed') {
          delete updatedJobs[update.verificationHistoryId];
        } else {
          updatedJobs[update.verificationHistoryId] = {
            name: verificationName,
            progress: update.progress,
            status: update.status,
            estimatedTime: update.estimatedTime,
            lastUpdated: new Date().getTime()
          };
        }
        return updatedJobs;
      });
    
      setVerificationHistory(prevHistory => 
        prevHistory.map(item => 
          item._id === update.verificationHistoryId
            ? { 
                ...item, 
                name: verificationName,
                progress: update.progress,
                status: update.status,
                results: update.status === 'completed' ? update.result?.verificationResults : null,
                totalEmails: update.result?.totalEmails || item.totalEmails,
                validEmails: update.result?.validEmails || item.validEmails,
                invalidEmails: update.result?.invalidEmails || item.invalidEmails
              }
            : item
        )
      );
    
      if (update.status === 'completed' || update.status === 'failed') {
        fetchVerificationHistory();
        toast({
          title: update.status === 'completed' ? 'Verification Completed' : 'Verification Failed',
          description: `${verificationName} has ${update.status === 'completed' ? 'finished successfully' : `failed. ${update.error || ''}`}`,
          status: update.status === 'completed' ? 'success' : 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  
    socketRef.current = socket;
  
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [fetchVerificationHistory, toast]);

  useEffect(() => {
    fetchVerificationHistory();
    fetchLists();
    fetchActiveJobsStatus();
  
    const cleanupInterval = setInterval(() => {
      setActiveJobs(prevJobs => cleanupActiveJobs(prevJobs));
    }, 60000); // Cleanup every minute
  
    return () => {
      clearInterval(cleanupInterval);
    };
  }, [fetchVerificationHistory, fetchActiveJobsStatus, cleanupActiveJobs]);

  const handleDeleteVerification = async (historyId) => {
    try {
      await api.delete(`/api/email-verifier/history/${historyId}`);
      fetchVerificationHistory();
    } catch (error) {
      console.error('Error deleting verification:', error);
      setError('Failed to delete verification');
    }
  };

  const handleDownload = async (historyId) => {
    try {
      const response = await api.get(`/api/email-verifier/download-results/${historyId}`, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `verification_results_${historyId}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      setError('Failed to download results');
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop: acceptedFiles => {
      setSelectedFile(acceptedFiles[0]);
      setShowBulkModal(true);
    },
    noClick: true,
    noKeyboard: true
  });

  const filteredHistory = verificationHistory.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedHistory = filteredHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxWidth="container.xl" margin="auto" padding={8}>
      <Heading as="h1" size="xl" marginBottom={6}>Email Verifier</Heading>
      
      <Flex flexWrap="wrap" marginX={-4}>
        <Box width={["100%", "100%", "60%"]} paddingX={4} marginBottom={8}>
          <Box 
            borderWidth={1} 
            borderRadius="lg" 
            overflow="hidden" 
            ref={verificationHistoryRef}
            height="565px"
            overflowX={["auto", "auto", "auto", "visible"]}
            sx={{
              '@media screen and (max-width: 1400px)': {
                overflowX: 'auto',
              }
            }}
          >
            <Box p={6} height="100%" width={["900px", "900px", "900px", "100%"]}>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Heading as="h2" size="lg">Verification History</Heading>
                <InputGroup width="200px">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                </InputGroup>
              </Flex>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="30%">Name</Th>
                    <Th width="15%">Type</Th>
                    <Th width="20%">Status</Th>
                    <Th width="17.5%"><CheckIcon /></Th>
                    <Th width="17.5%"><CloseIcon /></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedHistory.slice(0, 6).map((item) => (
                    <Tr key={item._id}>
                      <Td>
                        <Tooltip label={item.name}>
                          <Text isTruncated maxWidth="130px">{item.name}</Text>
                        </Tooltip>
                      </Td>
                      <Td>{item.type === 'bulk' ? 'Bulk' : item.type}</Td>
                      <Td>
                      {(item._id in activeJobs) || item.status === 'pending' ? (
                        <Progress value={item.progress} size="sm" width="100%" />
                      ) : (
                        <Tag colorScheme={item.status === 'Verified' ? "green" : (item.status === 'Failed' ? "red" : "yellow")}>
                          {item.status}
                        </Tag>
                      )}
                      </Td>
                      <Td>
                        {item.status === 'pending' || (item._id in activeJobs) ? (
                          <Spinner size="sm" />
                        ) : (
                          <Flex alignItems="center">
                            {item.validEmails !== undefined ? item.validEmails : 'N/A'}
                            <EmailIcon color="green.500" ml={2} />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Flex alignItems="center">
                            {item.status === 'pending' || (item._id in activeJobs) ? (
                              <Spinner size="sm" />
                            ) : (
                              <>
                                {item.invalidEmails !== undefined ? item.invalidEmails : 'N/A'}
                                <EmailIcon color="red.500" ml={2} />
                              </>
                            )}
                          </Flex>
                          {item.status === 'Verified' && (
                            <Tooltip label="Download Results">
                             <IconButton
  icon={<DownloadIcon />}
  onClick={() => {
    console.log('Download button clicked');
    handleDownload(item._id);
  }}
  aria-label="Download Results"
  size="sm"
                                ml="7"
                              />
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          {filteredHistory.length > itemsPerPage && (
            <Flex justifyContent="flex-end" mt={4}>
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                mr={2}
                aria-label="Previous page"
              />
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredHistory.length / itemsPerPage)))}
                disabled={currentPage === Math.ceil(filteredHistory.length / itemsPerPage)}
                aria-label="Next page"
              />
            </Flex>
          )}
        </Box>
        
        <Box width={["100%", "100%", "40%"]} paddingX={4}>
          <VStack spacing={8}>
            <Box width="100%" borderWidth={1} borderRadius="lg" overflow="hidden">
              <Box p={6}>
                <Heading as="h2" size="lg" marginBottom={4}>Single Email Verification</Heading>
                <HStack>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email to verify"
                  />
                  <Button onClick={handleSingleVerification}>
                    Verify
                  </Button>
                </HStack>
                {singleVerificationResult && (
                  <Text mt={2} fontWeight="bold" color={singleVerificationResult.isValid ? "green.500" : "red.500"}>
                    {singleVerificationResult.isValid ? 'Valid' : 'Invalid'}
                  </Text>
                )}
              </Box>
            </Box>
  
            <Box width="100%" borderWidth={1} borderRadius="lg" overflow="hidden">
              <Box p={6}>
                <Heading as="h2" size="lg" marginBottom={4}>Verify Existing Leads</Heading>
                <HStack>
                <Select
  placeholder="Select a list"
  value={selectedList}
  onChange={(e) => setSelectedList(e.target.value)}
>
  {Array.isArray(lists) && lists.map((list) => (
    <option key={list._id} value={list._id}>{list.name}</option>
  ))}
</Select>
                  <Button onClick={handleVerifyExistingLeads} isDisabled={!selectedList}>
                    Verify List
                  </Button>
                </HStack>
              </Box>
            </Box>
  
            <Box width="100%" borderWidth={1} borderRadius="lg" overflow="hidden" ref={bulkVerificationRef}>
              <Box p={6}>
                <Heading as="h2" size="lg" marginBottom={4}>Bulk Email Verification</Heading>
                <Box
                  {...getRootProps()}
                  borderWidth={2}
                  borderStyle="dashed"
                  borderRadius="md"
                  p={8}
                  textAlign="center"
                  cursor="pointer"
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text>Drop the CSV file here ...</Text>
                  ) : (
                    <Text>Drag 'n' drop a CSV file here, or click to select a file</Text>
                  )}
                </Box>
              </Box>
            </Box>
          </VStack>
        </Box>
      </Flex>
  
      {error && <Text color="red.500" mt={4}>{error}</Text>}
      
      {showBulkModal && selectedFile && (
        <BulkVerificationModal
          file={selectedFile}
          onClose={() => setShowBulkModal(false)}
          onVerificationComplete={handleBulkVerificationComplete}
          lists={lists}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          handleBulkVerification={handleBulkVerification}
          verificationHistory={verificationHistory}
        />
      )}
      
      {Object.keys(activeJobs).length > 0 && (
        <MultiJobProgress jobs={activeJobs} />
      )}
    </Box>
  );
}

export default EmailVerifier;
