// src/googleApi.js
const CLIENT_ID = '102788626182-0v93jo32vaahkao68e97gt75nfp29d0i.apps.googleusercontent.com';
const SCOPES = [
  'https://www.googleapis.com/auth/gmail.send',
  'https://www.googleapis.com/auth/gmail.modify',
  'https://www.googleapis.com/auth/gmail.compose',
  'https://www.googleapis.com/auth/gmail.readonly',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile'
].join(' ');

let tokenClient;

export const initClient = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      resolve();
    };
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

export const signIn = () => {
  return new Promise((resolve, reject) => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        reject(resp);
      } else {
        try {
          const userInfo = await getUserInfo(resp.access_token);
          resolve({...userInfo, token: resp.access_token});
        } catch (error) {
          reject(error);
        }
      }
    };
    tokenClient.requestAccessToken({prompt: 'consent'});
  });
};

export const signOut = () => {
  const token = localStorage.getItem('access_token');
  if (token) {
    google.accounts.oauth2.revoke(token, () => {
      console.log('Token revoked');
    });
    localStorage.removeItem('access_token');
  }
};

export const isSignedIn = () => {
  return localStorage.getItem('access_token') !== null;
};

export const getUserInfo = async (accessToken) => {
  const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user info');
  }

  const userInfo = await response.json();
  return {
    id: userInfo.sub,
    email: userInfo.email,
    name: userInfo.name,
    firstName: userInfo.given_name,
    lastName: userInfo.family_name,
    imageUrl: userInfo.picture,
    photoUrl: userInfo.picture // Add this line to ensure compatibility
  };
};

export const sendEmail = async (recipient, subject, body) => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    throw new Error('Not authenticated');
  }

  const message = [
    'From: "me"',
    `To: ${recipient}`,
    `Subject: ${subject}`,
    '',
    body,
  ].join('\n');

  const base64EncodedEmail = btoa(unescape(encodeURIComponent(message)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  const response = await fetch('https://www.googleapis.com/gmail/v1/users/me/messages/send', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      raw: base64EncodedEmail
    })
  });

  if (!response.ok) {
    throw new Error('Failed to send email');
  }

  return response.json();
};
const handleCredentialResponse = (response) => {
  console.log("Encoded JWT ID token: " + response.credential);
  // You might want to verify this token on your server
};




