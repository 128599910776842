import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, VStack, Heading, IconButton, Text, Flex, Input, InputGroup, InputLeftElement,
  Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
  ModalBody, ModalCloseButton, Alert, AlertIcon,
} from '@chakra-ui/react';
import { AddIcon, SearchIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ list, currentList, onListClick }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: list._id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 1,
    opacity: isDragging ? 0.5 : 1,
    boxShadow: isDragging ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none',
  };

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      justify="space-between"
      align="center"
      p={4}
      borderRadius="lg"
      cursor="pointer"
      border="1px solid"
      borderColor={currentList === list._id ? 'gray.300' : 'gray.200'}
      bg={currentList === list._id ? 'gray.50' : 'white'}
      _hover={{ borderColor: 'gray.300', bg: 'gray.50' }}
      onClick={() => onListClick(list._id)}
      mb={2}
    >
      <Text fontSize="md" fontWeight="medium" color="gray.700">{list.name}</Text>
      <Text fontSize="md" fontWeight="medium" color="gray.500">{list.count}</Text>
    </Flex>
  );
};

const DragOverlayContent = ({ list }) => {
  if (!list) return null;
  
  return (
    <Flex
      justify="space-between"
      align="center"
      p={4}
      borderRadius="lg"
      cursor="grabbing"
      border="1px solid"
      borderColor="blue.300"
      bg="blue.50"
      boxShadow="0 0 15px rgba(0, 0, 0, 0.1)"
      opacity={0.8}
      mb={2}
      width="100%"
    >
      <Text fontSize="md" fontWeight="medium" color="gray.700">{list.name}</Text>
      <Text fontSize="md" fontWeight="medium" color="gray.500">{list.count}</Text>
    </Flex>
  );
};

const ListsSidebar = ({ currentList, onListClick, onCreateList, lists, onReorderLists, totalLeadCount }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [internalLists, setInternalLists] = useState(lists);
  const [activeId, setActiveId] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newListName, setNewListName] = useState('');
  const [error, setError] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const LISTS_PER_PAGE = 10;
  const INITIAL_VISIBLE_LISTS = 5;

  useEffect(() => {
    setInternalLists(lists);
  }, [lists]);

  const filteredLists = useMemo(() => {
    return internalLists.filter(list => 
      list.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [internalLists, searchTerm]);

  const visibleLists = useMemo(() => {
    if (showMore) {
      const startIndex = (currentPage - 1) * LISTS_PER_PAGE;
      return filteredLists.slice(startIndex, startIndex + LISTS_PER_PAGE);
    } else {
      return filteredLists.slice(0, INITIAL_VISIBLE_LISTS);
    }
  }, [filteredLists, showMore, currentPage]);

  const totalPages = Math.ceil(filteredLists.length / LISTS_PER_PAGE);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
  
    if (active.id !== over.id) {
      setInternalLists((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        
        // Call onReorderLists with the new order
        onReorderLists(newItems).catch(() => {
          // If reordering fails, revert to the original order
          setInternalLists(items);
        });
        
        return newItems;
      });
    }
    setActiveId(null);
  };

  const handleCreateList = () => {
    if (newListName.trim()) {
      const isDuplicate = internalLists.some(list => list.name.toLowerCase() === newListName.trim().toLowerCase());
      if (isDuplicate) {
        setError('A list with this name already exists.');
      } else {
        onCreateList(newListName.trim());
        setNewListName('');
        setError('');
        onClose();
      }
    }
  };

  return (
    <Box width="300px" height="100vh" p={8} bg="white" borderRight="1px" borderColor="gray.200">
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading as="h2" size="lg" color="gray.700">
          Lists
        </Heading>
        <IconButton
          icon={<AddIcon />}
          onClick={onOpen}
          variant="outline"
          size="md"
          aria-label="Create new list"
        />
      </Flex>

      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input 
          placeholder="Search lists" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      <VStack spacing={4} align="stretch">
        <Flex
          justify="space-between"
          align="center"
          p={4}
          borderRadius="lg"
          cursor="pointer"
          border="1px solid"
          borderColor={currentList === 'all' ? 'gray.300' : 'gray.200'}
          bg={currentList === 'all' ? 'gray.50' : 'white'}
          _hover={{ borderColor: 'gray.300', bg: 'gray.50' }}
          onClick={() => onListClick('all')}
          mb={2}
        >
          <Text fontSize="md" fontWeight="medium" color="gray.700">All leads</Text>
          <Text fontSize="md" fontWeight="medium" color="gray.500">{totalLeadCount}</Text>
        </Flex>

        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext 
            items={visibleLists.map(list => list._id)}
            strategy={verticalListSortingStrategy}
          >
            {visibleLists.map((list) => (
              <SortableItem
                key={list._id}
                list={list}
                currentList={currentList}
                onListClick={onListClick}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId ? <DragOverlayContent list={internalLists.find(list => list._id === activeId)} /> : null}
          </DragOverlay>
        </DndContext>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Input
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="Enter list name"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateList}>
              Create
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ListsSidebar;