import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  HStack,
  Text,
  IconButton,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import { DeleteIcon, InfoIcon, AddIcon } from '@chakra-ui/icons';

const FollowUpModal = ({ isOpen, onClose, onAddFollowUps, maxFollowUps = 6, currentEmailCount = 1 }) => {
  const [followUps, setFollowUps] = useState([{ days: 1 }]);

  useEffect(() => {
    if (isOpen) {
      setFollowUps([{ days: 1 }]);
    }
  }, [isOpen]);

  const handleAddFollowUp = () => {
    if (currentEmailCount + followUps.length < maxFollowUps) {
      setFollowUps([...followUps, { days: 1 }]);
    }
  };

  const handleRemoveFollowUp = (index) => {
    setFollowUps(followUps.filter((_, i) => i !== index));
  };

  const handleFollowUpDaysChange = (index, value) => {
    const updatedFollowUps = followUps.map((followUp, i) =>
      i === index ? { ...followUp, days: value } : followUp
    );
    setFollowUps(updatedFollowUps);
  };

  const handleSubmit = () => {
    onAddFollowUps(followUps);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="350px">
        <ModalHeader>
          <HStack>
            <Text fontSize="md">Add Follow-up Emails</Text>
            <Tooltip label="Follow-ups will send after the last email in the chain, so if it's 2 days after, then it will send it 2 days after the follow-up (or original email) has sent.">
              <InfoIcon />
            </Tooltip>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3} align="stretch">
            {followUps.map((followUp, index) => (
              <FormControl key={index}>
                <HStack spacing={1} alignItems="center" justifyContent="space-between">
                  <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">Follow-up {index + 1}</Text>
                  <Text fontSize="sm">After</Text>
                  <NumberInput
                    value={followUp.days}
                    onChange={(_, value) => handleFollowUpDaysChange(index, value)}
                    min={1}
                    max={10}
                    w="70px"
                    size="md"
                  >
                    <NumberInputField p={1} textAlign="center" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text fontSize="sm" whiteSpace="nowrap">{followUp.days === 1 ? 'day' : 'days'}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleRemoveFollowUp(index)}
                    aria-label="Remove follow-up"
                    size="sm"
                  />
                </HStack>
              </FormControl>
            ))}
          </VStack>
          {currentEmailCount + followUps.length < maxFollowUps && (
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddFollowUp}
              variant="outline"
              borderColor="blue.500"
              color="blue.500"
              size="sm"
              height="35px"
              width="100%"
              mt={4}
            >
              Add Another Follow-up
            </Button>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} size="sm">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} size="sm">
            Add Follow-ups
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FollowUpModal;