import React from 'react';
import { Box, Flex, VStack, HStack, Skeleton, Container } from '@chakra-ui/react';

// Variation 1: Adjusted layout with header space
const SkeletonLoader1 = () => (
  <Container maxW="container.xl" py={8}>
    <Flex justifyContent="space-between" alignItems="center" mb={8}>
      <Skeleton height="40px" width="200px" />
      <HStack spacing={4}>
        <Skeleton height="40px" width="100px" />
        <Skeleton height="40px" width="100px" />
        <Skeleton height="40px" width="100px" />
      </HStack>
    </Flex>
    <Flex justifyContent="flex-end">
      <Flex mt={8} mr={6}>
        <VStack align="stretch" width="300px" mr={6}>
          <HStack spacing={1} mb={4}>
            <Skeleton height="42px" width="100%" />
            <Skeleton height="42px" width="100%" />
          </HStack>
          <VStack align="stretch" spacing={4}>
            {[...Array(3)].map((_, i) => (
              <Skeleton key={i} height="100px" width="100%" />
            ))}
          </VStack>
        </VStack>
        <VStack align="stretch" width="850px">
          <Skeleton height="40px" width="100%" mb={2} />
          <Skeleton height="300px" width="100%" mb={4} />
          <HStack spacing={2}>
            <Skeleton height="35px" width="120px" />
            <Skeleton height="35px" width="120px" />
            <Skeleton height="35px" width="120px" />
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  </Container>
);

// Variation 2: More compact layout with increased right and downward positioning
const SkeletonLoader2 = () => (
  <Container maxW="container.xl" py={12}>
    <Flex justifyContent="flex-end" mb={12}>
      <HStack spacing={4}>
        <Skeleton height="40px" width="100px" />
        <Skeleton height="40px" width="100px" />
        <Skeleton height="40px" width="100px" />
      </HStack>
    </Flex>
    <Flex justifyContent="flex-end">
      <Box width="1150px">
        <Flex>
          <VStack align="stretch" width="280px" mr={8}>
            <HStack spacing={1} mb={4}>
              <Skeleton height="42px" width="100%" />
              <Skeleton height="42px" width="100%" />
            </HStack>
            <VStack align="stretch" spacing={3}>
              {[...Array(4)].map((_, i) => (
                <Skeleton key={i} height="80px" width="100%" />
              ))}
            </VStack>
          </VStack>
          <VStack align="stretch" flex={1}>
            <Skeleton height="40px" width="100%" mb={2} />
            <Skeleton height="250px" width="100%" mb={4} />
            <HStack spacing={2}>
              <Skeleton height="35px" width="100px" />
              <Skeleton height="35px" width="100px" />
              <Skeleton height="35px" width="100px" />
            </HStack>
          </VStack>
        </Flex>
      </Box>
    </Flex>
  </Container>
);

// Variation 3: Asymmetrical layout with more emphasis on the main content area
const SkeletonLoader3 = () => (
  <Container maxW="container.xl" py={10}>
    <Flex justifyContent="space-between" alignItems="center" mb={10}>
      <Skeleton height="40px" width="250px" />
      <HStack spacing={4}>
        <Skeleton height="40px" width="120px" />
        <Skeleton height="40px" width="120px" />
        <Skeleton height="40px" width="120px" />
      </HStack>
    </Flex>
    <Flex justifyContent="flex-end">
      <Box width="1200px">
        <Flex>
          <VStack align="stretch" width="250px" mr={10}>
            <HStack spacing={1} mb={6}>
              <Skeleton height="45px" width="100%" />
              <Skeleton height="45px" width="100%" />
            </HStack>
            <VStack align="stretch" spacing={5}>
              {[...Array(5)].map((_, i) => (
                <Skeleton key={i} height="70px" width="100%" />
              ))}
            </VStack>
          </VStack>
          <VStack align="stretch" flex={1}>
            <Skeleton height="50px" width="100%" mb={4} />
            <Skeleton height="300px" width="100%" mb={6} />
            <HStack spacing={3} justifyContent="space-between">
              <HStack>
                <Skeleton height="40px" width="130px" />
                <Skeleton height="40px" width="130px" />
                <Skeleton height="40px" width="130px" />
              </HStack>
              <Skeleton height="40px" width="100px" />
            </HStack>
          </VStack>
        </Flex>
      </Box>
    </Flex>
  </Container>
);

export { SkeletonLoader1, SkeletonLoader2, SkeletonLoader3 };