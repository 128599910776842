import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    background: "hsl(0, 0%, 100%)",
    foreground: "hsl(222.2, 47.4%, 11.2%)",
    muted: "hsl(210, 40%, 96.1%)",
    mutedForeground: "hsl(215.4, 16.3%, 46.9%)",
    popover: "hsl(0, 0%, 100%)",
    popoverForeground: "hsl(222.2, 47.4%, 11.2%)",
    card: "hsl(0, 0%, 100%)",
    cardForeground: "hsl(222.2, 47.4%, 11.2%)",
    border: "hsl(214.3, 31.8%, 91.4%)",
    input: "hsl(214.3, 31.8%, 91.4%)",
    primary: {
      50: "hsl(204, 71.4%, 97%)",
      100: "hsl(204, 71.4%, 94%)",
      200: "hsl(204, 71.4%, 88%)",
      300: "hsl(204, 71.4%, 82%)",
      400: "hsl(204, 71.4%, 78%)",
      500: "hsl(204, 71.4%, 73.9%)",  // This is your #8dc6ec
      600: "hsl(204, 71.4%, 66%)",
      700: "hsl(204, 71.4%, 58%)",
      800: "hsl(204, 71.4%, 50%)",
      900: "hsl(204, 71.4%, 42%)",
    },
    primaryForeground: "hsl(222.2, 47.4%, 11.2%)",
    secondary: "hsl(210, 40%, 96.1%)",
    secondaryForeground: "hsl(222.2, 47.4%, 11.2%)",
    accent: "hsl(210, 40%, 96.1%)",
    accentForeground: "hsl(222.2, 47.4%, 11.2%)",
    destructive: "hsl(0, 100%, 50%)",
    destructiveForeground: "hsl(210, 40%, 98%)",
    ring: "hsl(215, 20.2%, 65.1%)",
  },
  radii: {
    base: "0.5rem",
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: "primary.500",
          color: "white",
          _hover: {
            bg: "primary.600",
          },
          _active: {
            bg: "primary.700",
          },
        },
      },
    },
  },
});

export default theme;