import React, { useState, useEffect, useRef,  useCallback } from 'react';
import api from '../api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Box, Flex, Heading, Button, Input, Table, Thead, Tbody, Tr, Th, Td, Tag, IconButton, Menu, MenuButton, MenuList, MenuItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Select, Checkbox, Text, Tooltip, HStack, VStack, Container, Divider, Stack, Badge, useToast } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, DeleteIcon, EditIcon, SearchIcon, ViewIcon, SettingsIcon } from '@chakra-ui/icons';
import ListsSidebar from './ListsSidebar';
import AddLeadForm from './AddLeadForm';
import ImportLeadsModal from './ImportLeadsModal';
import ImportProgressBox from './ImportProgressBox';
import io from 'socket.io-client';
 

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [lists, setLists] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(20);
  const [showNewListForm, setShowNewListForm] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [totalLeadCount, setTotalLeadCount] = useState(0);
  const [editingLead, setEditingLead] = useState(null);
  const [showListActions, setShowListActions] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const fixedColumns = ['Email', 'Verification Status', 'Company', 'Position', 'Date Added'];
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAddLeadFormOpen, setIsAddLeadFormOpen] = useState(false);
  const [importJobId, setImportJobId] = useState(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [socket, setSocket] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isListsFetched, setIsListsFetched] = useState(false);
  const [currentList, setCurrentList] = useState('all');


  const navigate = useNavigate();
  const location = useLocation();
  const listActionsRef = useRef(null);


  useEffect(() => {
    const handleLogout = () => {
      navigate('/login');
    };

    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('logout', handleLogout);
    };
  }, [navigate]);

  const fetchLeads = useCallback(async (listId) => {
    if (!isListsFetched) return;
    setIsLoading(true);
    const listToFetch = listId || currentList;
    console.log('Fetching leads for list:', listToFetch);
  
    try {
      const response = await api.get(`/api/leads`, {
        params: {
          list: listToFetch,
          page: currentPage,
          limit: leadsPerPage,
          search: searchTerm,
          sortField,
          sortOrder
        },
        withCredentials: true // Ensure cookies are sent with the request
      });
      console.log('Fetched leads:', response.data);
      setLeads(response.data.leads);
      setTotalLeads(response.data.total);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching leads:', error);
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login
        navigate('/login');
      } else {
        setLeads([]);
        setTotalLeads(0);
        setTotalPages(0);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentList, currentPage, leadsPerPage, searchTerm, sortField, sortOrder, isListsFetched, navigate]);

  const fetchListCounts = useCallback(async () => {
    try {
      const [countsResponse, totalCountResponse] = await Promise.all([
        api.get(`/api/lists/counts`, {  }),
        api.get(`/api/leads/count`, {  })
      ]);
      return {
        ...countsResponse.data,
        all: totalCountResponse.data.count
      };
    } catch (error) {
      console.error('Error fetching list counts:', error);
      return {};
    }
  }, []);

  const fetchListsAndCounts = useCallback(async () => {
    console.log('Fetching lists and counts...');
    try {
      const [listsResponse, countsResponse] = await Promise.all([
        api.get(`/api/lists`, { withCredentials: true }),
        fetchListCounts()
      ]);
      
      console.log('Lists response:', listsResponse.data);
      console.log('Counts response:', countsResponse);
  
      const listsWithCounts = listsResponse.data.lists.map(list => ({
        ...list,
        count: countsResponse[list._id] || 0
      })).sort((a, b) => a.order - b.order);
  
      setLists(listsWithCounts);
      
      const newTotalCount = countsResponse.all || 0;
      console.log('Fetched total count:', newTotalCount);
      setTotalLeadCount(prevCount => {
        if (prevCount !== newTotalCount) {
          console.log('Updating totalLeadCount from', prevCount, 'to', newTotalCount);
          return newTotalCount;
        }
        return prevCount;
      });
      
      setIsListsFetched(true);
      fetchLeads(currentList); // Fetch leads after setting isListsFetched
    } catch (error) {
      console.error('Error fetching lists and counts:', error);
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login
        navigate('/login');
      } else {
        // Handle other errors
        toast({
          title: "Error",
          description: "Failed to fetch lists and counts. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [fetchListCounts, fetchLeads, currentList, navigate, toast]);



  useEffect(() => {
    fetchListsAndCounts();
  }, [fetchListsAndCounts]);

  useEffect(() => {
    console.log('totalLeadCount updated:', totalLeadCount);
  }, [totalLeadCount]);

  useEffect(() => {
    if (isListsFetched) {
      console.log('Fetching leads due to parameter change...');
      fetchLeads(currentList);
    }
  }, [fetchLeads, currentList, isListsFetched]);



  const handleImportFinished = useCallback(async (data) => {
    console.log('handleImportFinished called with:', data);
    setImportJobId(null);
    await fetchListsAndCounts();
    console.log('About to show toast with data:', data);
    toast({
      title: "Import Completed",
      description: `Successfully imported ${data.newLeadsCount} new leads to the database${data.newLeadsInCurrentList ? ` (${data.newLeadsInCurrentList} new to this list)` : ''}, updated ${data.updatedLeadsCount} existing leads, and skipped ${data.skippedLeadsCount} leads.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    console.log('Toast shown');
  }, [fetchListsAndCounts, toast]);



  useEffect(() => {
    const newSocket = io(API_BASE_URL, {
      withCredentials: true,
      transports: ['websocket']
    });
  
    console.log('Socket connection attempted');
    newSocket.on('connect', () => {
      console.log('Socket connected successfully', newSocket.id);
    });
  
    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error.message);
      if (error.message === 'Authentication error') {
        console.log('Socket authentication error');
        navigate('/login');
      }
    });
  
    setSocket(newSocket);
  
    return () => {
      console.log('Cleaning up socket listeners');
      newSocket.disconnect();
    };
  }, [navigate]);


 
  useEffect(() => {
    if (isListsFetched) {
      console.log('Fetching leads due to parameter change...');
      fetchLeads(currentList);
    }
  }, [fetchLeads, currentPage, leadsPerPage, sortField, sortOrder, searchTerm, isListsFetched]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listActionsRef.current && !listActionsRef.current.contains(event.target)) {
        setShowListActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  useEffect(() => {
    const listFromUrl = new URLSearchParams(location.search).get('list');
    if (listFromUrl && listFromUrl !== currentList) {
      console.log('Setting current list from URL:', listFromUrl);
      setCurrentList(listFromUrl);
    } else if (!listFromUrl && currentList !== 'all') {
      console.log('Setting current list to all');
      setCurrentList('all');
    }
  }, [location.search, currentList]);

  const renderCellContent = (lead, attr) => {
    const getLowerCaseAttribute = (obj, key) => {
      const lowerKey = key.toLowerCase();
      return obj[Object.keys(obj).find(k => k.toLowerCase() === lowerKey)] || '-';
    };
  
    switch (attr.toLowerCase()) {
      case 'email':
        return (
          <VStack align="start" spacing={0}>
            {renderLeadName(lead)}
            <Text fontSize="sm" color="gray.600">{getLowerCaseAttribute(lead, 'email')}</Text>
          </VStack>
        );
      case 'verification status':
        return renderVerificationStatus(getLowerCaseAttribute(lead, 'verificationStatus'));
      case 'company':
        return getLowerCaseAttribute(lead, 'company');
      case 'position':
        return getLowerCaseAttribute(lead, 'position');
      case 'date added':
        const createdAt = getLowerCaseAttribute(lead, 'createdAt');
        return createdAt !== '-' ? formatDistanceToNow(new Date(createdAt), { addSuffix: true }) : '-';
      default:
        return getLowerCaseAttribute(lead, attr);
    }
  };


  const handleRenameList = async () => {
    if (!newListName.trim()) return;
    try {
      const response = await api.put(`/api/lists/${currentList}`, { name: newListName }, {  });
      setLists(prevLists => prevLists.map(list => 
        list._id === currentList ? { ...list, name: newListName } : list
      ));
      setIsRenaming(false);
      setShowListActions(false);
    } catch (error) {
      console.error('Error renaming list:', error);
      alert('Failed to rename list. Please try again.');
    }
  };

  const handleDeleteList = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this list? This action cannot be undone and will remove all leads associated with this list."
    );
    
    if (confirmDelete) {
      try {
        await api.delete(`/api/lists/${currentList}`, {  });
        setLists(prevLists => prevLists.filter(list => list._id !== currentList));
        setCurrentList('all');
        navigate('/leads');
        fetchLeads('all');
      } catch (error) {
        console.error('Error deleting list:', error);
        alert('Failed to delete list. Please try again.');
      }
    }
  };



  const handleListClick = useCallback((listId) => {
    setCurrentPage(1);
    navigate(`/leads?list=${listId}`);
  }, [navigate]);

  const handleCreateList = async (name) => {
    try {
      console.log('Attempting to create list:', name);
      const response = await api.post(`/api/lists`, { name }, {  });
      console.log('List created successfully:', response.data);
      setLists(prevLists => [...prevLists, response.data]);
      fetchLeads();
    } catch (error) {
      console.error('Error creating list:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
      }
      alert('Failed to create list. Please try again.');
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedLeads(leads.map(lead => lead._id));
    } else {
      setSelectedLeads([]);
    }
  };

  const handleSelectLead = (leadId) => {
    setSelectedLeads(prevSelected => 
      prevSelected.includes(leadId)
        ? prevSelected.filter(id => id !== leadId)
        : [...prevSelected, leadId]
    );
  };

  const handleDeleteLead = async (leadId) => {
    if (window.confirm('Are you sure you want to delete this lead?')) {
      try {
        await api.delete(`/api/leads/${leadId}`);
        setLeads(prevLeads => prevLeads.filter(lead => lead._id !== leadId));
      } catch (error) {
        console.error('Error deleting lead:', error);
        alert('Failed to delete lead. Please try again.');
      }
    }
  };
  const handleBulkDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedLeads.length} leads?`)) {
      try {
        const response = await api.post(`/api/leads/bulk-delete`, { leadIds: selectedLeads });
        
        setLeads(prevLeads => {
          const updatedLeads = prevLeads.filter(lead => !selectedLeads.includes(lead._id));
          
          // Update the counts for each list
          setLists(prevLists => prevLists.map(list => {
            const leadsInList = prevLeads.filter(lead => 
              lead.lists.includes(list._id) && selectedLeads.includes(lead._id)
            ).length;
            return {
              ...list,
              count: Math.max(0, list.count - leadsInList)
            };
          }));
          
          // Update total lead count
          setTotalLeadCount(prev => Math.max(0, prev - selectedLeads.length));
          
          return updatedLeads;
        });
        
        setSelectedLeads([]);
        alert(response.data.message); // Show success message
      } catch (error) {
        console.error('Error deleting leads:', error);
        alert(error.response?.data?.message || 'Failed to delete leads. Please try again.');
      }
    }
  };

  
  const renderLeadLists = (lead) => {
    if (!lead.lists || lead.lists.length === 0) return 'No List';
    return lead.lists.map(list => list.name).join(', ');
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    setCurrentPage(1);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderLeadName = (lead) => {
    if (lead.firstName && lead.lastName) {
      const firstName = capitalizeFirstLetter(lead.firstName);
      const lastName = capitalizeFirstLetter(lead.lastName);
      return (
        <span className="font-bold">
          {firstName} {lastName} -&nbsp;
        </span>
      );
    }
    return null;
  };

  const handleEditLead = async (leadId) => {
    try {
      console.log('Fetching lead details for ID:', leadId);
      const response = await api.get(`/api/leads/${leadId}`);
      console.log('Received lead details:', response.data);
      setEditingLead(response.data);
      setIsAddLeadFormOpen(true);
    } catch (error) {
      console.error('Error fetching lead details:', error);
      console.error('Error response:', error.response?.data);
      alert(`Failed to fetch lead details. Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleAddOrUpdateLead = async (leadData) => {
    try {
      if (editingLead) {
        // Update existing lead
        const response = await api.put(`/api/leads/${editingLead._id}`, leadData);
        setLeads(prevLeads => prevLeads.map(lead => lead._id === editingLead._id ? response.data : lead));
      } else {
        // Add new lead
        const response = await api.post(`/api/leads`, {
          ...leadData,
          lists: currentList !== 'all' ? [currentList] : []
        });
        setLeads(prevLeads => [...prevLeads, response.data]);
      }
  
      setIsAddLeadFormOpen(false);
      setEditingLead(null);
    } catch (error) {
      console.error('Error adding/updating lead:', error);
      alert('Failed to add/update lead. Please try again.');
    }
  };

  const handleImportComplete = (jobId) => {
    console.log('Import job started:', jobId);
    setImportJobId(jobId);
    if (socket) {
      socket.emit('subscribe', jobId);
    }
    setIsImportModalOpen(false);
  };


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderVerificationStatus = (status) => {
    switch (status) {
      case 'Verified':
        return <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Verified</span>;
      case 'Invalid':
        return <span className="px-2 py-1 bg-red-100 text-red-800 rounded-full text-xs">Invalid</span>;
      default:
        return <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">Unverified</span>;
    }
  };
 


  const handleReorderLists = async (newLists) => {
    try {
      // Send update to server
      await api.post(`/api/lists/reorder`, { order: newLists.map(list => list._id) }, {  });
      
      // If the server update is successful, update the local state
      setLists(newLists);
      console.log('Lists reordered successfully');
    } catch (error) {
      console.error('Error reordering lists:', error);
      // If the server update fails, show an error message to the user
      alert('Failed to reorder lists. Please try again.');
      // Optionally, you might want to refresh the lists from the server to ensure consistency
      fetchLists();
    }
  };
  
  
  return (
    <Flex h="100vh">
      <ListsSidebar 
  currentList={currentList} 
  onListClick={handleListClick}
  onCreateList={handleCreateList}
  lists={lists}
  onReorderLists={handleReorderLists}
  totalLeadCount={totalLeadCount}
/>
      <Box flex={1} overflow="auto">
        <Container maxW="container.xl" py={8}>
          <VStack spacing={8} align="stretch">
            <Flex justify="space-between" align="center" wrap="wrap" gap={4}>
              <Heading as="h1" size="lg" color="gray.800">
                {currentList === 'all' ? 'All Leads' : lists.find(l => l._id === currentList)?.name}
              </Heading>
              <HStack>
                <Input
                  placeholder="Search leads..."
                  value={searchTerm}
                  onChange={handleSearch}
                  width={{ base: "100%", md: "300px" }}
                />
                {currentList !== 'all' && (
                  <Menu>
                    <MenuButton as={IconButton} icon={<SettingsIcon />} variant="ghost" />
                    <MenuList>
                      <MenuItem onClick={() => setIsRenaming(true)}>Rename List</MenuItem>
                      <MenuItem onClick={handleDeleteList} color="red.500">Delete List</MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </Flex>
  
            <Stack direction={{ base: "column", md: "row" }} justify="space-between" align="center" wrap="wrap" spacing={4}>
              <HStack spacing={2}>
                {selectedLeads.length > 0 && (
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                      Bulk Actions ({selectedLeads.length})
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={handleBulkDelete}>Delete Selected</MenuItem>
                    </MenuList>
                  </Menu>
                )}
                <Button leftIcon={<AddIcon />} variant="ghost" onClick={() => {
  setEditingLead(null);
  setIsAddLeadFormOpen(true);
}}>
  New Lead
</Button>
<Button leftIcon={<AddIcon />} variant="ghost" onClick={() => setIsImportModalOpen(true)}>
  Import CSV
</Button>
              </HStack>
            </Stack>
  
            <Box borderWidth={1} borderRadius="lg" shadow="md">
            <Table variant="simple">
  <Thead>
    <Tr>
      <Th px={4} py={3}>
      <Checkbox
  isChecked={selectedLeads.length === leads.length && leads.length > 0}
  onChange={handleSelectAll}
/>
      </Th>
      {fixedColumns.map(column => (
        <Th 
          px={4} 
          py={3} 
          key={column} 
          onClick={() => handleSort(column.toLowerCase())}
          cursor="pointer"
        >
          {column}
          {sortField === column.toLowerCase() && (
            <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
          )}
        </Th>
      ))}
      <Th px={4} py={3}>Actions</Th>
    </Tr>
  </Thead>
  <Tbody>
  {leads.map((lead) => (
      <Tr key={lead._id}>
        <Td px={4} py={3}>
          <Checkbox
            isChecked={selectedLeads.includes(lead._id)}
            onChange={() => handleSelectLead(lead._id)}
          />
        </Td>
        {fixedColumns.map(column => (
          <Td px={4} py={3} key={column}>
            {renderCellContent(lead, column)}
          </Td>
        ))}
        <Td px={4} py={3}>
          <HStack spacing={2}>
            <Tooltip label="View lead">
              <IconButton
                icon={<ViewIcon />}
                aria-label="View lead"
                size="sm"
                variant="ghost"
                colorScheme="blue"
              />
            </Tooltip>
            <Tooltip label="Edit lead">
              <IconButton
                icon={<EditIcon />}
                aria-label="Edit lead"
                size="sm"
                variant="ghost"
                colorScheme="green"
                onClick={() => handleEditLead(lead._id)}
              />
            </Tooltip>
            <Tooltip label="Delete lead">
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete lead"
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => handleDeleteLead(lead._id)}
              />
            </Tooltip>
          </HStack>
        </Td>
      </Tr>
    ))}
  </Tbody>
</Table>
</Box>
  
<Flex justify="space-between" align="center" mt={4}>
  <HStack>
    <Text fontSize="sm">Rows per page:</Text>
    <Select
      value={leadsPerPage}
      onChange={(e) => {
        setLeadsPerPage(Number(e.target.value));
        setCurrentPage(1);
      }}
      size="sm"
      width="70px"
    >
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </Select>
  </HStack>
  <HStack>
    <IconButton
      icon={<ChevronLeftIcon />}
      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
      isDisabled={currentPage === 1}
      size="sm"
      variant="ghost"
    />
    <Text fontSize="sm">
      Page {currentPage} of {totalPages}
    </Text>
    <IconButton
      icon={<ChevronRightIcon />}
      onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
      isDisabled={currentPage === totalPages}
      size="sm"
      variant="ghost"
    />
  </HStack>
</Flex>
          </VStack>
        </Container>
      </Box>
  
      <AddLeadForm
  isOpen={isAddLeadFormOpen}
  onClose={() => {
    setIsAddLeadFormOpen(false);
    setEditingLead(null);
  }}
  onSubmit={handleAddOrUpdateLead}
  currentList={currentList}
  editingLead={editingLead}
/>
  
<ImportLeadsModal
  isOpen={isImportModalOpen}
  onClose={() => {
    setIsImportModalOpen(false);
  }}
  onImportComplete={handleImportComplete}
  lists={lists}
  currentList={currentList}
/>
  
      {isRenaming && (
        <Modal isOpen={isRenaming} onClose={() => setIsRenaming(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rename List</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="Enter new list name"
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setIsRenaming(false)}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleRenameList}>
                Rename
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {importJobId && socket && (
  <ImportProgressBox 
    jobId={importJobId}
    onComplete={handleImportFinished}
    socket={socket}
  />
)}
    </Flex>
  );
}

export default Leads;