import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Link,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { BellIcon } from '@chakra-ui/icons';
import api, { getUserInfo } from '../api';

const Header = ({ onSignOut }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [disconnectedMailboxes, setDisconnectedMailboxes] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserInfo();
    fetchDisconnectedMailboxes();
    const interval = setInterval(fetchDisconnectedMailboxes, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  const fetchUserInfo = async () => {
    try {
      const data = await getUserInfo();
      setUserInfo(data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchDisconnectedMailboxes = async () => {
    try {
      const response = await api.get('/api/mailboxes/disconnected');
      setDisconnectedMailboxes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching disconnected mailboxes:', error);
      setDisconnectedMailboxes([]);
    }
  };

  const handleLogout = async () => {
    try {
      await api.post('/api/auth/logout');
      navigate('/login');
      if (onSignOut) {
        onSignOut();
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavLink = ({ to, children }) => {
    const isActive = location.pathname === to;
    return (
      <Link
        as={RouterLink}
        to={to}
        position="relative"
        fontSize="sm"
        color={isActive ? 'primary.600' : 'inherit'}
        _hover={{
          color: 'primary.500',
          _after: {
            transform: 'scaleX(1)',
          },
        }}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: '-2px',
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: 'primary.500',
          transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
          transition: 'transform 0.3s',
        }}
      >
        {children}
      </Link>
    );
  };

  const renderUserAvatar = () => {
    if (!userInfo) {
      return <Avatar size="sm" name="?" />;
    }

    if (userInfo.photoUrl) {
      return <Avatar size="sm" src={userInfo.photoUrl} />;
    }

    const initials = userInfo.email.split('@')[0].slice(0, 2).toUpperCase();
    return <Avatar size="sm" name={initials} bg="primary.500" color="white" />;
  };

  const handleReconnect = (mailboxId) => {
    navigate(`/settings?reconnect=${mailboxId}`);
  };

  return (
    <Box as="header" bg={useColorModeValue('white', 'gray.800')} boxShadow="sm" py={2}>
      <Flex maxW="7xl" mx="auto" px={4} align="center" justify="space-between">
        <Menu>
          <MenuButton as={Button} variant="unstyled">
            {renderUserAvatar()}
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to="/profile">Profile</MenuItem>
            <MenuItem as={RouterLink} to="/settings">Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </MenuList>
        </Menu>
        <Flex as="nav" align="center" justify="flex-end" flex={1} gap={6}>
          <NavLink to="/email-campaign">Email Campaign</NavLink>
          <NavLink to="/current-campaigns">Current Campaigns</NavLink>
          <NavLink to="/email-verifier">Email Verifier</NavLink>
          <NavLink to="/leads">Leads</NavLink>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Notifications"
              icon={<BellIcon />}
              variant="outline"
              colorScheme={disconnectedMailboxes.length > 0 ? 'red' : 'gray'}
            />
            <MenuList>
              {disconnectedMailboxes.length > 0 ? (
                disconnectedMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox._id} onClick={() => handleReconnect(mailbox._id)}>
                    <Text>{mailbox.email} is disconnected - Click to reconnect</Text>
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No disconnected mailboxes</MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default React.memo(Header);