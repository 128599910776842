import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Textarea,
  Text,
  Flex,
  Tooltip,
  Box,
  Select,
  FormControl,
  FormLabel,
  Switch,
  useToast
} from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';
import api from '../api';

const SignatureManager = ({ isOpen, onClose, onSave, campaign,activeVersion, activeEmailIndex }) => {
  console.log('SignatureManager received campaign:', campaign);
  console.log('SignatureManager received signatureSettings:', campaign.signatureSettings);

  const [selectedEmail, setSelectedEmail] = useState('');
  const [signatureSettings, setSignatureSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (campaign && campaign.fromEmails && campaign.fromEmails.length > 0) {
      const currentVersion = campaign.versions.find(v => v.name === activeVersion);
      if (currentVersion && currentVersion.emails[activeEmailIndex]) {
        const currentEmail = currentVersion.emails[activeEmailIndex];
        const emailToUse = currentEmail.from || campaign.fromEmails[0].email;
        setSelectedEmail(emailToUse);
      } else {
        setSelectedEmail(campaign.fromEmails[0].email);
      }
      initializeSignatures();
    }
  }, [campaign, activeVersion, activeEmailIndex]);

  const initializeSignatures = async () => {
    setIsLoading(true);
    const settings = {};
    for (const emailObj of campaign.fromEmails) {
      const email = emailObj.email || emailObj;
      const existingSettings = campaign.signatureSettings.find(s => s.email === email);
      if (existingSettings) {
        settings[email] = existingSettings;
      } else {
        try {
          const response = await api.get(`/api/mailboxes/signature/${email}`);
          settings[email] = {
            signature: response.data.signature || '',
            signatureType: response.data.signatureType || 'none',
            customSignature: response.data.signatureType === 'custom' ? response.data.signature : '',
            showSignature: response.data.showSignature !== undefined ? response.data.showSignature : true,
          };
        } catch (error) {
          console.error(`Error fetching signature for ${email}:`, error);
          settings[email] = {
            signature: emailObj.signature || '',
            signatureType: emailObj.signatureType || 'none',
            customSignature: emailObj.signatureType === 'custom' ? emailObj.signature : '',
            showSignature: emailObj.showSignature !== undefined ? emailObj.showSignature : true,
          };
          toast({
            title: "Error fetching signature",
            description: `Failed to fetch signature for ${email}. ${error.response?.data?.message || error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
    setSignatureSettings(settings);
    setIsLoading(false);
  };

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  useEffect(() => {
    console.log('Current signature settings:', signatureSettings);
  }, [signatureSettings]);

  const handleSignatureTypeChange = (e) => {
    const newType = e.target.value;
    setSignatureSettings(prev => ({
      ...prev,
      [selectedEmail]: {
        ...prev[selectedEmail],
        signatureType: newType,
        customSignature: newType === 'custom' ? prev[selectedEmail].signature : prev[selectedEmail].customSignature
      }
    }));
  };

  const handleCustomSignatureChange = (e) => {
    setSignatureSettings(prev => ({
      ...prev,
      [selectedEmail]: {
        ...prev[selectedEmail],
        customSignature: e.target.value,
        signature: e.target.value // Update the main signature field as well
      }
    }));
  };

  const handleShowSignatureChange = (e) => {
    setSignatureSettings(prev => ({
      ...prev,
      [selectedEmail]: {
        ...prev[selectedEmail],
        showSignature: e.target.checked
      }
    }));
  };

  const handleSave = () => {
    console.log('Saving signature settings:', signatureSettings);
    onSave(signatureSettings);
    onClose();
  };

  const renderSignature = (signature) => {
    return { __html: signature };
  };

  const isGoogleProvider = selectedEmail && 
  (campaign.fromEmails.find(e => e.email === selectedEmail)?.provider === 'gmail' || 
   campaign.fromEmails.find(e => e.email === selectedEmail)?.provider === 'google365');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            Manage Signature
            <Tooltip label="These settings are specific to this campaign and won't affect your mailbox settings.">
              <InfoIcon color="gray.400" />
            </Tooltip>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {isLoading ? (
              <Text>Loading signatures...</Text>
            ) : (
              <>
                {campaign.fromEmails.length > 1 && (
                  <FormControl>
                    <FormLabel>Select Email</FormLabel>
                    <Select onChange={handleEmailChange} value={selectedEmail}>
                      {campaign.fromEmails.map((email, index) => (
                        <option key={index} value={email.email || email}>
                          {email.email || email}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="use-signature" mb="0">
                    Use Signature
                  </FormLabel>
                  <Switch
                    id="use-signature"
                    isChecked={signatureSettings[selectedEmail]?.showSignature}
                    onChange={handleShowSignatureChange}
                  />
                </FormControl>

                {signatureSettings[selectedEmail]?.showSignature && (
                  <>
                    <FormControl>
                      <FormLabel fontWeight="bold">Signature Type</FormLabel>
                      <Select 
  value={signatureSettings[selectedEmail]?.signatureType} 
  onChange={handleSignatureTypeChange}
  bg="gray.100"
  size="lg"
>
  {isGoogleProvider && <option value="email">Use Email Signature</option>}
  <option value="custom">Use Custom Signature</option>
  <option value="none">No Signature</option>
</Select>
                    </FormControl>

                    {signatureSettings[selectedEmail]?.signatureType === 'email' && isGoogleProvider && (
                      <Box 
                        border="1px solid" 
                        borderColor="gray.200" 
                        borderRadius="md" 
                        p={2}
                        dangerouslySetInnerHTML={renderSignature(signatureSettings[selectedEmail]?.signature || 'No email signature found.')}
                      />
                    )}

                    {signatureSettings[selectedEmail]?.signatureType === 'custom' && (
                      <Textarea
                        value={signatureSettings[selectedEmail]?.customSignature || signatureSettings[selectedEmail]?.signature}
                        onChange={handleCustomSignatureChange}
                        placeholder="Enter your custom signature here"
                        rows={5}
                      />
                    )}

                    {signatureSettings[selectedEmail]?.signatureType === 'none' && (
                      <Text color="gray.500" fontStyle="italic">No signature will be added to your emails.</Text>
                    )}
                  </>
                )}
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave} isDisabled={isLoading}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignatureManager;