import React, { useState } from 'react';
import api from '../api';
import FieldMapping from './FieldMapping';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Select,
  Checkbox,
  VStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

const BulkVerificationModal = ({ file, onClose, onVerificationComplete, lists, selectedList, setSelectedList, handleBulkVerification, verificationHistory }) => {
  const [step, setStep] = useState('initial');
  const [importType, setImportType] = useState('');
  const [newListName, setNewListName] = useState('');
  const [hasAttributes, setHasAttributes] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [error, setError] = useState(null);
  const [verificationName, setVerificationName] = useState('');

  const checkDuplicateName = (name) => {
    return verificationHistory.some(item => item.name.toLowerCase() === name.toLowerCase());
  };

  const handleImportTypeSelect = (type) => {
    setError(null);
    if (!verificationName.trim()) {
      setError('Please enter a verification name');
      return;
    }
    if (checkDuplicateName(verificationName)) {
      setError('A verification with this name already exists. Please choose a different name.');
      return;
    }
    console.log('Import type selected:', type);
    setImportType(type);
    if (type === 'import') {
      setStep('importOptions');
    } else {
      handleFileUpload(type);
    }
  };

  const handleFileUpload = async (type) => {
    setError(null);
    if (checkDuplicateName(verificationName)) {
      setError('A verification with this name already exists. Please choose a different name.');
      return;
    }
    console.log('Starting file upload');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('importType', type.toLowerCase());
    formData.append('verificationName', verificationName);
  
    console.log('Import type being sent:', type.toLowerCase());
  
    if (type === 'import') {
      if (selectedList) {
        formData.append('listId', selectedList);
      } else if (newListName) {
        formData.append('newListName', newListName);
      }
    }
  
    try {
      if (type === 'import' && hasAttributes) {
        console.log('Importing with attributes, fetching CSV preview');
        const response = await api.post(`/api/email-verifier/csv-preview`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log('CSV preview response:', response.data);
        
        const csvDataForFieldMapping = {
          headers: response.data.headers,
          previewData: [response.data.headers, ...response.data.data],
          fullData: [response.data.headers, ...response.data.fullData],
          totalRows: response.data.totalRows,
          validLeadsCount: response.data.totalRows
        };
  
        console.log('Processed CSV data for FieldMapping:', csvDataForFieldMapping);
        
        setCsvData(csvDataForFieldMapping);
        setStep('fieldMapping');
      } else {
        console.log('Starting verification process directly');
        const { data, hasHeaders, emailColumnIndex, headers } = await parseCSV(file);
        
        let dataToVerify;
        if (type === 'downloadOnly' && !hasHeaders) {
          // For download-only without headers, we need to ensure the data is in the correct format
          dataToVerify = data.map(row => ({
            email: row[`column${emailColumnIndex}`],
            _shouldVerify: true
          }));
        } else {
          dataToVerify = data;
        }
  
        formData.append('dataToVerify', JSON.stringify(dataToVerify));
        formData.append('hasHeaders', hasHeaders);
        formData.append('emailColumnIndex', emailColumnIndex.toString());
        if (headers) {
          formData.append('headers', JSON.stringify(headers));
        }
        console.log('CSV data added to formData:', dataToVerify);
        
        const result = await handleBulkVerification(formData);
        console.log('Bulk verification result:', result);
        if (result && result.jobId) {
          onVerificationComplete(result);
          onClose();
        } else {
          setError('Failed to start verification job');
        }
      }
    } catch (error) {
      console.error('Error in handleFileUpload:', error);
      setError(error.response?.data?.error || error.message || 'Failed to upload file');
    }
  };
  
  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        const lines = csvText.split('\n').filter(line => line.trim() !== '');
        
        // Function to check if a value is likely an email
        const isLikelyEmail = (value) => value.includes('@') && value.includes('.');
  
        // Determine if the first row is likely a header
        const firstRow = lines[0].split(',').map(item => item.trim());
        const hasHeaders = !firstRow.some(isLikelyEmail);
  
        let headers;
        let data;
        let emailColumnIndex;
  
        if (hasHeaders) {
          headers = firstRow.map(header => header.toLowerCase());
          emailColumnIndex = headers.findIndex(header => 
            header === 'email' || 
            header.includes('email') || 
            header.includes('e-mail')
          );
          data = lines.slice(1);
        } else {
          // If no headers, use index as header and find email column
          headers = firstRow.map((_, index) => `column${index}`);
          emailColumnIndex = firstRow.findIndex(isLikelyEmail);
          data = lines;
        }
  
        // Process the data
        const processedData = data.map(line => {
          const values = line.split(',').map(value => value.trim());
          return headers.reduce((acc, header, index) => {
            acc[header] = values[index] || '';
            return acc;
          }, {});
        }).filter(row => {
          const email = row[`column${emailColumnIndex}`] || row.email || Object.values(row).find(isLikelyEmail);
          return email && isLikelyEmail(email);
        }).map(row => ({
          ...row,
          _shouldVerify: true
        }));
  
        resolve({ 
          data: processedData, 
          hasHeaders, 
          emailColumnIndex,
          headers: hasHeaders ? headers : null
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };


  return (
    <Modal 
      isOpen={true} 
      onClose={onClose} 
      size={step === 'fieldMapping' ? "6xl" : "md"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent 
        maxW={step === 'fieldMapping' ? "1200px" : "600px"}
        w={step === 'fieldMapping' ? "1200px" : "600px"}
      >
        <ModalHeader>Bulk Email Verification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}
  
          {step === 'initial' && (
            <VStack spacing={4} align="stretch">
              <Input
                value={verificationName}
                onChange={(e) => setVerificationName(e.target.value)}
                placeholder="Enter verification name"
              />
              <Text>Choose an option:</Text>
              <Button onClick={() => handleImportTypeSelect('import')} colorScheme="blue">
                Import into lead's database
              </Button>
              <Button onClick={() => handleImportTypeSelect('downloadOnly')} colorScheme="green">
  Only download results
</Button>
            </VStack>
          )}
  
          {step === 'importOptions' && importType === 'import' && (
            <VStack spacing={4} align="stretch">
              <Box>
                <Text mb={2}>Create new list:</Text>
                <Input
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                  placeholder="Enter new list name"
                />
              </Box>
              <Box>
                <Text mb={2}>Or select existing list:</Text>
                <Select
                  value={selectedList}
                  onChange={(e) => setSelectedList(e.target.value)}
                  placeholder="Select a list (optional)"
                >
                  {lists.map(list => (
                    <option key={list._id} value={list._id}>{list.name}</option>
                  ))}
                </Select>
              </Box>
              <Checkbox
                isChecked={hasAttributes}
                onChange={(e) => setHasAttributes(e.target.checked)}
              >
                CSV has attributes to add
              </Checkbox>
              <Button onClick={() => handleFileUpload(importType)} colorScheme="blue">
                Continue
              </Button>
            </VStack>
          )}
  
          {step === 'fieldMapping' && csvData && csvData.headers && csvData.headers.length > 0 && (
            <Box overflowX="auto">
              <FieldMapping
                csvData={csvData}
                onMappingComplete={async (mapping, data, emailColumnIndex) => {
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('mapping', JSON.stringify(mapping));
                  formData.append('importType', importType);
                  formData.append('verificationName', verificationName);
                  formData.append('emailColumnIndex', emailColumnIndex.toString());
                  
                  const dataToVerify = data.map(row => ({
                    ...row,
                    _shouldVerify: true
                  }));
                  formData.append('dataToVerify', JSON.stringify(dataToVerify));
                  
                  formData.append('mappedFields', JSON.stringify(Object.values(mapping)));
                  if (selectedList) {
                    formData.append('listId', selectedList);
                  } else if (newListName) {
                    formData.append('newListName', newListName);
                  }

                  try {
                    const result = await handleBulkVerification(formData);
                    if (result && result.jobId) {
                      onVerificationComplete(result);
                      onClose();
                    } else {
                      setError('Failed to start verification job');
                    }
                  } catch (error) {
                    console.error('Error in handleBulkVerification:', error);
                    setError(error.message || 'Failed to start verification job');
                  }
                }}
                onCancel={() => setStep('importOptions')}
                selectedList={selectedList}
                newListName={newListName}
                lists={lists}
              />
            </Box>
          )}
        </ModalBody>
  
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default BulkVerificationModal;