import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box, VStack, HStack, Text, Button, Flex, Menu, MenuButton, MenuList, MenuItem, IconButton, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
} from "@chakra-ui/react";
import { EditIcon, ChevronDownIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import TiptapEditor from './TiptapEditor';
import TiptapSubject from './TiptapSubject';
import EmailPreview from './EmailPreview';
import api from '../api';

const CampaignContent = ({ campaign: initialCampaign, setCampaign: setParentCampaign, id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const [localCampaign, setLocalCampaign] = useState(initialCampaign);
  const [activeVersion, setActiveVersion] = useState('A');
  const [activeEmailIndex, setActiveEmailIndex] = useState(0);
  const [subject, setSubject] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [currentFocusedEditor, setCurrentFocusedEditor] = useState('main');
  const [isEditing, setIsEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [followUpDays, setFollowUpDays] = useState(2);
  const [editingFollowUpIndex, setEditingFollowUpIndex] = useState(null);

  const activeVersionRef = useRef(activeVersion);
  const activeEmailIndexRef = useRef(activeEmailIndex);

  const updateURLAndRefsWithoutRender = useCallback((version, emailIndex) => {
    const url = new URL(window.location);
    url.searchParams.set('version', version);
    url.searchParams.set('email', emailIndex.toString());
    window.history.pushState({}, '', url);
    activeVersionRef.current = version;
    activeEmailIndexRef.current = emailIndex;
  }, []);

  useEffect(() => {
    const initializeContent = () => {
      if (localCampaign && localCampaign.versions && localCampaign.versions.length > 0) {
        const searchParams = new URLSearchParams(location.search);
        const versionParam = searchParams.get('version');
        const emailIndexParam = searchParams.get('email');
  
        const initialVersion = versionParam && localCampaign.versions.some(v => v.name === versionParam)
          ? versionParam
          : localCampaign.versions[0].name;
        const initialEmailIndex = emailIndexParam 
          ? parseInt(emailIndexParam, 10)
          : 0;
  
        setActiveVersion(initialVersion);
        setActiveEmailIndex(initialEmailIndex);
  
        const selectedVersion = localCampaign.versions.find(v => v.name === initialVersion);
        if (selectedVersion && selectedVersion.emails[initialEmailIndex]) {
          const selectedEmail = selectedVersion.emails[initialEmailIndex];
          setSubject(selectedEmail.subject || '');
          setEditorContent(selectedEmail.body || '');
        }
  
        setIsContentReady(true);
      }
    };
  
    initializeContent();
  }, [location.search]); // Remove localCampaign from dependencies

  const handleVersionChange = useCallback((versionName) => {
    setActiveVersion(versionName);
    setActiveEmailIndex(0);
    updateURLAndRefsWithoutRender(versionName, 0);

    const versionData = localCampaign.versions.find(v => v.name === versionName);
    if (versionData && versionData.emails.length > 0) {
      const firstEmail = versionData.emails[0];
      setSubject(firstEmail.subject || '');
      setEditorContent(firstEmail.body || '');
    }
  }, [localCampaign, updateURLAndRefsWithoutRender]);

  const handleEmailSelect = useCallback((index) => {
    setActiveEmailIndex(index);
    updateURLAndRefsWithoutRender(activeVersionRef.current, index);

    const emailData = localCampaign.versions.find(v => v.name === activeVersion)?.emails[index];
    if (emailData) {
      setSubject(emailData.subject || '');
      setEditorContent(emailData.body || '');
    }
  }, [activeVersion, localCampaign, updateURLAndRefsWithoutRender]);

  const handleContentChange = useCallback((newSubject, newContent) => {
    setSubject(newSubject);
    setEditorContent(newContent);
    setHasUnsavedChanges(true);

    setLocalCampaign(prev => ({
      ...prev,
      versions: prev.versions.map(v =>
        v.name === activeVersion
          ? {
              ...v,
              emails: v.emails.map((email, index) =>
                index === activeEmailIndex
                  ? { ...email, subject: newSubject, body: newContent }
                  : email
              )
            }
          : v
      )
    }));
  }, [activeVersion, activeEmailIndex]);

  const handleSaveChanges = useCallback(async () => {
    try {
      await api.updateCampaign(id, localCampaign);
      setParentCampaign(localCampaign);
      setHasUnsavedChanges(false);
      toast({
        title: "Changes saved",
        description: "Your changes have been saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      toast({
        title: "Error saving changes",
        description: "An error occurred while saving your changes. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [id, localCampaign, setParentCampaign, toast]);

  const addVersion = useCallback(() => {
    if (localCampaign.versions && localCampaign.versions.length < 5) {
      const newVersionName = String.fromCharCode(65 + localCampaign.versions.length);
      const newVersion = { name: newVersionName, emails: [{ day: 1, subject: '', body: '' }] };
      
      setLocalCampaign(prev => ({
        ...prev,
        versions: [...prev.versions, newVersion]
      }));
      setActiveVersion(newVersionName);
      setActiveEmailIndex(0);
      updateURLAndRefsWithoutRender(newVersionName, 0);
      setSubject('');
      setEditorContent('');
      setHasUnsavedChanges(true);
    } else {
      toast({
        title: "Maximum versions reached",
        description: "You can't add more than 5 versions.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [localCampaign, updateURLAndRefsWithoutRender, toast]);

  const addFollowUp = useCallback(() => {
    setIsFollowUpModalOpen(true);
  }, []);

  const handleAddFollowUp = useCallback(() => {
    const currentVersion = localCampaign.versions.find(v => v.name === activeVersion);
    if (currentVersion.emails.length < 5) {
      const lastEmail = currentVersion.emails[currentVersion.emails.length - 1];
      const newDay = lastEmail.day + followUpDays;
      const newEmailIndex = currentVersion.emails.length;
      
      setLocalCampaign(prev => ({
        ...prev,
        versions: prev.versions.map(v => 
          v.name === activeVersion
            ? { 
                ...v, 
                emails: [
                  ...v.emails, 
                  { 
                    day: newDay, 
                    subject: '', 
                    body: '', 
                    sendAfterDays: followUpDays 
                  }
                ] 
              }
            : v
        )
      }));
      setActiveEmailIndex(newEmailIndex);
      updateURLAndRefsWithoutRender(activeVersion, newEmailIndex);
      setSubject('');
      setEditorContent('');
      setIsFollowUpModalOpen(false);
      setHasUnsavedChanges(true);
    } else {
      toast({
        title: "Maximum follow-ups reached",
        description: "You can't add more than 4 follow-ups.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [localCampaign, activeVersion, followUpDays, updateURLAndRefsWithoutRender, toast]);

  const handleDeleteVariant = useCallback((variantName) => {
    if (variantName === 'A') {
      toast({
        title: "Cannot delete base variant",
        description: "The A variant cannot be deleted as it's the base email.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete variant ${variantName}?`);
    if (confirmDelete) {
      setLocalCampaign(prev => {
        const updatedVersions = prev.versions.filter(v => v.name !== variantName);
        const renamedVersions = updatedVersions.map((version, index) => ({
          ...version,
          name: String.fromCharCode(65 + index)
        }));

        if (variantName === activeVersion) {
          setActiveVersion('A');
          setActiveEmailIndex(0);
          const variantA = renamedVersions.find(v => v.name === 'A');
          if (variantA && variantA.emails.length > 0) {
            setSubject(variantA.emails[0].subject || '');
            setEditorContent(variantA.emails[0].body || '');
          }
        } else if (activeVersion > variantName) {
          const newActiveVersion = String.fromCharCode(activeVersion.charCodeAt(0) - 1);
          setActiveVersion(newActiveVersion);
        }

        setHasUnsavedChanges(true);
        return { ...prev, versions: renamedVersions };
      });

      toast({
        title: "Variant deleted",
        description: `Variant ${variantName} has been deleted and remaining variants have been renamed.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [activeVersion, toast]);

  const handleDeleteFollowUp = useCallback((index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this follow-up?");
    if (confirmDelete) {
      setLocalCampaign(prev => ({
        ...prev,
        versions: prev.versions.map(v =>
          v.name === activeVersion
            ? { ...v, emails: v.emails.filter((_, i) => i !== index) }
            : v
        )
      }));
      if (index === activeEmailIndex) {
        setActiveEmailIndex(Math.max(0, index - 1));
      } else if (index < activeEmailIndex) {
        setActiveEmailIndex(activeEmailIndex - 1);
      }
      setHasUnsavedChanges(true);
    }
  }, [activeVersion, activeEmailIndex]);

  const handleChangeFollowUpDay = useCallback((index) => {
    setEditingFollowUpIndex(index);
    setFollowUpDays(localCampaign.versions.find(v => v.name === activeVersion).emails[index].sendAfterDays);
    setIsFollowUpModalOpen(true);
  }, [localCampaign, activeVersion]);

  const handleUpdateFollowUp = useCallback(() => {
    setLocalCampaign(prev => ({
      ...prev,
      versions: prev.versions.map(v =>
        v.name === activeVersion
          ? {
              ...v,
              emails: v.emails.map((email, index) =>
                index === editingFollowUpIndex
                  ? { ...email, sendAfterDays: followUpDays }
                  : email
              )
            }
          : v
      )
    }));
    setIsFollowUpModalOpen(false);
    setEditingFollowUpIndex(null);
    setHasUnsavedChanges(true);
  }, [activeVersion, editingFollowUpIndex, followUpDays]);

  const renderVersions = useCallback(() => {
    if (!localCampaign || !localCampaign.versions || localCampaign.versions.length === 0) return null;
    
    return (
      <HStack spacing={1} mb={4} mt={3.5}>
        {localCampaign.versions.map((version) => (
          <Button
            key={version.name}
            size="md"
            variant="outline"
            width="100%"
            height="42px"
            onClick={() => handleVersionChange(version.name)}
            {...(activeVersion === version.name && {
              borderColor: 'blue.500',
              color: 'blue.500',
            })}
          >
            {version.name}
          </Button>
        ))}
      </HStack>
    );
  }, [localCampaign, activeVersion, handleVersionChange]);

  const renderEmails = useCallback(() => {
    if (!localCampaign || !localCampaign.versions) return null;
    
    const currentVersion = localCampaign.versions.find(v => v.name === activeVersion);
    if (!currentVersion || !currentVersion.emails) return null;
  
    const stripHtml = (html) => {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    };
  
    return (
      <VStack align="stretch" spacing={4}>
        {currentVersion.emails.map((email, index) => (
          <Box 
            key={index} 
            borderWidth={1} 
            borderRadius="md" 
            p={4} 
            position="relative"
            cursor="pointer"
            onClick={() => handleEmailSelect(index)}
            bg={index === activeEmailIndex ? "blue.50" : "white"}
            borderColor={index === activeEmailIndex ? "blue.500" : "gray.200"}
            _hover={{
              bg: index === activeEmailIndex ? "blue.100" : "gray.50",
              borderColor: "blue.300",
            }}
            transition="all 0.2s"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text>{activeVersion}</Text>
              {index > 0 && <Text>Follow up {index}</Text>}
              <Text fontWeight="bold">
                {index === 0 ? `Day ${email.day}` : `After ${email.sendAfterDays} days`}
              </Text>
            </Flex>
            <Text fontWeight="bold" mb={2} noOfLines={1}>
              {email.subject}
            </Text>
            <Text fontSize="sm" noOfLines={2}>
              {stripHtml(email.body || '')}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon />}
                variant="ghost"
                size="xs"
                position="absolute"
                bottom={1}
                right={1}
                aria-label="Email options"
                minWidth="20px"
                height="20px"
                padding={0}
              />
              <MenuList>
                {index === 0 ? (
                  activeVersion !== 'A' && (
                    <MenuItem onClick={() => handleDeleteVariant(currentVersion.name)}>
                      Delete variant
                    </MenuItem>
                  )
                ) : (
                  <>
                    <MenuItem onClick={() => handleChangeFollowUpDay(index)}>
                      Change follow-up day
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteFollowUp(index)}>
                      Delete follow-up
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Box>
        ))}
      </VStack>
    );
  }, [localCampaign, activeVersion, activeEmailIndex, handleEmailSelect, handleDeleteVariant, handleChangeFollowUpDay, handleDeleteFollowUp]);

  if (!isContentReady) {
    return <Box>Loading content...</Box>;
  }

  return (
    <Flex justifyContent="center">
      <VStack align="stretch" width="300px" mr={6}>
        <Flex width="100%">
          <Button
            variant="outline"
            height="43px"
            width="50%"
            borderColor="blue.500"
            color="blue.500"
            mr={1}
            onClick={addVersion}
            className="add-variant-button"
          >
            Add Variant
          </Button>
          <Button
            variant="outline"
            height="43px"
            width="50%"
            borderColor="blue.500"
            color="blue.500"
            ml={1}
            onClick={addFollowUp}
            className="add-follow-up-button"
          >
            Add Follow Up
          </Button>
        </Flex>
        {renderVersions()}
        {renderEmails()}
      </VStack>
      <VStack align="stretch" width="850px">
        <TiptapSubject
          value={subject}
          onChange={(newSubject) => handleContentChange(newSubject, editorContent)}
          placeholder={activeEmailIndex === 0 ? "Subject Line" : "Leave empty to reply to the previous email"}
          onFocus={() => setCurrentFocusedEditor('subject')}
          className="subject-input"
        />
        <Box 
          mb={4} 
          borderWidth="1px"
          mt="2px"
          borderRadius="md" 
          borderColor="gray.200"
          overflow="hidden"
          position="relative"
          transition="all 0.2s"
          _focusWithin={{
            borderColor: "blue.500",
            boxShadow: "none",
          }}
        >
          <TiptapEditor
            content={editorContent}
            onEditorUpdate={(newContent) => handleContentChange(subject, newContent)}
            currentFocusedEditor={currentFocusedEditor}
            setCurrentFocusedEditor={setCurrentFocusedEditor}
            placeholder="Compose your email..."
            signature={localCampaign.signatureSettings?.signature || ''}
            showSignature={localCampaign.signatureSettings?.showSignature}
            setIsEditing={setIsEditing}
          />
        </Box>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Button 
            onClick={handleSaveChanges}
            colorScheme="blue"
            isDisabled={!hasUnsavedChanges}
          >
            Save Changes
          </Button>
          <EmailPreview 
            campaign={localCampaign} 
            showSignature={localCampaign.signatureSettings?.showSignature} 
            signature={localCampaign.signatureSettings?.signature}
            buttonProps={{
              size: "md",
              height: "40px",
              minWidth: "40px"
            }}
          />
        </Flex>
      </VStack>

      {/* Follow-up Modal */}
      <Modal isOpen={isFollowUpModalOpen} onClose={() => {
        setIsFollowUpModalOpen(false);
        setEditingFollowUpIndex(null);
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingFollowUpIndex !== null ? 'Edit Follow-up Email' : 'Add Follow-up Email'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Days after no reply</FormLabel>
              <NumberInput
                value={followUpDays}
                onChange={(_, value) => setFollowUpDays(value)}
                min={1}
                max={30}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={editingFollowUpIndex !== null ? handleUpdateFollowUp : handleAddFollowUp}>
              {editingFollowUpIndex !== null ? 'Update Follow-up' : 'Add Follow-up'}
            </Button>
            <Button variant="ghost" onClick={() => {
              setIsFollowUpModalOpen(false);
              setEditingFollowUpIndex(null);
            }}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CampaignContent;