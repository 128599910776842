import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Text,
  Input,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import api from '../api';

export const defaultAttributes = [
  { name: 'First Name', example: 'Steve' },
  { name: 'Last Name', example: 'Jobs' },
  { name: 'Email', example: 'steve@apple.com' },
  { name: 'Company', example: 'Apple.com' },
  { name: 'Position', example: 'CEO' },
  { name: 'Website', example: 'Apple.com' },
  { name: 'LinkedIn URL', example: 'linkedin.com/in/steve-jobs' },
  { name: 'Company Industry', example: 'Technology' },
  { name: 'Company Size', example: '$352B' },
  { name: 'Twitter', example: 'x.com/stevejobs' },
  { name: 'Phone', example: '408-435-2195' },
  { name: 'Location', example: 'Silicon Valley' },
  { name: 'Country', example: 'United States' },
  { name: 'Social', example: 'facebook.com/steve-jobs' },
  { name: 'Domain Name', example: 'Apple.com' },
];

const AttributeModal = ({ isOpen, onClose, onInsert, focusedEditor }) => {
    const [customAttributes, setCustomAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [fallback, setFallback] = useState('');

  const resetState = () => {
    setSelectedAttribute(null);
    setFallback('');
  };

  useEffect(() => {
    if (isOpen) {
      console.log('AttributeModal opened, focusedEditor:', focusedEditor);
      fetchCustomAttributes();
      resetState();
    }
  }, [isOpen, focusedEditor]);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const fetchCustomAttributes = async () => {
    try {
      console.log('Fetching custom attributes...');
      const response = await api.get('/api/leads/custom-attributes');
      console.log('Custom attributes response:', response.data);
      
      if (Array.isArray(response.data)) {
        const processedAttributes = response.data.filter(attr => 
          attr.name && !attr.name.startsWith('$__')
        );
        console.log('Processed attributes:', processedAttributes);
        setCustomAttributes(processedAttributes);
      } else {
        console.error('Unexpected response format:', response.data);
        setCustomAttributes([]);
      }
    } catch (error) {
      console.error('Error fetching custom attributes:', error.response ? error.response.data : error.message);
      setCustomAttributes([]);
    }
  };

  const handleAttributeSelect = (attribute) => {
    setSelectedAttribute(attribute);
    setFallback('');
  };

 const handleInsert = () => {
  if (selectedAttribute && fallback) {
    onInsert(selectedAttribute.name, fallback, focusedEditor);
    handleClose();
  }
};

  const allAttributes = [...defaultAttributes, ...customAttributes];

  return (
<Modal isOpen={isOpen} onClose={handleClose} size="6xl" isCentered>
        <ModalOverlay />
      <ModalContent maxWidth="1000px" maxHeight="80vh" overflowY="auto">
        <ModalHeader>Insert Attribute</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>Inserting into: <strong>{focusedEditor === 'subject' ? 'Subject Line' : 'Main Editor'}</strong></Text>
          <SimpleGrid columns={6} spacing={4}>
            {allAttributes.map(attr => (
              <Box 
                key={attr.name}
                borderWidth={1}
                borderRadius="md"
                p={2}
                cursor="pointer"
                onClick={() => handleAttributeSelect(attr)}
                bg={selectedAttribute?.name === attr.name ? "blue.100" : "white"}
                _hover={{ bg: "blue.50" }}
              >
                <Text fontWeight="bold">{attr.name}</Text>
                <Text fontSize="sm" color="gray.600">
  {typeof attr.example === 'object' ? JSON.stringify(attr.example) : attr.example}
</Text>
              </Box>
            ))}
            </SimpleGrid>
          <VStack mt={6} align="stretch">
            <Text>Selected Attribute: <strong>{selectedAttribute?.name || 'None'}</strong></Text>
            <Input 
              placeholder="Type in a fallback if there is no lead data to display" 
              value={fallback} 
              onChange={(e) => setFallback(e.target.value)}
              isDisabled={!selectedAttribute}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleInsert} isDisabled={!selectedAttribute || !fallback}>
            Insert
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttributeModal;