import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Input,
  Select,
  Text,
  VStack,
  useToast,
  Badge,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
  HStack
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import api from '../api';

const defaultSendingDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  };

  const timezones = [
    { name: 'UTC-12:00 Baker Island', value: 'Etc/GMT+12' },
    { name: 'UTC-11:00 American Samoa', value: 'Pacific/Pago_Pago' },
    { name: 'UTC-10:00 Hawaii', value: 'Pacific/Honolulu' },
    { name: 'UTC-09:00 Alaska', value: 'America/Anchorage' },
    { name: 'UTC-08:00 Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
    { name: 'UTC-07:00 Mountain Time (US & Canada)', value: 'America/Denver' },
    { name: 'UTC-06:00 Central Time (US & Canada)', value: 'America/Chicago' },
    { name: 'UTC-05:00 Eastern Time (US & Canada)', value: 'America/New_York' },
    { name: 'UTC-04:00 Atlantic Time (Canada)', value: 'America/Halifax' },
    { name: 'UTC-03:00 Brazil, Buenos Aires', value: 'America/Sao_Paulo' },
    { name: 'UTC-02:00 Mid-Atlantic', value: 'Atlantic/South_Georgia' },
    { name: 'UTC-01:00 Azores', value: 'Atlantic/Azores' },
    { name: 'UTC+00:00 London, Dublin', value: 'Europe/London' },
    { name: 'UTC+01:00 Berlin, Paris, Rome', value: 'Europe/Paris' },
    { name: 'UTC+02:00 Helsinki, Kiev, Cairo', value: 'Europe/Kiev' },
    { name: 'UTC+03:00 Moscow, Baghdad', value: 'Europe/Moscow' },
    { name: 'UTC+04:00 Dubai, Baku', value: 'Asia/Dubai' },
    { name: 'UTC+05:00 Karachi, Tashkent', value: 'Asia/Karachi' },
    { name: 'UTC+05:30 Mumbai, Colombo', value: 'Asia/Kolkata' },
    { name: 'UTC+06:00 Dhaka, Almaty', value: 'Asia/Dhaka' },
    { name: 'UTC+07:00 Bangkok, Hanoi', value: 'Asia/Bangkok' },
    { name: 'UTC+08:00 Beijing, Singapore', value: 'Asia/Shanghai' },
    { name: 'UTC+09:00 Tokyo, Seoul', value: 'Asia/Tokyo' },
    { name: 'UTC+10:00 Sydney, Brisbane', value: 'Australia/Sydney' },
    { name: 'UTC+11:00 Solomon Islands', value: 'Pacific/Guadalcanal' },
    { name: 'UTC+12:00 Auckland, Fiji', value: 'Pacific/Auckland' },
  ];

  const CampaignSettings = ({ currentProfileId, onNewProfileCreated, onCloseModal, isInModal = false, initialData = null }) => {
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(currentProfileId || '');
  const [profileName, setProfileName] = useState('');
  const [sendingDays, setSendingDays] = useState({...defaultSendingDays});
  const [sendingWindow, setSendingWindow] = useState({ start: '09:00', end: '17:00' });
  const [timezone, setTimezone] = useState('America/New_York');
  const [dailyEmailCap, setDailyEmailCap] = useState(200);
  const [delayBetweenEmails, setDelayBetweenEmails] = useState(60);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openTracking, setOpenTracking] = useState(true);
  const [clickTracking, setClickTracking] = useState(true);

  const toast = useToast();

 

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

 
  const handleDeleteProfile = async () => {
    if (profileToDelete) {
      try {
        await api.deleteCampaignProfile(profileToDelete);
        toast({
          title: 'Profile deleted',
          description: 'The campaign profile has been successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchProfiles();
        onClose();
      } catch (error) {
        console.error('Error deleting profile:', error);
        toast({
          title: 'Error deleting profile',
          description: error.message || 'An unexpected error occurred',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  const fetchProfiles = async () => {
    try {
      const response = await api.getCampaignProfiles();
      setProfiles(response.data);
      if (response.data.length > 0) {
        setSelectedProfile(response.data[0]._id);
        loadProfile(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
      toast({
        title: 'Error fetching profiles',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loadProfile = useCallback((profile) => {
    setProfileName(profile.name);
    setSendingDays(profile.sendingDays || {...defaultSendingDays});
    setSendingWindow(profile.sendingWindow);
    setTimezone(profile.timezone || 'America/New_York');
    setDailyEmailCap(profile.dailyEmailCap);
    setDelayBetweenEmails(profile.delayBetweenEmails);
    setOpenTracking(profile.openTracking ?? true);
    setClickTracking(profile.clickTracking ?? true);
  }, []);

  useEffect(() => {
    const initializeProfile = async () => {
      await fetchProfiles();
      if (initialData) {
        setIsNewProfile(true);
        setSelectedProfile('new');
        setProfileName(initialData.name);
        setSendingDays(initialData.sendingDays);
        setSendingWindow(initialData.sendingWindow);
        setTimezone(initialData.timezone);
        setDailyEmailCap(initialData.dailyEmailCap);
        setDelayBetweenEmails(initialData.delayBetweenEmails);
      } else if (currentProfileId === 'new') {
        setIsNewProfile(true);
        setSelectedProfile('new');
        resetToDefaultValues();
      } else if (currentProfileId) {
        setIsNewProfile(false);
        setSelectedProfile(currentProfileId);
        const currentProfile = profiles.find(p => p._id === currentProfileId);
        if (currentProfile) {
          loadProfile(currentProfile);
        }
      }
    };
  
    initializeProfile();
  }, [currentProfileId, initialData]);

 
  const resetToDefaultValues = () => {
    setProfileName('');
    setSendingDays({...defaultSendingDays});
    setSendingWindow({ start: '09:00', end: '17:00' });
    setTimezone('America/New_York');
    setDailyEmailCap(200);
    setDelayBetweenEmails(60);
  };


  const handleProfileSelect = (e) => {
    const profileId = e.target.value;
    setSelectedProfile(profileId);
    if (profileId === 'new') {
      setIsNewProfile(true);
      resetToDefaultValues();
    } else {
      setIsNewProfile(false);
      const profile = profiles.find(p => p._id === profileId);
      if (profile) {
        loadProfile(profile);
      }
    }
  };
 const handleDayToggle = (day) => {
  setSendingDays(prev => ({
    ...prev,
    [day]: !prev[day]
  }));
};

CampaignSettings.defaultProps = {
  onNewProfileCreated: () => {},
  onCloseModal: () => {},
};

const handleSaveSettings = async () => {
  if (!profileName.trim()) {
    toast({
      title: 'Error',
      description: 'Please name your campaign profile',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  try {
    const profileData = {
      name: profileName,
      sendingDays,
      sendingWindow,
      timezone,
      dailyEmailCap,
      delayBetweenEmails,
      openTracking,
      clickTracking,
    };

    let savedProfile;

    if (isNewProfile) {
      const response = await api.createCampaignProfile(profileData);
      savedProfile = response.data;
      setIsNewProfile(false);
      setSelectedProfile(savedProfile._id);
    } else {
      const response = await api.updateCampaignProfile(selectedProfile, profileData);
      savedProfile = response.data;
    }

    // Update local state
    setProfiles(prevProfiles => {
      const updatedProfiles = isNewProfile 
        ? [...prevProfiles, savedProfile]
        : prevProfiles.map(p => p._id === savedProfile._id ? savedProfile : p);
      return updatedProfiles;
    });

    // Here's the key change: we're passing the full profile object to onNewProfileCreated
    if (onNewProfileCreated) {
      onNewProfileCreated(savedProfile);
    }

    toast({
      title: isNewProfile ? 'Profile created' : 'Settings saved',
      description: isNewProfile ? 'New profile has been created successfully.' : 'Your settings have been updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    if (onCloseModal) {
      onCloseModal();
    }
  } catch (error) {
    console.error('Error saving settings:', error);
    toast({
      title: 'Error saving settings',
      description: error.message || 'An unexpected error occurred',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
};

return (
  <>
    <Card>
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg">
            {isNewProfile ? (profileName || "New Profile") : "Campaign Settings"}
          </Heading>
          {!isInModal && (
            <Flex alignItems="center">
              {isNewProfile && (
                <Badge colorScheme="green" mr={2}>New Profile</Badge>
              )}
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete profile"
                variant="ghost"
                colorScheme="red"
                mr={2}
                isDisabled={isNewProfile}
                onClick={() => {
                  setProfileToDelete(selectedProfile);
                  onOpen();
                }}
              />
              <Select
                width="200px"
                value={isNewProfile ? "new" : selectedProfile}
                onChange={handleProfileSelect}
              >
                {isNewProfile ? (
                  <option value="new">New Profile</option>
                ) : (
                  <option value="" disabled>Select a profile</option>
                )}
                {profiles.map((profile) => (
                  <option key={profile._id} value={profile._id}>
                    {profile.name}
                  </option>
                ))}
                {!isNewProfile && <option value="new">Create New Profile</option>}
              </Select>
            </Flex>
          )}
        </Flex>
        <Text mt={2} color="gray.600">Fine-tune your campaign parameters</Text>
      </CardHeader>
      <CardBody>
        <VStack spacing={6} align="stretch">
          <Flex alignItems="center" justifyContent="space-between">
            <Box flex={1} mr={4}>
              <Text mb={2} fontWeight="medium">Profile Name</Text>
              <Input 
                placeholder="Profile Name" 
                value={profileName} 
                onChange={(e) => setProfileName(e.target.value)}
                size="md"
              />
            </Box>
            <HStack spacing={4}>
              <Box>
                <Text mb={2} fontWeight="medium">Open Tracking</Text>
                <Switch 
                  isChecked={openTracking} 
                  onChange={(e) => setOpenTracking(e.target.checked)}
                  size="lg"
                />
              </Box>
              <Box>
                <Text mb={2} fontWeight="medium">Click Tracking</Text>
                <Switch 
                  isChecked={clickTracking} 
                  onChange={(e) => setClickTracking(e.target.checked)}
                  size="lg"
                />
              </Box>
            </HStack>
          </Flex>

          <Box>
            <Text mb={2} fontWeight="medium">Sending Days</Text>
            <Flex justifyContent="space-between" gap={2}>
              {Object.entries(sendingDays).map(([day, isSelected]) => (
                <Button 
                  key={day} 
                  size="sm" 
                  variant={isSelected ? "solid" : "outline"}
                  colorScheme={isSelected ? "blue" : "gray"}
                  onClick={() => handleDayToggle(day)}
                  flex={1}
                >
                  {day.charAt(0).toUpperCase() + day.slice(1, 3)}
                </Button>
              ))}
            </Flex>
          </Box>
          
          <Flex justifyContent="space-between" gap={4}>
            <Box flex={1}>
              <Text mb={2} fontWeight="medium">Sending Window</Text>
              <Flex gap={2}>
                <Input
                  type="time"
                  value={sendingWindow.start}
                  onChange={(e) => setSendingWindow(prev => ({...prev, start: e.target.value}))}
                  size="md"
                />
                <Text alignSelf="center">to</Text>
                <Input
                  type="time"
                  value={sendingWindow.end}
                  onChange={(e) => setSendingWindow(prev => ({...prev, end: e.target.value}))}
                  size="md"
                />
              </Flex>
            </Box>
            <Box flex={1}>
              <Text mb={2} fontWeight="medium">Timezone</Text>
              <Select 
                value={timezone} 
                onChange={(e) => setTimezone(e.target.value)}
                size="md"
              >
                {timezones.map((tz) => (
                  <option key={tz.value} value={tz.value}>
                    {tz.name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          
          <Flex justifyContent="space-between" gap={4}>
            <Box flex={1}>
              <Text mb={2} fontWeight="medium">Daily Email Cap</Text>
              <Input 
                type="number" 
                value={dailyEmailCap} 
                onChange={(e) => setDailyEmailCap(e.target.value)} 
                size="md" 
              />
            </Box>
            <Box flex={1}>
              <Text mb={2} fontWeight="medium">Delay Between Emails (seconds)</Text>
              <Input 
                type="number" 
                value={delayBetweenEmails} 
                onChange={(e) => setDelayBetweenEmails(e.target.value)} 
                size="md" 
              />
            </Box>
          </Flex>

          <Flex justifyContent="flex-end">
            <Button 
              colorScheme="blue"
              onClick={handleSaveSettings}
            >
              {isNewProfile ? 'Create Profile' : 'Save Settings'}
            </Button>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this campaign profile? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleDeleteProfile}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
);
};

export default CampaignSettings;
export { defaultSendingDays };