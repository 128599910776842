import React, { useEffect, useState } from 'react';
import { Box, Progress, Text, VStack, CloseButton, useToast } from '@chakra-ui/react';

const ImportProgressBox = ({ jobId, onComplete, socket }) => {
  const [progress, setProgress] = useState(0);
  const [stats, setStats] = useState({
    processedLeads: 0,
    totalLeads: 0,
    newLeadsCount: 0,
    updatedLeadsCount: 0,
    skippedLeadsCount: 0
  });
  const [isVisible, setIsVisible] = useState(true);
  const toast = useToast();

  useEffect(() => {
    if (!socket) {
      console.log('Socket not available in ImportProgressBox');
      return;
    }
  
    console.log('Setting up listeners in ImportProgressBox');
    
    const handleProgress = (data) => {
      console.log('Import progress received in ImportProgressBox:', data);
      setProgress(data.progress || 0);
      setStats({
        processedLeads: data.processedLeads || 0,
        totalLeads: data.totalLeads || 0,
        newLeadsCount: data.newLeadsCount || 0,
        updatedLeadsCount: data.updatedLeadsCount || 0,
        skippedLeadsCount: data.skippedLeadsCount || 0
      });
    };
  
    const handleComplete = (data) => {
      console.log('Import complete received in ImportProgressBox:', data);
      setProgress(100);
      setStats({
        processedLeads: data.totalRows || 0,
        totalLeads: data.totalRows || 0,
        newLeadsCount: data.newLeadsCount || 0,
        updatedLeadsCount: data.updatedLeadsCount || 0,
        skippedLeadsCount: data.skippedLeadsCount || 0
      });
      onComplete(data);
      console.log('onComplete called from ImportProgressBox');
    };
    
    socket.on('importProgressUpdate', handleProgress);
    socket.on('importComplete', handleComplete);
    
    return () => {
      console.log('Cleaning up socket listeners in ImportProgressBox');
      socket.off('importProgressUpdate', handleProgress);
      socket.off('importComplete', handleComplete);
    };
  }, [jobId, onComplete, socket]);

  if (!isVisible) return null;

  return (
    <Box 
      position="fixed" 
      bottom="20px" 
      right="20px" 
      borderWidth={1} 
      borderRadius="lg" 
      p={4} 
      maxW="300px" 
      width="100%" 
      bg="white" 
      boxShadow="lg"
      zIndex="tooltip"
    >
      <CloseButton position="absolute" right="8px" top="8px" onClick={() => setIsVisible(false)} />
      <VStack spacing={4} align="stretch">
        <Text fontWeight="bold">Import Progress (Job ID: {jobId})</Text>
        <Progress value={progress} size="sm" colorScheme="blue" />
        <Text>Processed: {stats.processedLeads} / {stats.totalLeads}</Text>
        <Text>New Leads: {stats.newLeadsCount}</Text>
        <Text>Updated Leads: {stats.updatedLeadsCount}</Text>
        <Text>Skipped Leads: {stats.skippedLeadsCount}</Text>
      </VStack>
    </Box>
  );
};

export default ImportProgressBox;