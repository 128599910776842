// Toolbar.js
import React, { useState } from 'react';
import {
    HStack,
    IconButton,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Input,
    Button,
} from "@chakra-ui/react";
import { 
  FaBold, 
  FaItalic, 
  FaUnderline, 
  FaHighlighter, 
  FaLink, 
  FaImage, 
  FaList, 
  FaListOl,
  FaTable,
} from 'react-icons/fa';
import { BiBracket } from 'react-icons/bi';

const Toolbar = ({ editor, onInsertAttribute }) => {
  const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  if (!editor) {
    return null;
  }

  const handleInsertLink = () => {
    if (linkUrl) {
      // Check if the URL starts with http:// or https://
      const url = linkUrl.match(/^https?:\/\//) ? linkUrl : `https://${linkUrl}`;
      editor.chain().focus().setLink({ href: url }).run();
      setLinkUrl('');
      setIsLinkPopoverOpen(false);
    }
  };
  
  const handleInsertAttributeClick = (e) => {
    e.preventDefault();
    onInsertAttribute();
    e.target.blur();
  };

  return (
    <HStack spacing={1} p={2} borderTopWidth={1} position="absolute" bottom={0} left={0} right={0} bg="white">
      <IconButton 
        icon={<FaBold />} 
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        aria-label="Bold"
        variant="ghost"
      />
      <IconButton 
        icon={<FaItalic />} 
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
        aria-label="Italic"
        variant="ghost"
      />
      <IconButton 
        icon={<FaUnderline />} 
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive('underline')}
        aria-label="Underline"
        variant="ghost"
      />
      <IconButton 
        icon={<FaHighlighter />} 
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        isActive={editor.isActive('highlight')}
        aria-label="Highlight"
        variant="ghost"
      />
      <Popover
        isOpen={isLinkPopoverOpen}
        onClose={() => setIsLinkPopoverOpen(false)}
        placement="top"
      >
        <PopoverTrigger>
          <IconButton 
            icon={<FaLink />} 
            onClick={() => setIsLinkPopoverOpen(!isLinkPopoverOpen)}
            isActive={editor.isActive('link')}
            aria-label="Link"
            variant="ghost"
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <Input
              placeholder="Enter URL"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              mb={2}
            />
            <Button onClick={handleInsertLink}>Insert Link</Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <IconButton 
        icon={<BiBracket />} 
        onClick={handleInsertAttributeClick}
        aria-label="Insert Attribute"
        variant="ghost"
        onMouseDown={(e) => e.preventDefault()}
      />
      <IconButton 
        icon={<FaImage />} 
        onClick={() => {
          const url = window.prompt('Enter the image URL');
          if (url) {
            editor.chain().focus().setImage({ src: url }).run();
          }
        }}
        aria-label="Insert Image"
        variant="ghost"
      />
      <IconButton 
        icon={<FaList />} 
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
        aria-label="Bullet List"
        variant="ghost"
      />
      <IconButton 
        icon={<FaListOl />} 
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive('orderedList')}
        aria-label="Numbered List"
        variant="ghost"
      />
      <Select 
        width="120px"
        onChange={(e) => editor.chain().focus().setFontFamily(e.target.value).run()}
        variant="ghost"
      >
        <option value="Arial">Arial</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Times New Roman">Times New Roman</option>
        <option value="Courier New">Courier New</option>
        <option value="Verdana">Verdana</option>
        <option value="Georgia">Georgia</option>
      </Select>
      <NumberInput 
        width="80px"
        min={8} 
        max={96} 
        onChange={(valueString) => editor.chain().focus().setFontSize(valueString + 'px').run()}
        defaultValue={16}
        variant="ghost"
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  );
};

export default Toolbar;