// src/App.js
import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { initClient, signIn } from './googleApi';
import Header from './components/Header';
import Login from './components/Login';
import Leads from './components/Leads';
import NewImport from './components/NewImport';
import FieldMapping from './components/FieldMapping';
import EmailVerifier from './components/EmailVerifier';
import EmailCampaignDashboard from './components/EmailCampaignDashboard';
import CreateCampaign from './components/CreateCampaign';
import Settings from './components/Settings';
import ButtonTestPage from './components/ButtonTestPage';
import MicrosoftCallback from './components/MicrosoftCallback';
import { ChakraProvider, Spinner, Alert, AlertIcon, Box } from '@chakra-ui/react';
import theme from './theme/theme';
import api from './api';
import CurrentCampaigns from './components/CurrentCampaigns';

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const checkAuthStatus = useCallback(() => {
    return new Promise((resolve, reject) => {
      api.get('/api/auth/check', { withCredentials: true })
        .then(response => {
          console.log('Full auth check response:', response);
          console.log('Response data:', response.data);
          console.log('Authenticated status:', response.data.authenticated);
          if (response.data.authenticated) {
            setIsAuthenticated(true);
            setUserInfo(response.data.userInfo);
            console.log('User authenticated:', response.data.userInfo);
            resolve(true);
          } else {
            console.log('User not authenticated');
            setIsAuthenticated(false);
            setUserInfo(null);
            resolve(false);
          }
        })
        .catch(error => {
          console.error('Error checking auth status:', error);
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
          }
          setIsAuthenticated(false);
          setUserInfo(null);
          reject(error);
        });
    });
  }, []);

  useEffect(() => {
    console.log('Auth state changed:', { isAuthenticated, userInfo });
  }, [isAuthenticated, userInfo]);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await initClient();
        console.log('Google Identity Services initialized');
        await checkAuthStatus();
      } catch (error) {
        console.error('Error initializing Google Identity Services:', error);
        setError('Failed to initialize the application. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    initializeApp();
  }, [checkAuthStatus]);

  const handleSignIn = useCallback((userData) => {
    console.log('handleSignIn called with userData:', userData);
    setIsAuthenticated(true);
    setUserInfo(userData);
    navigate('/');
  }, [navigate]);

  const handleSignOut = useCallback(async () => {
    try {
      await api.post('/api/auth/logout');
    } catch (error) {
      console.error('Error during logout:', error);
    }
    setIsAuthenticated(false);
    setUserInfo(null);
    navigate('/login');
  }, [navigate]);

  if (isLoading) {
    return (
      <ChakraProvider>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Box>
      </ChakraProvider>
    );
  }

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Header userInfo={userInfo} onSignOut={handleSignOut} />
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <Box flex="1" as="main">
          <Routes>
            <Route 
              path="/login" 
              element={!isAuthenticated ? <Login onSignIn={handleSignIn} error={error} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/" 
              element={<PrivateRoute><EmailCampaignDashboard /></PrivateRoute>} 
            />
            <Route 
              path="/leads" 
              element={<PrivateRoute><Leads /></PrivateRoute>}  
            />
            <Route 
              path="/leads/new-import" 
              element={<PrivateRoute><NewImport /></PrivateRoute>}  
            />
            <Route 
              path="/new-import/field-mapping" 
              element={<PrivateRoute><FieldMapping /></PrivateRoute>} 
            />
            <Route 
              path="/email-verifier" 
              element={<PrivateRoute><EmailVerifier /></PrivateRoute>}  
            />
            <Route 
              path="/email-campaign" 
              element={<PrivateRoute><EmailCampaignDashboard /></PrivateRoute>}  
            />
            <Route 
              path="/email-campaign/:id" 
              element={<PrivateRoute><CreateCampaign /></PrivateRoute>}
            />
            <Route 
              path="/settings" 
              element={<PrivateRoute><Settings /></PrivateRoute>}
            />
            <Route 
              path="/button-test" 
              element={<PrivateRoute><ButtonTestPage /></PrivateRoute>}
            />
            <Route 
  path="/current-campaigns/:id" 
  element={<PrivateRoute><CurrentCampaigns /></PrivateRoute>}
/>
            <Route
              path="/auth/microsoft/callback"
              element={<MicrosoftCallback onSignIn={handleSignIn} />}
            />
          </Routes>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;