import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];
let refreshTimeout;
let tokenExpirationTime = null;
let refreshAttempts = 0;
const maxRefreshAttempts = 3;



const REFRESH_INTERVAL = 14 * 60 * 1000; // 14 minutes


const refreshToken = async () => {
  if (isRefreshing) return;
  isRefreshing = true;
  
  try {
    console.log('Sending refresh token request');
    const refreshResponse = await api.post('/api/auth/refresh');
    console.log('Refresh token response received:', refreshResponse.status);
    
    if (refreshResponse.status === 200) {
      console.log('Token refreshed successfully');
      updateTokenExpirationTime(refreshResponse.data.expiresAt);
      scheduleTokenRefresh();
      return true;
    } else {
      console.log('Unexpected response from refresh token endpoint:', refreshResponse.status);
      return false;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    return false;
  } finally {
    isRefreshing = false;
  }
};

const checkTokenExpiration = async () => {
  const currentTime = Date.now();
  const timeUntilExpiration = tokenExpirationTime - currentTime;

  if (timeUntilExpiration <= 0) {
    console.log('Token has expired, refreshing...');
    await refreshToken();
  } else if (timeUntilExpiration <= 5 * 60 * 1000) { // 5 minutes before expiration
    console.log('Token expiring soon, refreshing...');
    await refreshToken();
  } else {
    console.log('Token still valid, scheduling next check');
    scheduleTokenRefresh();
  }
};

api.getReauthUrl = async (mailboxId) => {
  try {
    const response = await api.get(`/api/mailboxes/reauth/${mailboxId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting re-auth URL:', error);
    throw error;
  }
};

const updateTokenExpirationTime = (expiresAt) => {
  tokenExpirationTime = new Date(expiresAt).getTime();
};

const scheduleTokenRefresh = () => {
  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
  }
  const timeUntilRefresh = (REFRESH_INTERVAL * 0.75); // 75% of the interval
  refreshTimeout = setTimeout(checkTokenExpiration, timeUntilRefresh);
};

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

const getUserInfo = async () => {
  try {
    const response = await api.get('/api/auth/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

api.getCampaignVersion = async (campaignId, versionName) => {
  try {
    const response = await api.get(`/api/email-campaigns/${campaignId}/version/${versionName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign version:', error);
    throw error;
  }
};

api.getSignature = async (email) => {
  if (!email || typeof email !== 'string') {
    console.log('Invalid email provided to fetch signature');
    return null;
  }
  try {
    const response = await api.get(`/api/mailboxes/signature/${email}`);
    return response.data.signature;
  } catch (error) {
    console.error('Error fetching signature:', error);
    return null;
  }
};

api.addRecipientsFromPaste = (campaignId, data) => api.post(`/api/email-campaigns/${campaignId}/recipients/paste`, data);


api.selectEmailAndSignature = (campaign) => {
  // Simple round-robin selection for demonstration
  const index = campaign.sentCount % campaign.fromEmails.length;
  return campaign.fromEmails[index];
};

api.getRawCampaign = async (campaignId) => {
  try {
    const url = `/api/email-campaigns/${campaignId}/raw`;
    console.log('Requesting raw campaign data URL:', url);
    const response = await api.get(url, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });
    console.log('Received raw campaign data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching raw campaign:', error);
    throw error;
  }
};

api.getCampaign = async (campaignId, bypassCache = false) => {
  try {
    const url = `/api/email-campaigns/${campaignId}`;
    const cacheBusterUrl = `${url}?t=${Date.now()}`;
    
    console.log('Requesting URL:', cacheBusterUrl);
    const response = await api.get(cacheBusterUrl, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });
    
    console.log('Raw API response:', response.data);
    
    const campaignData = {
      ...response.data,
      versions: response.data.versions.map(version => ({
        ...version,
        removed: typeof version.removed === 'boolean' ? version.removed : false
      })),
      cacheTimestamp: Date.now()
    };
    
    console.log('Processed campaign data:', campaignData);
    return campaignData;
  } catch (error) {
    console.error('Error fetching campaign:', error);
    throw error;
  }
};


api.getCampaignVersion = async (campaignId, versionName) => {
  try {
    const response = await api.get(`/api/email-campaigns/${campaignId}/version/${versionName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign version:', error);
    throw error;
  }
};

api.createCampaign = async (campaignData) => {
  try {
    const response = await api.post('/api/email-campaigns', campaignData);
    return response.data;
  } catch (error) {
    console.error('Error creating campaign:', error);
    throw error;
  }
};

api.updateCampaign = async (campaignId, campaignData) => {
  try {
    // Format the startDate if it exists
    if (campaignData.startDate) {
      campaignData = {
        ...campaignData,
        startDate: new Date(campaignData.startDate).toISOString()
      };
    }
    
    console.log('Sending campaign data:', campaignData);  // Added for debugging

    const response = await api.put(`/api/email-campaigns/${campaignId}`, campaignData);
    if (!response.data) {
      throw new Error('No data received from the server');
    }
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating campaign:', error);
    throw error;
  }
};

api.getCampaignCounts = async (campaignId) => {
  try {
    const response = await api.get(`/api/email-campaigns/${campaignId}/recipients`);
    return response.data;
  } catch (error) {
    console.error('Error in getCampaignCounts:', error);
    throw error;
  }
};

api.cacheCampaign = async (campaignId, campaignData) => {
  try {
    await api.post(`/api/email-campaigns/${campaignId}/cache`, campaignData);
  } catch (error) {
    console.error('Error caching campaign:', error);
  }
};

api.getCachedCampaign = async (campaignId) => {
  try {
    const response = await api.get(`/api/email-campaigns/${campaignId}/cache`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cached campaign:', error);
    return null;
  }
};

api.startCampaign = async (campaignId, startDate) => {
  try {
    console.log(`Starting campaign ${campaignId} with start date ${startDate}`);
    const response = await api.post(`/api/email-campaigns/${campaignId}/start`, { startDate });
    console.log('Campaign start response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error starting campaign:', error);
    console.error('Error response:', error.response);
    throw error;
  }
};

api.cacheCampaign = async (campaignId, campaignData) => {
  try {
    await api.post(`/api/email-campaigns/${campaignId}/cache`, campaignData);
  } catch (error) {
    console.error('Error caching campaign:', error);
  }
};

api.getMicrosoftLoginUrl = () => {
  console.log('Requesting Microsoft login URL');
  return api.get('/api/auth/microsoft/login');
};
api.microsoftCallback = (code) => {
  console.log('Sending Microsoft callback code to server');
  return api.post('/api/auth/microsoft/callback', { code });
};

api.microsoftLogin = async (code) => {
  console.log('Initiating Microsoft login process');
  try {
    const response = await api.post('/api/auth/microsoft/callback', { code });
    console.log('Microsoft login response received:', response.data);
    if (response.data && response.data.user) {
      return response.data.user;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Microsoft login error:', error);
    throw error;
  }
};

api.inititateMicrosoftLogin = async () => {
  try {
    const response = await api.get('/api/auth/microsoft/login');
    return response.data.url;
  } catch (error) {
    console.error('Error initiating Microsoft login:', error);
    throw error;
  }
};

api.addRecipientsFromCSV = async (campaignId, data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('listName', data.listName);
  formData.append('emailColumnIndex', data.emailColumnIndex);
  formData.append('mapping', JSON.stringify(data.mapping));
  formData.append('hasHeaders', data.hasHeaders);

  try {
    const response = await api.post(`/api/email-campaigns/${campaignId}/recipients/csv`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error adding recipients from CSV:', error);
    throw error;
  }
};

api.getImports = (campaignId) => api.get(`/api/email-campaigns/${campaignId}/imports`);

api.removeImport = (campaignId, importId) => 
  api.delete(`/api/email-campaigns/${campaignId}/imports/${importId}`);

api.restoreImport = (campaignId, importId) => 
  api.post(`/api/email-campaigns/${campaignId}/imports/${importId}/restore`);

// New methods for recipient management
api.getRecipients = (campaignId) => api.get(`/email-campaigns/${campaignId}/recipients`);
api.addRecipientsFromLists = (campaignId, data) => {
  console.log('Sending request to add recipients from lists:', { campaignId, data });
  return api.post(`/api/email-campaigns/${campaignId}/recipients`, data);
};
api.removeRecipient = (campaignId, recipientId) => api.delete(`/email-campaigns/${campaignId}/recipients/${recipientId}`);
api.bulkRemoveRecipients = (campaignId, data) => api.post(`/email-campaigns/${campaignId}/recipients/bulk-remove`, data);

// Campaign Profile methods
api.getCampaignProfiles = () => api.get('/api/campaign-profiles');
api.getCampaignProfile = (profileId) => {
  return api.get(`/api/campaign-profiles/${profileId}`, {
    headers: {
      'Accept': 'application/json'
    }
  });
};

api.createCampaignProfile = (profileData) => api.post('/api/campaign-profiles', profileData);

api.updateCampaignProfile = (profileId, profileData) => 
  api.put(`/api/campaign-profiles/${profileId}`, profileData);

// In your api.js file
api.deleteCampaignProfile = (profileId) => api.delete(`/api/campaign-profiles/${profileId}`);

api.getRandomLead = (campaignId) => {
  return api.get(`/api/email-campaigns/${campaignId}/random-lead`);
};

api.updateCampaignSignature = async (campaignId, email, signature) => {
  try {
    const response = await api.put(`/api/email-campaigns/${campaignId}/signature`, { email, signature });
    return response.data;
  } catch (error) {
    console.error('Error updating campaign signature:', error);
    throw error;
  }
};

api.getSignaturesForEmails = async (emails) => {
  if (!emails || emails.length === 0) {
    console.log('No emails provided to fetch signatures');
    return [];
  }
  try {
    const emailsString = emails.map(e => e.email || e).join(',');
    const response = await api.get(`/api/mailboxes/signatures?emails=${emailsString}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching signatures:', error);
    return [];
  }
};

api.connectSmtpImap = (mailboxData) => {
  console.log('Connecting SMTP/IMAP mailbox:', {...mailboxData, password: '******'});
  return api.post('/api/smtp-imap/connect/smtp-imap', mailboxData);
};
api.getMicrosoftMailboxUrl = () => api.get('/api/auth/microsoft/mailbox');
api.addMicrosoft365Mailbox = (code) => api.post('/api/mailboxes/microsoft365/callback', { code });

api.logout = async () => {
  try {
    await api.post('/api/auth/logout');
    window.dispatchEvent(new Event('logout'));
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

const initializeTokenCheck = () => {
  api.get('/api/auth/token-info')
    .then(response => {
      updateTokenExpirationTime(response.data.expiresAt);
      scheduleTokenRefresh();
    })
    .catch(error => {
      console.error('Failed to initialize token check:', error);
    });
};

initializeTokenCheck();



api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !error.config._retry) {
      if (refreshAttempts < maxRefreshAttempts) {
        error.config._retry = true;
        refreshAttempts++;
        try {
          await refreshToken();
          return api(error.config);
        } catch (refreshError) {
          await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, refreshAttempts)));
          return api(error.config);
        }
      } else {
        console.log('Max refresh attempts reached, logging out');
        window.dispatchEvent(new Event('logout'));
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

scheduleTokenRefresh();

api.manualRefresh = async () => {
  return refreshToken();
};

api.checkAuth = async () => {
  try {
    const response = await api.get('/api/auth/check');
    return response.data.authenticated;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return false;
  }
};

// Add a new version to a campaign
api.addVersion = async (campaignId, newVersion) => {
  try {
    const response = await api.post(`/api/email-campaigns/${campaignId}/versions`, newVersion);
    return response.data;
  } catch (error) {
    console.error('Error adding new version:', error);
    throw error;
  }
};

// Add a new follow-up email to a version
api.addFollowUp = async (campaignId, versionName, newFollowUp) => {
  try {
    const response = await api.post(`/api/email-campaigns/${campaignId}/versions/${versionName}/follow-ups`, newFollowUp);
    return response.data;
  } catch (error) {
    console.error('Error adding new follow-up:', error);
    throw error;
  }
};

// Delete a version from a campaign
api.deleteVersion = async (campaignId, versionName) => {
  try {
    const response = await api.delete(`/api/email-campaigns/${campaignId}/versions/${versionName}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting version:', error);
    throw error;
  }
};

// Delete a follow-up email from a version
api.deleteFollowUp = async (campaignId, versionName, followUpIndex) => {
  try {
    const response = await api.delete(`/api/email-campaigns/${campaignId}/versions/${versionName}/follow-ups/${followUpIndex}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting follow-up:', error);
    throw error;
  }
};

// Update a follow-up email in a version
api.updateFollowUp = async (campaignId, versionName, followUpIndex, updatedFollowUp) => {
  try {
    const response = await api.put(`/api/email-campaigns/${campaignId}/versions/${versionName}/follow-ups/${followUpIndex}`, updatedFollowUp);
    return response.data;
  } catch (error) {
    console.error('Error updating follow-up:', error);
    throw error;
  }
};

// Add this method to the api.js file
api.toggleVersion = async (campaignId, versionName, isActive, deactivationOption) => {
  try {
    const endpoint = `/api/email-campaigns/${campaignId}/toggle-version`;
    const body = { versionName, isActive, deactivationOption };
    
    console.log('Sending toggle version request:', body);
    const response = await api.put(endpoint, body);
    console.log('Toggle version response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error toggling version:', error);
    throw error;
  }
};
api.completeReauth = async (mailboxId, code) => {
  try {
    const response = await api.post(`/api/mailboxes/reauth/${mailboxId}/complete`, { code });
    return response.data;
  } catch (error) {
    console.error('Error completing re-auth:', error);
    throw error;
  }
};

export default api;
export { getUserInfo };

