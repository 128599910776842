import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  HStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="300px" maxWidth="300px">
        <ModalBody p={0}>
          <VStack spacing={4} p={4} bg={bgColor} borderWidth={1} borderColor={borderColor} borderRadius="md">
            <Text fontSize="lg" fontWeight="bold" textAlign="center">Start Campaign Confirmation</Text>
            <HStack spacing={3} width="100%">
              <Button variant="outline" flex={1} onClick={onClose}>Back</Button>
              <Button variant="primary" flex={1} onClick={onConfirm}>Activate</Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;