import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ListsSidebar from './ListsSidebar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

const NewImport = () => {
  const [files, setFiles] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState('');
  const [newListName, setNewListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showNewListForm, setShowNewListForm] = useState(false);
  const [totalLeadCount, setTotalLeadCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv', multiple: true });

  useEffect(() => {
    const fetchData = async () => {
      await fetchLists();
      const params = new URLSearchParams(location.search);
      const preSelectedList = params.get('preSelectedList');
      if (preSelectedList && preSelectedList !== 'all') {
        setSelectedList(preSelectedList);
      }
      // Fetch total lead count
      try {
        const response = await axios.get(`${API_BASE_URL}/api/lists/counts`);
        setTotalLeadCount(response.data.all || 0);
      } catch (error) {
        console.error('Error fetching total lead count:', error);
      }
    };
    fetchData();
  }, [location]);

  const fetchLists = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/lists`);
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  };

  const handleCreateList = async (name) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/lists`, { name });
      setLists([...lists, response.data]);
      setSelectedList(response.data._id);
      setNewListName('');
      setShowNewListForm(false);
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleImport = async () => {
    if (files.length === 0 || (!selectedList && !newListName)) return;
  
    setIsLoading(true);
    setProgress(0);
  
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      if (selectedList) {
        formData.append('listId', selectedList);
      } else if (newListName) {
        formData.append('listName', newListName);
      }
  
      const response = await axios.post(`${API_BASE_URL}/api/leads/upload-csv`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
  
      setIsLoading(false);
      navigate('/new-import/field-mapping', { state: { uploadedFiles: response.data.uploadedFiles, selectedList, newListName } });
    } catch (error) {
      console.error('Error uploading files:', error);
      setIsLoading(false);
    }
  };

  const handleListClick = (listId) => {
    setSelectedList(listId);
    if (listId === 'all') {
      navigate('/leads');
    } else {
      navigate(`/leads?list=${listId}`);
    }
  };

  return (
    <div className="flex">
      <ListsSidebar 
        currentList={selectedList} 
        onListClick={handleListClick}
        totalLeadCount={totalLeadCount}
        onCreateList={() => setShowNewListForm(true)}
        lists={lists}  // Make sure to pass the lists prop
      />
      <div className="flex-1 p-4">
        <h1 className="text-2xl font-bold mb-4">Import Leads</h1>
        <div className="max-w-2xl mx-auto">
          <select
            value={selectedList}
            onChange={(e) => {
              if (e.target.value === 'new') {
                setShowNewListForm(true);
                setSelectedList('');
              } else {
                setShowNewListForm(false);
                setSelectedList(e.target.value);
              }
            }}
            className="border p-2 rounded w-full mb-4"
          >
            <option value="">Select a destination list</option>
            {lists.map((list) => (
              <option key={list._id} value={list._id}>
                {list.name}
              </option>
            ))}
            <option value="new">Create a new list</option>
          </select>
          
          {showNewListForm && (
            <div className="mb-4">
              <input
                type="text"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="New list name"
                className="border p-2 rounded mr-2"
              />
              <button 
                onClick={() => handleCreateList(newListName)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Create List
              </button>
            </div>
          )}
          
          <div {...getRootProps()} className="border-2 border-dashed p-8 mb-4 text-center">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the CSV files here ...</p>
            ) : (
              <p>Drag 'n' drop CSV files here, or click to select files</p>
            )}
          </div>
          
          {files.length > 0 && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">Files to be imported:</h2>
              <ul>
                {files.map((file, index) => (
                  <li key={index} className="flex justify-between items-center mb-2">
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          <button
            onClick={handleImport}
            disabled={files.length === 0 || (!selectedList && !newListName) || isLoading}
            className="bg-green-500 text-white px-4 py-2 rounded disabled:bg-gray-400"
          >
            Import Leads
          </button>
          
          {isLoading && (
            <div className="mt-4">
              <div className="w-full bg-gray-200 rounded">
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded"
                  style={{ width: `${progress}%` }}
                >
                  {Math.round(progress)}%
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewImport;