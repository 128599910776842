import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../api';
import FieldMapping from './FieldMapping';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
  Box,
  Text,
  VStack,
  HStack,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

const ImportLeadsModal = ({ isOpen, onClose, onImportComplete, lists, currentList }) => {
  const [files, setFiles] = useState([]);
  const [selectedList, setSelectedList] = useState(currentList);
  const [newListName, setNewListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showNewListForm, setShowNewListForm] = useState(false);
  const [showFieldMapping, setShowFieldMapping] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
      setSelectedList(currentList);
      setNewListName('');
      setShowNewListForm(false);
      setShowFieldMapping(false);
      setCsvData(null);
    }
  }, [isOpen, currentList]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv', multiple: false });

  const handleImport = async () => {
    if (files.length === 0) return;
  
    setIsLoading(true);
  
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      
      const response = await api.post(`/api/leads/csv-preview`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      console.log('CSV preview response:', response.data);
  
      const processedData = {
        headers: response.data.headers,
        previewData: response.data.previewData,
        fullData: response.data.fullData,
        rowCount: response.data.rowCount,
        hasHeaders: response.data.hasHeaders,
        totalRows: response.data.validLeadsCount
      };
  
      console.log('Processed CSV Data:', processedData);
  
      setCsvData(processedData);
      setShowFieldMapping(true);
    } catch (error) {
      console.error('Error processing CSV:', error);
      toast({
        title: "Error processing CSV",
        description: "Please check the file and try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMappingComplete = async (mapping, dataToSend, emailColumnIndex, headers) => {
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('listId', selectedList === 'all' ? 'all' : selectedList);
      formData.append('newListName', showNewListForm ? newListName : null);
      formData.append('emailColumnIndex', emailColumnIndex);
      formData.append('mapping', JSON.stringify(mapping));
      formData.append('totalRows', csvData.totalRows);
      formData.append('hasHeaders', csvData.hasHeaders);
      formData.append('headers', JSON.stringify(headers));  // Add this line
      console.log('Mapping before sending to server:', mapping);
      console.log('Email column index:', emailColumnIndex);
      
      const response = await api.post(`/api/leads/import-mapped-csv`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      
      console.log('Import job created response:', response.data);
  
      if (response.data.jobId) {
        toast({
          title: "Import Started",
          description: `Your leads are being imported. You can track the progress in the corner of the screen.`,
          status: "info",
          duration: 5000,
          isClosable: true,
        });
  
        onImportComplete(response.data.jobId);
        onClose();
      } else {
        throw new Error('No job ID returned from server');
      }
    } catch (error) {
      console.error('Error importing mapped CSV:', error);
      console.error('Error response:', error.response?.data);
      toast({
        title: "Error importing leads",
        description: error.response?.data?.message || "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="6xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="1200px">
        <ModalHeader>Import Leads</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display={showFieldMapping ? 'none' : 'block'}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Destination list:</FormLabel>
                <Select
                  value={selectedList}
                  onChange={(e) => {
                    if (e.target.value === 'new') {
                      setShowNewListForm(true);
                      setSelectedList('');
                    } else {
                      setShowNewListForm(false);
                      setSelectedList(e.target.value);
                    }
                  }}
                >
                  <option value="all">All Leads (No specific list)</option>
                  {lists.map((list) => (
                    <option key={list._id} value={list._id}>
                      {list.name}
                    </option>
                  ))}
                  <option value="new">Create a new list</option>
                </Select>
              </FormControl>
              
              {showNewListForm && (
                <Input
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                  placeholder="New list name"
                />
              )}
              
              {selectedList === 'all' && (
                <Text color="blue.600">
                  Note: Leads will be saved to your total database without being attached to a specific list.
                </Text>
              )}
              
              <Box {...getRootProps()} borderWidth={2} borderStyle="dashed" p={4} textAlign="center">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Text>Drop the CSV file here ...</Text>
                ) : (
                  <Text>Drag 'n' drop a CSV file here, or click to select a file</Text>
                )}
              </Box>
              
              {files.length > 0 && (
                <Box>
                  <Text fontWeight="semibold" mb={2}>File to be imported:</Text>
                  <Text>{files[0].name}</Text>
                </Box>
              )}
            </VStack>
          </Box>
          
          <Box display={showFieldMapping ? 'block' : 'none'}>
            {csvData && (
              <FieldMapping 
                csvData={csvData} 
                onMappingComplete={handleMappingComplete} 
                onCancel={() => setShowFieldMapping(false)}
                selectedList={selectedList}
                newListName={newListName}
                lists={lists}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          {!showFieldMapping ? (
            <HStack spacing={2}>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={handleImport}
                isDisabled={files.length === 0 || isLoading}
                isLoading={isLoading}
              >
                Import Leads
              </Button>
            </HStack>
          ) : (
            <Button variant="ghost" onClick={() => setShowFieldMapping(false)}>
              Back to File Selection
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ImportLeadsModal;