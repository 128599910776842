import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  InputGroup,
  InputLeftElement,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Switch,
  Checkbox,
  Stack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { CiFilter } from "react-icons/ci";
import { GoPeople } from "react-icons/go";
import { FaPaperPlane, FaCommentDots, FaUserSlash, FaChartLine } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import api from '../api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

const EmailCampaignDashboard = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [totalSent, setTotalSent] = useState(0);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [avgOpenRate, setAvgOpenRate] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [newCampaign, setNewCampaign] = useState({ name: '', fromEmails: [] });
  const [availableEmails, setAvailableEmails] = useState([]);
  const [isInboxRotation, setIsInboxRotation] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const CAMPAIGNS_PER_PAGE = 6;

  useEffect(() => {
    fetchCampaigns();
    fetchAvailableEmails();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await api.get('/api/email-campaigns');
      setCampaigns(response.data);
      calculateStats(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchAvailableEmails = async () => {
    try {
      const response = await api.get('/api/email-campaigns/connected-emails');
      setAvailableEmails(response.data);
    } catch (error) {
      console.error('Error fetching available emails:', error);
    }
  };

  const calculateStats = (campaignsData) => {
    const sent = campaignsData.reduce((sum, campaign) => sum + campaign.stats.sent, 0);
    const recipients = campaignsData.reduce((sum, campaign) => sum + campaign.recipientCount, 0);
    const openRate = campaignsData.reduce((sum, campaign) => sum + campaign.openRate, 0) / campaignsData.length || 0;

    setTotalSent(sent);
    setTotalRecipients(recipients);
    setAvgOpenRate(openRate);
  };

  const handleNewCampaign = () => {
    setNewCampaign({ name: '', fromEmails: [] });
    setIsInboxRotation(false);
    onOpen();
  };

  const handleCreateCampaign = async () => {
    try {
      if (!newCampaign.name.trim()) {
        toast({
          title: "Campaign name is required",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (newCampaign.fromEmails.length === 0) {
        toast({
          title: "Please select at least one email address",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Fetch mailbox IDs for selected emails
      const mailboxData = await Promise.all(newCampaign.fromEmails.map(async (email) => {
        const response = await api.get(`/api/mailboxes/by-email/${email}`);
        return {
          mailbox: response.data._id,
          email: email
        };
      }));

      const campaignData = {
        ...newCampaign,
        fromEmails: mailboxData
      };

      const response = await api.post('/api/email-campaigns', campaignData);
      setCampaigns([...campaigns, response.data]);
      onClose();
      navigate(`/email-campaign/${response.data._id}`);
      toast({
        title: "Campaign created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating campaign:', error);
      let errorMessage = "An unexpected error occurred";
      if (error.response) {
        if (error.response.status === 400) {
          errorMessage = error.response.data.error || "Bad request";
        } else if (error.response.status === 500) {
          errorMessage = "Server error. Please try again later.";
        }
      }
      toast({
        title: "Error creating campaign",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEmailSelection = (email) => {
    setNewCampaign(prev => {
      const updatedEmails = prev.fromEmails.includes(email)
        ? prev.fromEmails.filter(e => e !== email)
        : [...prev.fromEmails, email];
      return { ...prev, fromEmails: updatedEmails };
    });
  };

  const filteredCampaigns = campaigns.filter(campaign => 
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (statusFilter === 'all' || campaign.status === statusFilter)
  ).sort((a, b) => {
    if (a.status === 'scheduled' && b.status !== 'scheduled') return -1;
    if (a.status !== 'scheduled' && b.status === 'scheduled') return 1;
    return 0;
  });

  const paginatedCampaigns = filteredCampaigns.slice(
    (currentPage - 1) * CAMPAIGNS_PER_PAGE,
    currentPage * CAMPAIGNS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredCampaigns.length / CAMPAIGNS_PER_PAGE);

  const renderStatusBadge = (status) => {
    return (
      <Center>
        <Badge
          variant="subtle"
          bg="transparent"
          border="1px solid"
          borderColor={
            status === 'active' ? "primary.500" :
            status === 'completed' ? "green.500" :
            status === 'paused' ? "yellow.500" :
            status === 'scheduled' ? "blue.500" :
            "gray.500"
          }
          color={
            status === 'active' ? "primary.500" :
            status === 'completed' ? "green.500" :
            status === 'paused' ? "yellow.500" :
            status === 'scheduled' ? "blue.500" :
            "gray.500"
          }
          px={2}
          py={1}
          borderRadius="0"
          textTransform="capitalize"
        >
          {status}
        </Badge>
      </Center>
    );
  };

  const renderTableCell = (value, IconComponent, color) => {
    if (value === 0 || value === null || value === undefined) {
      return <Text textAlign="center">-</Text>;
    }
    return (
      <HStack spacing={2} justifyContent="center">
        <IconComponent style={{ color: `var(--chakra-colors-${color.replace('.', '-')})` }} />
        <Text>{value}</Text>
      </HStack>
    );
  };

  return (
    <Box minH="100vh">
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Flex justify="space-between" align="center">
            <Heading as="h1" size="lg" color="gray.800">Email Campaign Dashboard</Heading>
            <Button 
              leftIcon={<AddIcon />} 
              colorScheme="primary"
              onClick={handleNewCampaign}
            >
              New Campaign
            </Button>
          </Flex>
          
          <Flex justify="space-between" wrap="wrap" gap={4}>
            <StatCard title="Active Campaigns" value={campaigns.filter(c => c.status === 'active').length} />
            <StatCard title="Total Recipients" value={totalRecipients} />
            <StatCard title="Avg. Open Rate" value={`${avgOpenRate.toFixed(1)}%`} />
            <StatCard title="Emails Sent" value={totalSent} />
          </Flex>
          
          <Box borderWidth={1} borderRadius="lg" overflow="hidden">
            <Box p={6}>
              <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">Active Campaigns</Heading>
                <HStack>
                  <Menu>
                    <MenuButton as={IconButton} icon={<CiFilter />} variant="outline" />
                    <MenuList>
                      <MenuItem onClick={() => setStatusFilter('all')}>All Statuses</MenuItem>
                      <MenuItem onClick={() => setStatusFilter('active')}>Active</MenuItem>
                      <MenuItem onClick={() => setStatusFilter('scheduled')}>Scheduled</MenuItem>
                      <MenuItem onClick={() => setStatusFilter('draft')}>Draft</MenuItem>
                      <MenuItem onClick={() => setStatusFilter('paused')}>Paused</MenuItem>
                      <MenuItem onClick={() => setStatusFilter('completed')}>Completed</MenuItem>
                    </MenuList>
                  </Menu>
                  <InputGroup w="200px">
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search campaigns"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                </HStack>
              </Flex>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="250px">Name</Th>
                    <Th textAlign="center">Status</Th>
                    <Th textAlign="center">Recipients</Th>
                    <Th textAlign="center">Sent</Th>
                    <Th textAlign="center">Replied</Th>
                    <Th textAlign="center">Unsubscribed</Th>
                    <Th textAlign="center">Open Rate</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedCampaigns.map((campaign) => (
                    <Tr 
                      key={campaign._id} 
                      onClick={() => navigate(campaign.status === 'draft' ? `/email-campaign/${campaign._id}` : `/current-campaigns/${campaign._id}`)}
                      cursor="pointer"
                      _hover={{ bg: "gray.50" }}
                    >
                      <Td>
                        <Tooltip label={campaign.name} placement="top-start">
                          <Text isTruncated maxWidth="250px">{campaign.name}</Text>
                        </Tooltip>
                      </Td>
                      <Td>{renderStatusBadge(campaign.status)}</Td>
                      <Td>{renderTableCell(campaign.recipientCount, GoPeople, "primary.500")}</Td>
                      <Td>{renderTableCell(campaign.stats.sent, FaPaperPlane, "green.500")}</Td>
                      <Td>{renderTableCell(campaign.stats.replied, FaCommentDots, "blue.500")}</Td>
                      <Td>{renderTableCell(campaign.stats.unsubscribed, FaUserSlash, "red.500")}</Td>
                      <Td>
                        {campaign.tracking?.opens ? 
                          renderTableCell(campaign.openRate ? `${campaign.openRate.toFixed(1)}%` : '-', FaChartLine, "orange.500") :
                          <Text textAlign="center" color="red.500">X</Text>
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          
          <Flex justifyContent="flex-end" mt={4}>
            <HStack>
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                isDisabled={currentPage === 1}
              />
              <Text>
                Page {currentPage} of {totalPages}
              </Text>
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                isDisabled={currentPage === totalPages}
              />
            </HStack>
          </Flex>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Campaign Name</FormLabel>
              <Input 
                value={newCampaign.name} 
                onChange={(e) => setNewCampaign({...newCampaign, name: e.target.value})}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Send from</FormLabel>
              <Select 
                value={newCampaign.fromEmails[0] || ''}
                onChange={(e) => setNewCampaign({...newCampaign, fromEmails: [e.target.value]})}
                isDisabled={isInboxRotation}
              >
                <option value="">Select an email</option>
                {availableEmails.map(email => (
                  <option key={email} value={email}>{email}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl display="flex" alignItems="center" mb={4}>
              <FormLabel htmlFor="inbox-rotation" mb="0">
                Inbox rotation?
              </FormLabel>
              <Switch 
                id="inbox-rotation" 
                isChecked={isInboxRotation}
                onChange={(e) => setIsInboxRotation(e.target.checked)}
              />
            </FormControl>
            {isInboxRotation && (
              <FormControl>
                <FormLabel>Select emails for rotation</FormLabel>
                <Stack spacing={2}>
                  {availableEmails.map(email => (
                    <Checkbox 
                      key={email} 
                      isChecked={newCampaign.fromEmails.includes(email)}
                      onChange={() => handleEmailSelection(email)}
                    >
                      {email}
                    </Checkbox>
                  ))}
                </Stack>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateCampaign}>
              Create
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const StatCard = ({ title, value }) => (
  <Box borderWidth={1} borderRadius="lg" overflow="hidden" w={{ base: "100%", md: "23%" }}>
    <Box p={4}>
      <Stat>
        <StatLabel color="gray.500">{title}</StatLabel>
        <StatNumber fontSize="2xl" fontWeight="bold" color="gray.800">{value}</StatNumber>
      </Stat>
    </Box>
  </Box>
);

export default EmailCampaignDashboard;