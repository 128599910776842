import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api';

const MicrosoftCallback = ({ onSignIn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const code = new URLSearchParams(location.search).get('code');
      console.log('Microsoft callback received. Code:', code ? 'present' : 'not found');
      
      if (code) {
        try {
          console.log('Attempting to process Microsoft callback');
          const response = await api.microsoftLogin(code);
          console.log('Microsoft login processed successfully:', response);
          
          if (response && response.id) {
            console.log('User data received:', response);
            if (onSignIn) {
              console.log('Calling onSignIn with user data');
              onSignIn(response);
            } else {
              console.error('onSignIn function is not defined');
            }
            console.log('Navigating to home page');
            navigate('/');
          } else {
            console.error('Invalid response format:', response);
            throw new Error('Invalid response format');
          }
        } catch (error) {
          console.error('Error processing Microsoft callback:', error);
          navigate('/login', { state: { error: 'Authentication failed: ' + error.message } });
        }
      } else {
        console.error('No authorization code received');
        navigate('/login', { state: { error: 'No authorization code received' } });
      }
    };

    handleCallback();
  }, [location, navigate, onSignIn]);

  return <div>Processing Microsoft login... Please wait.</div>;
};

export default MicrosoftCallback;