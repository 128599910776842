// TiptapEditor.js
import React, { useEffect, useCallback, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Highlight from '@tiptap/extension-highlight';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import { Box, Popover, PopoverTrigger, PopoverContent, PopoverBody, Input, Button, HStack } from "@chakra-ui/react";
import Toolbar from './Toolbar';
import AttributeModal from './AttributeModal';
import Placeholder from '@tiptap/extension-placeholder'
import LinkExtension from '@tiptap/extension-link';

const TiptapEditor = ({ 
    content, 
    onEditorUpdate, 
    currentFocusedEditor,
    setCurrentFocusedEditor, 
    onInsertAttribute,
    placeholder,
    signature, // New prop for the signature
    showSignature,
    setIsEditing 
  }) => {
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false);
  const [linkPopover, setLinkPopover] = useState({ isOpen: false, url: '', text: '', position: null });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      LinkExtension.configure({
        openOnClick: false,
        HTMLAttributes: {
          style: 'color: blue; text-decoration: underline; cursor: pointer;',
        },
      }),
      Image,
      Highlight,
      FontFamily,
      TextStyle,
      Placeholder.configure({
        placeholder: placeholder,
        showOnlyWhenEditable: true,
      })
    ],
    content: content,
    onUpdate: ({ editor }) => {
      onEditorUpdate(editor.getHTML());
      // Set editing back to false after a short delay
      setTimeout(() => {
        setIsEditing(false);
      }, 500);
    },
    onFocus: () => {
      setCurrentFocusedEditor('main');
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
      },
      handleClick: (view, pos, event) => {
        const { state } = view;
        const selectionRange = getMarkRange(state.doc.resolve(pos), state.schema.marks.link);
        if (selectionRange) {
          handleLinkClick(event);
          return true;
        }
        return false;
      },
    },
  });

  useEffect(() => {
    if (editor) {
      editor.on('blur', () => {
        setIsEditing(false);
      });
    }
  }, [editor, setIsEditing]);

  const handleLinkClick = useCallback((event) => {
    const linkElement = event.target.closest('a');
    if (linkElement && editor) {
      const range = document.createRange();
      range.selectNodeContents(linkElement);
      const rect = range.getBoundingClientRect();
      const editorRect = editor.view.dom.getBoundingClientRect();
      setLinkPopover({
        isOpen: true,
        url: linkElement.href,
        text: linkElement.textContent,
        position: { 
          top: rect.bottom - editorRect.top, 
          left: rect.left - editorRect.left
        },
      });
    }
  }, [editor]);

  const updateLink = useCallback(() => {
    if (editor) {
      let url = linkPopover.url;
      
      // If the URL doesn't start with a protocol, add https://
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      
      // Only update the text content if it has changed
      if (linkPopover.text !== editor.getAttributes('link').href) {
        editor.chain().focus().insertContent(linkPopover.text).run();
      }
    }
    setLinkPopover({ ...linkPopover, isOpen: false });
  }, [editor, linkPopover]);

  const removeLink = useCallback(() => {
    if (editor) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }
    setLinkPopover({ ...linkPopover, isOpen: false });
  }, [editor]);


  
  // Separate effect for handling content changes
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const handleInsertAttribute = useCallback((attributeName, fallback) => {
    console.log('handleInsertAttribute called in TiptapEditor');
    console.log('Current focused editor:', currentFocusedEditor);
    if (editor && currentFocusedEditor === 'main') {
      const attribute = `{${attributeName}:${fallback}}`;
      const { from } = editor.state.selection;
      
      editor
        .chain()
        .focus()
        .insertContent(attribute + ' ')
        .run();

      const newPos = from + attribute.length + 1; // +1 for the space
      editor.commands.setTextSelection(newPos);

      console.log('Attribute inserted in main editor, cursor positioned after attribute');
    } else {
      console.log('Calling onInsertAttribute for subject line');
      onInsertAttribute(attributeName, fallback);
    }
    setIsAttributeModalOpen(false);
  }, [editor, onInsertAttribute, currentFocusedEditor]);
  return (
    <Box height="550px" width="850px" position="relative">

      <Box 
  height="500px" 
  overflowY="auto" 
  p={4} 
  pb={16}
  position="relative"
  sx={{
    '& .ProseMirror': {
      outline: 'none',
      minHeight: '100%',
    },
    '& .ProseMirror:focus': {
      outline: 'none',
    },
    '& .ProseMirror p.is-editor-empty:first-child::before': {
      color: 'gray.400',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none'
    }
  }}
>
<EditorContent editor={editor} />
        {showSignature && (
          <Box
            mt={2}
            color="#8f8f8f"
            dangerouslySetInnerHTML={{ 
              __html: `
                <p>--</p>
                <p>${signature}</p>
              `
            }}
          />
        )}
        <Popover
          isOpen={linkPopover.isOpen}
          onClose={() => setLinkPopover({ ...linkPopover, isOpen: false })}
          placement="bottom"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Box position="absolute" top={linkPopover.position?.top} left={linkPopover.position?.left}>
              {/* This invisible box acts as an anchor for the popover */}
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <Input
                placeholder="URL"
                value={linkPopover.url}
                onChange={(e) => setLinkPopover({ ...linkPopover, url: e.target.value })}
                mb={2}
              />
              <Input
                placeholder="Link text"
                value={linkPopover.text}
                onChange={(e) => setLinkPopover({ ...linkPopover, text: e.target.value })}
                mb={2}
              />
              <HStack>
                <Button onClick={updateLink}>Update</Button>
                <Button onClick={removeLink}>Remove</Button>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Toolbar 
        editor={editor} 
        onInsertAttribute={() => setIsAttributeModalOpen(true)}
      />
      <AttributeModal
        isOpen={isAttributeModalOpen}
        onClose={() => setIsAttributeModalOpen(false)}
        onInsert={handleInsertAttribute}
        focusedEditor={currentFocusedEditor}
      />
    </Box>
  );
};

function getMarkRange(pos, type) {
  const { parent, parentOffset } = pos;
  const start = parent.childAfter(parentOffset);
  if (!start.node) return null;
  const mark = start.node.marks.find((mark) => mark.type === type);
  if (!mark) return null;
  let startIndex = start.index;
  let startPos = pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;
  while (startIndex > 0 && mark.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1;
    startPos -= parent.child(startIndex).nodeSize;
  }
  while (endIndex < parent.childCount && mark.isInSet(parent.child(endIndex).marks)) {
    endPos += parent.child(endIndex).nodeSize;
    endIndex += 1;
  }
  return { from: startPos, to: endPos };
}

export default TiptapEditor;