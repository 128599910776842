import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, VStack, HStack, Text, Select, Button, IconButton,
  Stat, StatLabel, StatNumber, StatHelpText, Divider,
  useDisclosure, useColorModeValue, SimpleGrid, Flex, Icon,
  Avatar, Menu, MenuButton, MenuList, MenuItem, Input, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  FormControl, FormLabel, Switch, Stack, Checkbox
} from '@chakra-ui/react';
import { EditIcon, CalendarIcon, TimeIcon, EmailIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaGlobe } from 'react-icons/fa';
import EmailPreview from './EmailPreview';
import { defaultAttributes } from './AttributeModal';
import api from '../api';
import { useToast } from '@chakra-ui/react';
import CampaignSettings from './CampaignSettings';
import { defaultSendingDays } from './CampaignSettings';
import ConfirmationModal from './ConfirmationModal'; 

const ExpandableEmail = ({ 
  email, 
  activeEmail, 
  isFirst, 
  showSignature, 
  signature, 
  signatureSettings,
  unsubscribeInfo, 
  lead 
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const renderEmailContent = () => {
    let content = email.body || '';
    content = content.replace(/<p>/g, '').replace(/<\/p>/g, '<br>').trim();

    // Check if signature should be added
    const shouldAddSignature = showSignature && 
                               signature && 
                               signatureSettings?.signatureType !== 'none';

    if (shouldAddSignature) {
      content += `<br><br><span style="color: #8f8f8f;">--<br>${signature}</span>`;
    }

    if (unsubscribeInfo) {
      // Add unsubscribe text directly after the signature (or content if no signature)
      // Make it smaller, italic, and gray
      content += `<br><span style="font-size: 0.8em; font-style: italic; color: #808080;">${unsubscribeInfo.formattedText}</span>`;
    }

    return (
      <>
        <style jsx>{`
          .unsubscribe-link {
            color: #0000EE;
            text-decoration: underline;
            cursor: default;
          }
        `}</style>
        <div 
          dangerouslySetInnerHTML={{ 
            __html: content 
          }} 
          style={{ 
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            fontFamily: 'Arial, sans-serif',
            lineHeight: '1.5'
          }}
        />
      </>
    );
  };



  const recipientName = lead ? `${lead.firstName || ''} ${lead.lastName || ''}`.trim() : 'Recipient';
  const recipientEmail = lead ? lead.email : 'recipient@example.com';

  return (
    <Box borderBottomWidth={1} borderColor="gray.200">
      <Box 
        p={4} 
        onClick={onToggle} 
        cursor="pointer" 
        bg={isOpen ? "gray.50" : "white"}
      >
        <HStack justify="space-between" mb={isOpen ? 4 : 0}>
          <HStack>
            <Avatar name={activeEmail} size="sm" />
            <Box>
              <Text fontWeight="bold">{activeEmail.split('@')[0]} <Text as="span" fontWeight="normal" color="gray.500">&lt;{activeEmail}&gt;</Text></Text>
              <Text fontSize="sm" color="gray.500">
                to <Text as="span" fontWeight="bold">{recipientName}</Text> &lt;{recipientEmail}&gt;
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Text color="gray.500">{email.date}</Text>
            {!isFirst && (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
          </HStack>
        </HStack>
        {isOpen || isFirst ? (
          renderEmailContent()
        ) : (
          <Text noOfLines={2}>{email.body.replace(/<[^>]+>/g, '')}</Text>
        )}
      </Box>
    </Box>
  );
};

  const CampaignOverview = ({ 
    recipientCount, 
    emailCount, 
    campaign, 
    setCampaign, 
    onSettingsClick, 
    onSignatureClick, 
    onUnsubscribeClick, 
    onStartDateChange, 
    startDate, 
    onEmailSelectionChange, 
    availableEmails, 
    campaignId, 
    fullCampaignProfile,
    profiles ,
    specificDate,
    initialProfile,
    refreshWrapUpData,
    handleSpecificDateChange,
  }) => {   
    const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInboxRotation, setIsInboxRotation] = useState(campaign.fromEmails.length > 1);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [localInboxRotation, setLocalInboxRotation] = useState(campaign.fromEmails.length > 1);
  const [localFromEmails, setLocalFromEmails] = useState(campaign.fromEmails);
  const toast = useToast();
  const [currentProfile, setCurrentProfile] = useState(fullCampaignProfile);
  const [newProfileData, setNewProfileData] = useState(null);
 


  console.log('Current profile in CampaignOverview:', currentProfile);




  const calculateSendingDays = useCallback(() => {
    const sendingDays = fullCampaignProfile?.sendingDays || {};
    return Object.values(sendingDays).filter(Boolean).length;
  }, [fullCampaignProfile]);

  const [sendingDaysCount, setSendingDaysCount] = useState(calculateSendingDays());

  useEffect(() => {
    setSendingDaysCount(calculateSendingDays());
  }, [fullCampaignProfile, calculateSendingDays]);

  const currentIteration = {
    bgColor: "teal.50",
    iconColor: "teal.500",
    statBgColor: "teal.100",
    buttonColor: "teal",
  };

  const formatSendingDays = (sendingDays) => {
    if (!sendingDays) return 'No days selected';
    
    const daysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const activeDays = daysOrder.filter(day => sendingDays[day]);
    
    if (activeDays.length === 0) return 'No days selected';
    
    const ranges = [];
    let rangeStart = null;
    
    for (let i = 0; i < activeDays.length; i++) {
      if (rangeStart === null) {
        rangeStart = activeDays[i];
      } else if (daysOrder.indexOf(activeDays[i]) !== daysOrder.indexOf(activeDays[i-1]) + 1) {
        ranges.push(rangeStart === activeDays[i-1] ? rangeStart : `${rangeStart}-${activeDays[i-1]}`);
        rangeStart = activeDays[i];
      }
    }
    
    if (rangeStart !== null) {
      ranges.push(rangeStart === activeDays[activeDays.length-1] ? rangeStart : `${rangeStart}-${activeDays[activeDays.length-1]}`);
    }
    
    return ranges.map(range => {
      const [start, end] = range.split('-');
      return `${start.charAt(0).toUpperCase() + start.slice(1)}${end ? `-${end.charAt(0).toUpperCase() + end.slice(1)}` : ''}`;
    }).join(', ');
  };

  useEffect(() => {
    if (fullCampaignProfile) {
      setCurrentProfile(fullCampaignProfile);
    }
  }, [fullCampaignProfile]);




  const handleProfileChange = async (profileId) => {
    if (profileId === 'new') {
      onSettingsClick('new');
    } else {
      try {
        const response = await api.getCampaignProfile(profileId);
        const fullCampaignProfile = response.data;
  
        setCurrentProfile(fullCampaignProfile);
  
        setCampaign(prev => ({
          ...prev,
          campaignProfile: fullCampaignProfile
        }));
  
        await api.updateCampaign(campaignId, { campaignProfile: profileId });
        refreshWrapUpData();
  
        toast({
          title: "Profile updated",
          description: "Campaign profile has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error updating campaign profile:', error);
        toast({
          title: "Error updating profile",
          description: "Failed to update campaign profile. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  



  const handleInboxRotationChange = (isChecked) => {
    setLocalInboxRotation(isChecked);
    if (!isChecked) {
      // If turning off inbox rotation, keep only the first email
      setLocalFromEmails(localFromEmails.slice(0, 1));
    }
  };

 const handleEmailSelection = (email) => {
    if (localInboxRotation) {
      setLocalFromEmails(prev => 
        prev.some(e => e.email === email || e === email)
          ? prev.filter(e => e.email !== email && e !== email)
          : [...prev, { email }]
      );
    } else {
      setLocalFromEmails([{ email }]);
    }
  };


const handleSaveEmails = async () => {
  try {
    const response = await api.put(`/api/email-campaigns/${campaignId}`, {
      fromEmails: localFromEmails
    });
    if (response.data) {
      onEmailSelectionChange(response.data.fromEmails);
      setIsEmailModalOpen(false);
      refreshWrapUpData();
      toast({
        title: "Emails updated",
        description: "Your sending emails have been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  } catch (error) {
    console.error('Error updating sending emails:', error);
    toast({
      title: "Error updating sending emails",
      description: "Failed to save changes. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

  if (!campaign.campaignProfile) {
    return <Text>Loading campaign profile...</Text>;
  }

  return (
    <VStack align="stretch" spacing={4} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md" height="100%" bg={currentIteration.bgColor}>
      <Text fontWeight="bold" fontSize="xl">Campaign Overview</Text>
      <Box borderWidth={1} borderColor={borderColor} borderRadius="md" p={2}>
        <HStack justify="space-between" mb={2}>
          <Text fontWeight="semibold">Sending from:</Text>
          <IconButton
            icon={<ChevronDownIcon />}
            aria-label="Change email"
            size="sm"
            variant="ghost"
            onClick={() => setIsEmailModalOpen(true)}
          />
        </HStack>
        <Box maxHeight="150px" overflowY="auto">
          {campaign.fromEmails.map((email, index) => (
            <Text key={index} fontSize="sm">{email.email || email}</Text>
          ))}
        </Box>
      </Box>
      <SimpleGrid columns={2} spacing={4}>
        <Stat bg={currentIteration.statBgColor} p={2} borderRadius="md" textAlign="center">
          <StatLabel>Total Recipients</StatLabel>
          <StatNumber>{recipientCount || 0}</StatNumber>
          <StatHelpText>Across {campaign.versions?.length || 0} variants</StatHelpText>
        </Stat>
        <Stat bg={currentIteration.statBgColor} p={2} borderRadius="md" textAlign="center">
          <StatLabel>Emails to be Sent</StatLabel>
          <StatNumber>{emailCount || 0}</StatNumber>
          <StatHelpText>
            Over {sendingDaysCount} days
          </StatHelpText>
        </Stat>
      </SimpleGrid>
      <Divider />
      <VStack align="stretch" spacing={2}>
        <Text fontWeight="bold">Campaign Settings Profile:</Text>
        {profiles && profiles.length > 0 ? (
          <Select 
            value={currentProfile?._id || campaign.campaignProfile || ''}
            onChange={(e) => handleProfileChange(e.target.value)}
          >
            {profiles.map(profile => (
              <option key={profile._id} value={profile._id}>{profile.name}</option>
            ))}
            <option value="new">Create New Settings Profile</option>
          </Select>
        ) : (
          <Text>No profiles available</Text>
        )}
      </VStack>

    <SimpleGrid columns={2} spacing={4}>
        <HStack>
          <Icon as={CalendarIcon} color={currentIteration.iconColor} />
          <VStack align="start" spacing={0}>
            <Text fontWeight="medium">Sending Days</Text>
            <Text fontSize="sm">
              {formatSendingDays(currentProfile?.sendingDays)}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          <Icon as={TimeIcon} color={currentIteration.iconColor} />
          <VStack align="start" spacing={0}>
            <Text fontWeight="medium">Sending Window</Text>
            <Text fontSize="sm">
              {currentProfile?.sendingWindow ? 
                `${currentProfile.sendingWindow.start} - ${currentProfile.sendingWindow.end}` : 
                'Not set'}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          <Icon as={FaGlobe} color={currentIteration.iconColor} />
          <VStack align="start" spacing={0}>
            <Text fontWeight="medium">Timezone</Text>
            <Text fontSize="sm">{currentProfile?.timezone || 'Not set'}</Text>
          </VStack>
        </HStack>
        <HStack>
          <Icon as={EmailIcon} color={currentIteration.iconColor} />
          <VStack align="start" spacing={0}>
            <Text fontWeight="medium">Daily Email Cap</Text>
            <Text fontSize="sm">{currentProfile?.dailyEmailCap || 'Not set'}</Text>
          </VStack>
        </HStack>
      </SimpleGrid>
      <HStack>
        <Button leftIcon={<EditIcon />} onClick={onSignatureClick} variant="outline" flex={1}>
          Signature
        </Button>
        <Button leftIcon={<EditIcon />} onClick={onUnsubscribeClick} variant="outline" flex={1}>
          Unsubscribe
        </Button>
      </HStack>
      <HStack justify="space-between" mt={4}>
      <Text>Start:</Text>
      <Select 
        value={startDate} 
        onChange={(e) => onStartDateChange(e.target.value)} 
        size="sm" 
        width="auto" 
        borderRadius="md"
      >
        <option value="Immediately">Immediately</option>
        <option value="SpecificDate">Choose specific date</option>
      </Select>
    </HStack>
    {startDate === 'SpecificDate' && (
      <Input
        type="date"
        value={specificDate}
        onChange={handleSpecificDateChange}
        min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
      />
    )}
      
      
      <Modal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Sending Emails</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Inbox rotation</FormLabel>
              <Switch 
                isChecked={localInboxRotation}
                onChange={(e) => handleInboxRotationChange(e.target.checked)}
              />
            </FormControl>
            {localInboxRotation ? (
              <Stack>
                {availableEmails.map(email => (
                  <Checkbox 
                    key={email}
                    isChecked={localFromEmails.some(e => e.email === email || e === email)}
                    onChange={() => handleEmailSelection(email)}
                  >
                    {email}
                  </Checkbox>
                ))}
              </Stack>
            ) : (
              <Select
                value={localFromEmails[0]?.email || localFromEmails[0] || ''}
                onChange={(e) => handleEmailSelection(e.target.value)}
              >
                <option value="">Select an email</option>
                {availableEmails.map(email => (
                  <option key={email} value={email}>{email}</option>
                ))}
              </Select>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveEmails}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setIsEmailModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const WrapUp = ({ wrapUpData, campaign, setCampaign, onSettingsClick, onSignatureClick, onUnsubscribeClick, onStartDateChange, refreshWrapUpData, 
  handleBack,
  startCampaign,
  isSaving
 }) => {
  const [activeVariant, setActiveVariant] = useState('');
  const [activeEmail, setActiveEmail] = useState('');
  const [currentSignature, setCurrentSignature] = useState('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [newProfileData, setNewProfileData] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [startDate, setStartDate] = useState(wrapUpData?.campaign?.startDate ? 'SpecificDate' : 'Immediately');
  const [specificDate, setSpecificDate] = useState(wrapUpData?.campaign?.startDate ? new Date(wrapUpData.campaign.startDate).toISOString().split('T')[0] : '');
  const toast = useToast(); 
  
  const handleStartCampaign = () => {
    setIsConfirmationOpen(true);
  };
  
  const handleConfirm = async () => {
    setIsConfirmationOpen(false);
    try {
      await startCampaign();
      toast({
        title: "Campaign Started",
        description: "Your campaign has been successfully activated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to start the campaign. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (wrapUpData?.campaign) {
      setActiveVariant(wrapUpData.campaign.versions[0]?.name || '');
      setActiveEmail(wrapUpData.campaign.fromEmails[0]?.email || wrapUpData.campaign.fromEmails[0] || '');
      
      // Update startDate and specificDate based on the fetched campaign data
      if (wrapUpData.campaign.startDate) {
        setStartDate('SpecificDate');
        setSpecificDate(new Date(wrapUpData.campaign.startDate).toISOString().split('T')[0]);
      } else {
        setStartDate('Immediately');
        setSpecificDate('');
      }
    }
  }, [wrapUpData]);
  if (!wrapUpData) return <Box>Loading campaign data...</Box>;

  const { campaignCounts = {}, availableEmails = [], profiles = [], previewLead, fullCampaignProfile, signatureInfo = {}, unsubscribeInfo = {} } = wrapUpData;
  const currentCampaign = wrapUpData.campaign || campaign;
  const currentVariant = currentCampaign?.versions?.find(v => v.name === activeVariant) || { emails: [] };
  
    console.log('Current campaign profile:', currentCampaign.campaignProfile);

  
    console.log('Campaign counts in WrapUp:', campaignCounts);

    const getSignatureForEmail = (email) => {
      const signatureSetting = signatureInfo[email];
      if (signatureSetting) {
        return signatureSetting.signatureType === 'custom' ? signatureSetting.customSignature : signatureSetting.signature;
      }
      return '';
    };


    const updateCampaignProfile = async (profile) => {
      try {
        console.log('Updating campaign profile with:', profile);
        const updatedCampaign = await api.updateCampaign(campaign._id, { 
          campaignProfile: profile._id  // Send only the profile ID
        });
    
        console.log('Received updated campaign:', updatedCampaign);
    
        if (updatedCampaign) {
          // Merge the new profile with the existing campaign data
          const mergedCampaign = {
            ...updatedCampaign,
            campaignProfile: profile  // Use the full profile object
          };
          console.log('Merged campaign data:', mergedCampaign);
          setCampaign(mergedCampaign);
        } else {
          console.error('Failed to update campaign with new profile');
        }
      } catch (error) {
        console.error('Error updating campaign with new profile:', error);
      }
    };

    const handleSettingsClick = async (value) => {
      if (value === 'new') {
        setNewProfileData({
          _id: 'new',
          name: '',
          sendingDays: {...defaultSendingDays},
          sendingWindow: { start: '09:00', end: '17:00' },
          timezone: 'America/New_York',
          dailyEmailCap: 200,
          delayBetweenEmails: 60
        });
        setIsSettingsModalOpen(true);
      } else {
        try {
          await api.updateCampaign(campaign._id, { campaignProfile: value });
          setCampaign(prev => ({
            ...prev,
            campaignProfile: value  // Store only the profile ID
          }));
          refreshWrapUpData();
        } catch (error) {
          console.error('Error updating campaign profile:', error);
        }
      }
    };
  
    const handleNewProfileCreated = async (newProfile) => {
      try {
        const updatedCampaign = await api.updateCampaign(campaign._id, { 
          campaignProfile: newProfile._id  // Send only the profile ID
        });
        setCampaign(prev => ({
          ...prev,
          campaignProfile: newProfile._id  // Store only the profile ID
        }));
        setNewProfileData(null);
        setIsSettingsModalOpen(false);
        refreshWrapUpData();
      } catch (error) {
        console.error('Error updating campaign with new profile:', error);
      }
    };


    const handleStartDateChange = async (value) => {
      setStartDate(value);
      if (value === 'Immediately') {
        try {
          await api.updateCampaign(campaign._id, { startDate: null });
          setCampaign(prev => ({ ...prev, startDate: null }));
          toast({
            title: "Start date updated",
            description: "Campaign will start immediately",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error updating start date:', error);
          refreshWrapUpData();
          toast({
            title: "Error updating start date",
            description: "Failed to update start date. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
  
    const handleSpecificDateChange = async (e) => {
      const selectedDate = new Date(e.target.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
  
      if (selectedDate <= today) {
        toast({
          title: "Invalid date",
          description: "Please select a future date",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      setSpecificDate(e.target.value);
      try {
        await api.updateCampaign(campaign._id, { startDate: selectedDate });
        setCampaign(prev => ({ ...prev, startDate: selectedDate }));
        refreshWrapUpData();
        toast({
          title: "Start date updated",
          description: `Campaign will start on ${selectedDate.toLocaleDateString()}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error updating start date:', error);
        toast({
          title: "Error updating start date",
          description: "Failed to update start date. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
  
    const replaceAttributes = (content, lead) => {
      if (!content) return ''; // Return an empty string if content is null or undefined
    
      return content.replace(/\{([^:}]+)(?::([^}]+))?\}/g, (match, name, fallback) => {
        const lowercaseName = name.toLowerCase().trim();
        if (lead) {
          // Check standard attributes
          if (lead[lowercaseName] !== undefined) {
            return lead[lowercaseName];
          }
          // Check custom attributes
          if (lead.customAttributes && lead.customAttributes[name] !== undefined) {
            return lead.customAttributes[name];
          }
        }
        // Check default attributes
        const attribute = defaultAttributes.find(attr => attr.name.toLowerCase() === lowercaseName);
        if (attribute) {
          return attribute.example;
        }
        // If not found in lead data or default attributes, return fallback or original match
        return fallback || match;
      });
    };
  
      const handleEmailSelectionChange = async (newEmails) => {
        setCampaign(prev => ({
          ...prev,
          fromEmails: newEmails
        }));
        const newActiveEmail = newEmails[0].email || newEmails[0];
        setActiveEmail(newActiveEmail);
      };
  

  
      
    const cleanHtmlContent = (content) => {
      return content.replace(/<p>/g, '').replace(/<\/p>/g, '<br>').trim();
    };

    const handlePreviewEmailChange = (email) => {
      setActiveEmail(email);
    };

    return (
      <Box p={5} minH="100vh">
      <Flex maxW="1400px" mx="auto" gap={6} alignItems="flex-start">
        <Box flex={1}>
          <VStack spacing={4} align="stretch">
            <Box borderWidth={1} borderColor="gray.200" borderRadius="md" overflow="hidden">
              <Box p={4} borderBottomWidth={1} borderColor="gray.200">
                <HStack justify="space-between">
                  <Text as="h2" fontSize="2xl" fontWeight="400">
                  {replaceAttributes(currentCampaign.versions.find(v => v.name === activeVariant)?.emails[0]?.subject || '', previewLead)}
                  </Text>
                  <Menu>
                    <MenuButton as={IconButton} icon={<ChevronDownIcon />} variant="ghost" />
                    <MenuList>
                      {currentCampaign.versions.map((version, index) => (
                        <MenuItem key={index} onClick={() => setActiveVariant(version.name)}>
                          Variant {version.name}
                        </MenuItem>
                      ))}
                      <Divider />
                      {currentCampaign.fromEmails.map((email, index) => (
                        <MenuItem key={index} onClick={() => handlePreviewEmailChange(email.email || email)}>
                          {email.email || email}
                        </MenuItem>
    ))}
  </MenuList>
</Menu>
                  </HStack>
                </Box>
                {currentVariant.emails.map((email, index) => (
                <ExpandableEmail 
                  key={index} 
                  email={{
                    ...email,
                      body: cleanHtmlContent(replaceAttributes(email.body || '', previewLead)),
  recipientName: previewLead ? `${previewLead.firstName || ''} ${previewLead.lastName || ''}`.trim() : 'Recipient'
                  }} 
                  activeEmail={activeEmail} 
                  isFirst={index === 0}
                  showSignature={signatureInfo[activeEmail]?.showSignature}
                  signature={getSignatureForEmail(activeEmail)}
                  unsubscribeInfo={unsubscribeInfo}
                  signatureSettings={signatureInfo[activeEmail]}
                  lead={previewLead}
                />
              ))}
            </Box>
          </VStack>
        </Box>
          
         <Box width="400px">
         <CampaignOverview 
            campaign={currentCampaign}
            fullCampaignProfile={fullCampaignProfile}
            setCampaign={setCampaign} 
            onSettingsClick={handleSettingsClick}
            onSignatureClick={onSignatureClick}
            onUnsubscribeClick={onUnsubscribeClick}
            onStartDateChange={handleStartDateChange}
            startDate={startDate}
            handleSpecificDateChange={handleSpecificDateChange}
            specificDate={specificDate}
            onEmailSelectionChange={handleEmailSelectionChange}
            availableEmails={availableEmails} 
            campaignId={currentCampaign._id} 
            profiles={profiles}
            recipientCount={campaignCounts?.totalRecipients || 0}
      emailCount={campaignCounts?.totalEmails || 0}
            initialProfile={currentCampaign.campaignProfile}
            refreshWrapUpData={refreshWrapUpData}
            handleStartDateChange={handleStartDateChange}
          />
       
       <Flex justifyContent="space-between" mt={4}>
          <Button onClick={handleBack}>
            Back
          </Button>
          <Button 
            variant="primary"
            onClick={handleStartCampaign}
            isLoading={isSaving}
            loadingText="Starting Campaign"
            disabled={isSaving}
          >
            {isSaving ? 'Starting...' : 'Start Campaign'}
          </Button>
        </Flex>
        <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirm}
      />
      </Box>
    </Flex>
      <Modal isOpen={isSettingsModalOpen} onClose={() => setIsSettingsModalOpen(false)} size="4xl">
      <ModalOverlay />
      <ModalContent maxWidth="900px">
        <ModalHeader>Campaign Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CampaignSettings
            currentProfileId={newProfileData ? 'new' : campaign.campaignProfile}
            onNewProfileCreated={handleNewProfileCreated}
            onCloseModal={() => setIsSettingsModalOpen(false)}
            isInModal={true}
            initialData={newProfileData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
    </Box>
  );
};
  
  export default WrapUp;