import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Container, VStack, HStack, Heading, Text, Button, Select,
  Table, Thead, Tbody, Tr, Th, Td, IconButton, Menu, MenuButton, MenuList,
  MenuItem, Tag, Flex, useColorModeValue, Checkbox, useToast, Modal, 
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, 
  ModalCloseButton, Input, Textarea, Tooltip, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Progress
} from '@chakra-ui/react';
import { AddIcon, ViewIcon, EditIcon, DeleteIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useDropzone } from 'react-dropzone';
import FieldMapping from './FieldMapping';
import api from '../api';
import AddLeadForm from './AddLeadForm';
import io from 'socket.io-client';
import ImportBoxes from './ImportBoxes';



const SelectedRecipientsCounter = ({ selected = 0, total = 5000 }) => (
    <Box 
      borderWidth={1} 
      borderRadius="md" 
      p={2} 
      width="150px"  // Set a fixed width to ensure consistent sizing
      height="60px"  // Set a fixed height to ensure consistent sizing
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="sm" textAlign="center">Selected Recipients</Text>
      <Text fontSize="xl" fontWeight="bold" textAlign="center">{selected}/{total}</Text>
    </Box>
  );

  const RecipientSelection = ({ 
    campaignId, 
    campaignName, 
    initialRecipients, 
    totalRecipients, 
    onUpdateRecipients,
    initialLists,
    initialImports,
    preloadedRecipients,
    onImportComplete,
    refreshWrapUpData
  }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  

  const [lists, setLists] = useState(initialLists || []);
  const [selectedLists, setSelectedLists] = useState([]);
  const [includeUnverified, setIncludeUnverified] = useState(false);
  const [includeContacted, setIncludeContacted] = useState(false);
  const [showFieldMapping, setShowFieldMapping] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [selectedRecipients, setSelectedRecipients] = useState({});
  const [pastedEmails, setPastedEmails] = useState('');
  const [newListName, setNewListName] = useState('');
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importedListCount, setImportedListCount] = useState(0);
  const toast = useToast();
  const bgColor = useColorModeValue('blue.50', 'blue.900');
  const [isAddLeadFormOpen, setIsAddLeadFormOpen] = useState(false);
  const [editingLead, setEditingLead] = useState(null);
  const [imports, setImports] = useState(initialImports || []);

  const [deletedImports, setDeletedImports] = useState({});
  const [campaignListName, setCampaignListName] = useState(`${campaignName}'s leads`);
  const [recipients, setRecipients] = useState(preloadedRecipients || []);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const paginatedRecipients = recipients.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const totalPages = Math.ceil(recipients.length / pageSize);
  const [socket, setSocket] = useState(null);
  const [importProgress, setImportProgress] = useState(0);
  const [importStatus, setImportStatus] = useState('idle');
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);


  

  const fetchRecipients = useCallback(async (page = 1, forceRefresh = false, suppressToast = false) => {
    if (preloadedRecipients && page === 1 && !forceRefresh) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await api.get(`/api/email-campaigns/${campaignId}/recipients`, {
        params: { page, pageSize }
      });
      setRecipients(response.data.recipients);
      onUpdateRecipients(response.data.total);
    } catch (error) {
      console.error('Error fetching recipients:', error);
      if (!suppressToast) {
        toast({
          title: "Error fetching recipients",
          description: error.response?.data?.message || "An unexpected error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [campaignId, pageSize, onUpdateRecipients, preloadedRecipients, toast]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (currentPage > 1 || !preloadedRecipients) {
      fetchRecipients(currentPage);
    }
  }, [currentPage, fetchRecipients, preloadedRecipients]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchRecipients(currentPage);
    }
  }, [currentPage, fetchRecipients]);

  const handlePageSizeChange = (event) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    setCurrentPage(1);
    fetchRecipients(1);
  };

  const getNextImportName = () => {
    return `Import ${imports.length + 1}`;
  };
  
  useEffect(() => {
    fetchImports();
    fetchRecipients();
    loadImportProgress();
  }, [campaignId]);

  const fetchLists = async () => {
    if (lists.length > 0) return;
    try {
      const response = await api.get('/api/lists');
      setLists(response.data.lists);
    } catch (error) {
      console.error('Error fetching lists:', error);
      toast({
        title: "Error fetching lists",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };



  useEffect(() => {
    const newSocket = io(process.env.API_BASE_URL);
    setSocket(newSocket);
  
    if (newSocket) {
      newSocket.on('importProgress', (data) => {
        if (data.campaignId === campaignId) {
          setImportProgress(prev => {
            const newProgress = {
              ...prev,
              [data.importId]: {
                status: 'importing',
                progress: Math.round((data.processedCount / data.totalCount) * 100),
                count: data.processedCount
              }
            };
            console.log('Import progress update:', newProgress[data.importId]);
            saveImportProgress(newProgress);
            return newProgress;
          });
        }
      });
  
      newSocket.on('importComplete', (data) => {
        if (data.campaignId === campaignId) {
          console.log('Import complete data:', data);
          const addedCount = data.totalImported;
          
          setImportProgress(prev => {
            const newProgress = {
              ...prev,
              [data.importId]: {
                status: 'completed',
                progress: 100,
                count: addedCount
              }
            };
            console.log('Import complete progress update:', newProgress[data.importId]);
            saveImportProgress(newProgress);
            return newProgress;
          });
  
          setImports(prev => {
            const updatedImports = prev.map(imp => 
              imp.id === data.importId ? { ...imp, count: addedCount } : imp
            );
            console.log('Updated imports:', updatedImports);
            return updatedImports;
          });
  
          handleImportComplete();
          
          toast({
            title: "Import completed",
            description: `${addedCount} recipients added to ${campaignListName}`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  
      newSocket.on('importError', (data) => {
        if (data.campaignId === campaignId) {
          setImportProgress(prev => {
            const newProgress = {
              ...prev,
              [data.importId]: {
                status: 'error',
                progress: 0,
                count: 0
              }
            };
            console.log('Import error progress update:', newProgress[data.importId]);
            saveImportProgress(newProgress);
            return newProgress;
          });
          toast({
            title: "Import error",
            description: data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    }
  
    return () => {
      if (newSocket) {
        newSocket.off('importProgress');
        newSocket.off('importComplete');
        newSocket.off('importError');
        newSocket.close();
      }
    };
  }, [campaignId, campaignListName]);



  const clearRecipientsCache = async (campaignId) => {
    const cacheKey = `recipients:${campaignId}`;
    await redisClient.del(cacheKey);
  };

  const handleLeadUpdate = async (updatedLeadData) => {
    try {
      const response = await api.put(`/api/leads/${editingLead._id}`, updatedLeadData);
      console.log('Lead updated:', response.data);
      
      // Update the recipient data in the local state
      setRecipients(prevRecipients => 
        prevRecipients.map(r => 
          r.email === response.data.email 
            ? { ...r, company: response.data.company, position: response.data.position }
            : r
        )
      );
      
      setIsAddLeadFormOpen(false);
      setEditingLead(null);
      
      toast({
        title: "Lead updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating lead:', error);
      toast({
        title: "Error updating lead",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditLead = async (recipientId) => {
    try {
      console.log('Editing recipient with ID:', recipientId);
      const recipient = recipients.find(r => r._id === recipientId);
      console.log('Found recipient:', recipient);
  
      if (!recipient) {
        throw new Error('Recipient not found');
      }
      
      if (!recipient.leadId) {
        console.log('Lead ID not found for recipient, using recipient data');
        setEditingLead({
          _id: recipient._id,
          email: recipient.email,
          firstName: recipient.firstName || '',
          lastName: recipient.lastName || '',
          position: recipient.position || '',
          company: recipient.company || '',
          // Add other fields as necessary
        });
      } else {
        console.log('Fetching lead with ID:', recipient.leadId);
        const response = await api.get(`/api/leads/${recipient.leadId}`);
        console.log('Fetched lead data:', response.data);
        setEditingLead(response.data);
      }
  
      setIsAddLeadFormOpen(true);
    } catch (error) {
      console.error('Error fetching lead details:', error);
      toast({
        title: "Error fetching lead details",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMethodSelect = (method) => {
    setSelectedMethod(selectedMethod === method ? null : method);
  };

  const handleListSelect = (listId) => {
    setSelectedLists(prev => 
      prev.includes(listId) ? prev.filter(id => id !== listId) : [...prev, listId]
    );
  };

  useEffect(() => {
    fetchImports();
    fetchRecipients();
    loadImportProgress();
  }, [campaignId]);

  const loadImportProgress = () => {
    const savedProgress = localStorage.getItem(`importProgress_${campaignId}`);
    if (savedProgress) {
      setImportProgress(JSON.parse(savedProgress));
    }
  };

  const saveImportProgress = (progress) => {
    localStorage.setItem(`importProgress_${campaignId}`, JSON.stringify(progress));
  };
  
  const fetchImports = async () => {
    if (imports.length > 0) return;
    try {
      const response = await api.getImports(campaignId);
      console.log('Fetched imports:', response.data);
      setImports(response.data);
      
      // Update importProgress for completed imports
      const updatedProgress = { ...importProgress };
      response.data.forEach(imp => {
        if (!updatedProgress[imp.id] || updatedProgress[imp.id].status === 'completed') {
          updatedProgress[imp.id] = { status: 'completed', progress: 100 };
        }
      });
      setImportProgress(updatedProgress);
      saveImportProgress(updatedProgress);
    } catch (error) {
      console.error('Error fetching imports:', error);
      toast({
        title: "Error fetching imports",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  
  const handleImportComplete = useCallback(() => {
    fetchRecipients(1, true, true);  // Force refresh of recipients
    onImportComplete();
    if (typeof refreshWrapUpData === 'function') {
      refreshWrapUpData();  // Refresh wrap-up data only if the function exists
    }
  }, [fetchRecipients, onImportComplete, refreshWrapUpData]);
  
  const handleAddFromLists = async () => {
    try {
      console.log('Selected lists:', selectedLists);
      
      const selectedListNames = selectedLists
        .map(listId => lists.find(l => l._id === listId)?.name || 'Unknown List')
        .join(', ');
  
      const response = await api.post(`/api/email-campaigns/${campaignId}/recipients`, {
        lists: selectedLists,
        listNames: selectedListNames, 
        includeUnverified,
        includeContacted
      });
  
      console.log('Response from adding recipients:', response.data);
  
      if (response.data.importId) {
        setImports(prev => [...prev, {
          id: response.data.importId,
          name: getNextImportName(),
          method: 'list',
          count: 0 // Start with 0, will be updated as import progresses
        }]);
        setImportProgress(prev => ({
          ...prev,
          [response.data.importId]: { status: 'importing', progress: 0, count: 0 }
        }));
      } else {
        console.error('Import ID is missing from the response');
      }
  
      setSelectedMethod(null); 
      handleImportComplete();
  
      toast({
        title: "Import started",
        description: "Your import has been started. You can track its progress in the imports section.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
    } catch (error) {
      console.error('Error adding recipients:', error);
      console.error('Error response:', error.response?.data);
      toast({
        title: "Error adding recipients",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvContent = event.target.result;
      
      try {
        // Use the API to get the CSV preview, similar to ImportLeadsModal
        const formData = new FormData();
        formData.append('file', file);
        
        const response = await api.post(`/api/leads/csv-preview`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        console.log('CSV preview response:', response.data);
  
        const processedData = {
          headers: response.data.headers,
          previewData: response.data.previewData,
          fullData: response.data.fullData,
          rowCount: response.data.rowCount,
          hasHeaders: response.data.hasHeaders,
          totalRows: response.data.validLeadsCount
        };
  
        console.log('Processed CSV Data:', processedData);
  
        setCsvData(processedData);
        setIsImportModalOpen(true);
      } catch (error) {
        console.error('Error processing CSV:', error);
        toast({
          title: "Error processing CSV",
          description: "Please check the file and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    reader.readAsText(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.csv', multiple: false });

  const handleDeleteImport = async (importId) => {
    if (!importId) {
      console.error('Import ID is undefined');
      toast({
        title: "Error removing import",
        description: "Import ID is missing",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    if (window.confirm("Are you sure you want to delete this import?")) {
      try {
        await api.removeImport(campaignId, importId);
        setImports(prev => prev.filter(imp => imp.id !== importId));
        handleImportComplete();
        toast({
          title: "Import removed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error removing import:', error);
        toast({
          title: "Error removing import",
          description: error.response?.data?.message || "An unexpected error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  
  const handleUndoDelete = async (importId) => {
    try {
      await api.restoreImport(campaignId, importId);
      setDeletedImports(prev => {
        const newDeletedImports = { ...prev };
        delete newDeletedImports[importId];
        return newDeletedImports;
      });
      fetchRecipients();
    } catch (error) {
      console.error('Error restoring import:', error);
      toast({
        title: "Error restoring import",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getListName = () => {
    if (newListName) {
      return newListName;
    }
    const baseName = `${campaignName} list`;
    return importedListCount > 0 ? `${baseName} ${importedListCount + 1}` : baseName;
  };

  const handleFieldMappingComplete = async (mapping, dataToSend, emailColumnIndex, headers) => {
    try {
      console.log('Preparing to import CSV');
      setImportStatus('starting');
      setImportProgress(0);
  
      const response = await api.addRecipientsFromCSV(campaignId, {
        file: new Blob([csvData.fullData.map(row => row.join(',')).join('\n')], { type: 'text/csv' }),
        listName: campaignListName,
        emailColumnIndex: emailColumnIndex,
        mapping: mapping,
        hasHeaders: csvData.hasHeaders
      });
  
      console.log('CSV import response:', response);
  
      setImports(prev => [...prev, {
        id: response.importId,
        name: getNextImportName(),
        method: 'csv',
        count: response.addedCount || 0
      }]);
  
      // Always close the modal after starting the import
      setIsImportModalOpen(false);
      setSelectedMethod(null);
  
      // Update import progress
      setImportProgress(prev => ({
        ...prev,
        [response.importId]: { status: 'importing', progress: 0, count: 0 }
      }));
  
      // Trigger import complete callback
      handleImportComplete();
  
      toast({
        title: "Import started",
        description: "Your import has been started. You can track its progress in the imports section.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
    } catch (error) {
      console.error('Error importing recipients:', error);
      toast({
        title: "Error starting import",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveRecipient = async (recipientId) => {
    try {
      await api.delete(`/api/email-campaigns/${campaignId}/recipients/${recipientId}`);
      handleImportComplete();
      toast({
        title: "Recipient removed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing recipient:', error);
      toast({
        title: "Error removing recipient",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBulkRemove = async () => {
    if (isRemoving) return;

    try {
      setIsRemoving(true);
      console.log('Bulk remove initiated for campaign:', campaignId);
      
      const recipientIdsToRemove = Object.keys(selectedRecipients);
      
      if (recipientIdsToRemove.length === 0) {
        console.log('No recipients selected');
        toast({
          title: "No recipients selected",
          description: "Please select recipients to remove",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const response = await api.post(`/api/email-campaigns/${campaignId}/recipients/bulk-remove`, {
        recipientIds: recipientIdsToRemove
      });

      console.log('Bulk remove response:', response.data);

      // Update local state
      setRecipients(prevRecipients => 
        prevRecipients.filter(recipient => !selectedRecipients[recipient._id])
      );

      // Refresh the recipient list
      await handleImportComplete();

      setSelectedRecipients({});
      
      toast({
        title: "Recipients removed successfully",
        description: `${response.data.removedCount} recipients removed`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Error removing recipients:', error);
      console.error('Error details:', error.response?.data);
      toast({
        title: "Error removing recipients",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRemoving(false);
    }
  };

const handleSelectAllRecipients = (event) => {
  const isChecked = event.target.checked;
  const newSelected = { ...selectedRecipients };
  
  if (isChecked) {
    // Select all visible recipients
    paginatedRecipients.forEach(recipient => {
      newSelected[recipient._id] = true;
    });
  } else {
    // Deselect all visible recipients
    paginatedRecipients.forEach(recipient => {
      delete newSelected[recipient._id];
    });
  }
  
  setSelectedRecipients(newSelected);
};

  useEffect(() => {
    console.log('Recipients state updated:', recipients);
  }, [recipients]);

  const handleSelectRecipient = (recipientId) => {
    setSelectedRecipients(prev => {
      const newSelected = { ...prev };
      if (newSelected[recipientId]) {
        delete newSelected[recipientId];
      } else {
        newSelected[recipientId] = true;
      }
      return newSelected;
    });
  };

  // Helper function to check if all visible recipients are selected
  const areAllVisibleSelected = () => {
    return paginatedRecipients.every(recipient => selectedRecipients[recipient._id]);

  };

    // Helper function to get the count of selected recipients
    const getSelectedCount = () => Object.keys(selectedRecipients).length;

  const handlePasteEmails = async () => {
    try {
      const response = await api.addRecipientsFromPaste(campaignId, {
        emails: pastedEmails
      });

      if (response.data.importId) {
        setImports(prev => [...prev, {
          id: response.data.importId,
          name: getNextImportName(),
          method: 'paste',
          count: 0 // Start with 0, will be updated as import progresses
        }]);
        setImportProgress(prev => ({
          ...prev,
          [response.data.importId]: { status: 'importing', progress: 0, count: 0 }
        }));
      }
      handleImportComplete();
    
      fetchRecipients();
      setPastedEmails('');
      setSelectedMethod(null);
    } catch (error) {
      console.error('Error adding pasted recipients:', error);
      toast({
        title: "Error adding recipients",
        description: error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        
        
        <HStack spacing={4} justify="center">
          <Button
            as="button"
            onClick={() => handleMethodSelect('lists')}
            p={4}
            height="auto"
            borderWidth={1}
            borderRadius="md"
            borderColor="blue.500"
            color="blue.500"
            bg={selectedMethod === 'lists' ? bgColor : 'transparent'}
            _hover={{ bg: bgColor }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <AddIcon mb={2} />
            <Text>From Lists</Text>
          </Button>
          <Button
            as="button"
            onClick={() => handleMethodSelect('csv')}
            p={4}
            height="auto"
            borderWidth={1}
            borderRadius="md"
            borderColor="blue.500"
            color="blue.500"
            bg={selectedMethod === 'csv' ? bgColor : 'transparent'}
            _hover={{ bg: bgColor }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <AddIcon mb={2} />
            <Text>Import CSV</Text>
          </Button>
          <Button
            as="button"
            onClick={() => handleMethodSelect('paste')}
            p={4}
            height="auto"
            borderWidth={1}
            borderRadius="md"
            borderColor="blue.500"
            color="blue.500"
            bg={selectedMethod === 'paste' ? bgColor : 'transparent'}
            _hover={{ bg: bgColor }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <AddIcon mb={2} />
            <Text>Copy & Paste</Text>
          </Button>
        </HStack>

        {selectedMethod && (
          <Box 
            p={4} 
            borderWidth={1} 
            borderRadius="md" 
            width="750px" 
            mx="auto"
          >
            {selectedMethod === 'lists' && (
              <VStack align="stretch">
                <Select placeholder="Select lists" onChange={(e) => handleListSelect(e.target.value)}>
                  {lists.map(list => (
                    <option key={list._id} value={list._id}>{list.name} ({list.leadCount} leads)</option>
                  ))}
                </Select>
                <Checkbox isChecked={includeUnverified} onChange={(e) => setIncludeUnverified(e.target.checked)}>
                  Include unverified leads
                </Checkbox>
                <Checkbox isChecked={includeContacted} onChange={(e) => setIncludeContacted(e.target.checked)}>
  Include already contacted leads
</Checkbox>
                <Button 
                  mt={4} 
                  variant="outline"
                  borderColor="blue.500"
                  color="blue.500"
                  _hover={{ bg: 'blue.50' }}
                  onClick={handleAddFromLists}
                >
                  Add Selected Lists
                </Button>
              </VStack>
            )}
            {selectedMethod === 'csv' && (
        <VStack align="stretch">
          <Box {...getRootProps()} p={4} borderWidth={1} borderStyle="dashed" borderRadius="md">
            <input {...getInputProps()} />
            <Text>Drag and drop a CSV file here, or click to select a file</Text>
          </Box>
          <Text fontSize="sm" color="gray.500">
            Note: The leads will be added to the list "{campaignListName}".
          </Text>
        </VStack>
      )}
      {selectedMethod === 'paste' && (
        <VStack align="stretch">
          <Text fontSize="sm" color="gray.500">
            The leads will be added to the list "{campaignListName}".
          </Text>
          <Textarea 
            placeholder="Paste your leads here... (One email per line)" 
            rows={5}
            value={pastedEmails}
            onChange={(e) => setPastedEmails(e.target.value)}
          />
          <Button 
            mt={4} 
            variant="outline"
            borderColor="blue.500"
            color="blue.500"
            _hover={{ bg: 'blue.50' }}
            onClick={handlePasteEmails}
          >
            Add Pasted Leads
          </Button>
        </VStack>
      )}
          </Box>
        )}

<Box>
    <Flex justify="space-between" align="center" mb={4} width="100%">
      {/* Left: Selected Recipients Counter */}
      <Box>
      <SelectedRecipientsCounter 
                selected={recipients.length}  // This shows total recipients
                total={5000}  // Assuming 5000 is the maximum allowed
              />
      </Box>

      <ImportBoxes 
    imports={imports}
    handleDeleteImport={handleDeleteImport}
    importProgress={importProgress}
  />

      {/* Right: Bulk Actions Button */}
      <Box>
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<ChevronDownIcon />} 
            variant="outline"
            borderColor="blue.500"
            color="blue.500"
            _hover={{ bg: 'blue.50' }}
          >
            Bulk Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleBulkRemove}>Remove Selected</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>

    <AddLeadForm
      isOpen={isAddLeadFormOpen}
      onClose={() => {
        setIsAddLeadFormOpen(false);
        setEditingLead(null);
      }}
      onSubmit={handleLeadUpdate}
      editingLead={editingLead}
    />

    <Table>
      <Thead>
        <Tr>
          <Th> <Checkbox 
          onChange={handleSelectAllRecipients} 
          isChecked={areAllVisibleSelected()}
          isIndeterminate={getSelectedCount() > 0 && !areAllVisibleSelected()}
        /></Th>
          <Th>Email</Th>
          <Th>Verification Status</Th>
          <Th>Company</Th>
          <Th>Position</Th>
          <Th>List</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
      {paginatedRecipients.map((recipient) => (
      <Tr key={recipient._id}>
        <Td>
          <Checkbox 
            isChecked={!!selectedRecipients[recipient._id]} 
            onChange={() => handleSelectRecipient(recipient._id)} 
          /></Td>
            <Td>{recipient.email}</Td>
            <Td>{recipient.verificationStatus}</Td>
            <Td>{recipient.company}</Td>
            <Td>{recipient.position}</Td>
            <Td><Tag>{campaignListName}</Tag></Td>
            <Td>
              <HStack spacing={2}>
                <Tooltip label="View recipient">
                  <IconButton
                    icon={<ViewIcon />}
                    aria-label="View recipient"
                    size="sm"
                    variant="ghost"
                    colorScheme="blue"
                  />
                </Tooltip>
                <Tooltip label="Edit lead">
                  <IconButton
                    icon={<EditIcon />}
                    aria-label="Edit lead"
                    size="sm"
                    variant="ghost"
                    colorScheme="green"
                    onClick={() => handleEditLead(recipient._id)}
                  />
                </Tooltip>
                <Tooltip label="Delete recipient">
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete recipient"
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => handleRemoveRecipient(recipient._id)}
                  />
                </Tooltip>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    <Flex justifyContent="space-between" alignItems="center" mt={4}>
      <HStack>
        <Text>Show</Text>
        <Select value={pageSize} onChange={handlePageSizeChange}>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
        <Text>recipients</Text>
      </HStack>
      <HStack>
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1 || isLoading}
        />
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages || isLoading}
        />
      </HStack>
    </Flex>
  </Box>
        
      </VStack>

      <Modal isOpen={isImportModalOpen} onClose={() => setIsImportModalOpen(false)} size="6xl">
  <ModalOverlay />
  <ModalContent maxW="1200px">
    <ModalHeader>Import Leads</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {csvData && (
        <FieldMapping 
          csvData={csvData}
          onMappingComplete={handleFieldMappingComplete}
          onCancel={() => setIsImportModalOpen(false)}
          selectedList={`${campaignName} list${importedListCount > 0 ? ` ${importedListCount + 1}` : ''}`}
          newListName={newListName}
          lists={lists}
        />
      )}
    </ModalBody>
    <ModalFooter>
      <Button variant="ghost" onClick={() => setIsImportModalOpen(false)}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>
    </Container>
  );
};

export default RecipientSelection;